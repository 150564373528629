<template>
  <v-card class="prompt-item mx-auto" @click="(e) => $emit('click', value, e)">
    <v-card-title class="prompt-title primary-gradient">
      <span class="text-h6 font-weight-light">Credentials #{{ value.id }}</span>
      <div
        class="item-status"
        :style="{
          backgroundColor:
            value.status.code === 'DRAFT'
              ? 'red'
              : value.status.code === 'ACTIVE'
              ? 'purple'
              : 'grey',
        }"
      >
        <span>{{ value.status.name }}</span>
      </div>
    </v-card-title>

    <v-card-text class="font-weight-bold pa-4 prompt-text-preview">
      <v-list-item class="full-width">
        <v-list-item-icon>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-key-link</v-icon>
          </v-btn>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class=""> API Key </v-list-item-title>
          <v-list-item-subtitle>{{ value.key }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn @click.prevent.stop="copyToClipBoard" icon>
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-card-text>

    <v-card-actions class="card-actions-bottom">
      <!-- <v-icon class="mr-1"> mdi-heart </v-icon>
            <span class="subheading mr-2">256</span> -->
      Last usage: {{ fromNow(value.last_usage) }}
      <v-spacer></v-spacer>
      <span class="mr-1">·</span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="error"
            @click.prevent.stop="$emit('onRemove', value)"
            icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <span>
          Clicking on this button will permanently delete the selected item from
          the system. Please note that this action cannot be undone, so proceed
          with caution. Consider making the item inactive or archived if you
          prefer a reversible action.</span
        >
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
    
    
    <script>
import moment from "moment";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      //
    };
  },

  methods: {
    fromNow(date) {
      return date ? moment(date).fromNow() : "never";
    },
    async copyToClipBoard() {
      await navigator.clipboard.writeText(this.value.key);

      this.$toast.open({
        type: "success",
        message: "Copied to clipboard",
        position: "top-right",
      });
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.prompt-item {
  min-height: 240px;

  .prompt-text-preview {
    max-height: 196px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .prompt-title {
    position: relative;
    overflow: hidden;
    .item-status {
      position: absolute;
      top: -10px;
      right: -60px;
      transform: rotate(45deg);
      width: 150px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      height: 62px;
      text-transform: capitalize;
      align-items: end;
      vertical-align: bottom;
    }
  }

  .active-version {
    max-width: 100px;
  }
}
</style>