var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"connector-menu"},[_c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function({ on: menuOn, attrs: menuAttrs }){return [_c('v-row',_vm._b({staticClass:"connector-menu-display-col"},'v-row',_vm.$attrs,false),[_c('v-col',{staticClass:"connector-col"},[_c('v-menu',{attrs:{"content-class":"selector-menu","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"connector-chip",attrs:{"text-color":"white"},on:{"click":function($event){return _vm.$emit('click', $event)}}},'v-chip',{
                  ...menuAttrs,
                  ...attrs,
                },false),{
                  ...menuOn,
                  ...on,
                }),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(_vm.connector ? _vm.connector.name : "Select Connector")+" ")],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.pConnectors),function(item){return _c('v-list-item',{key:item.id,staticClass:"mb-2",attrs:{"two-line":""},on:{"click":function($event){return _vm.$emit('onConnectorChanged', item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('v-chip',{attrs:{"small":"","color":item.status.code === 'DRAFT'
                          ? 'pink'
                          : item.status.code === 'ACTIVE'
                          ? 'primary'
                          : 'grey',"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(item.status.name)+" ")],1),_vm._v(" "+_vm._s(_vm.formatDate(item.created_at)))],1)],1)],1)}),1)],1)],1),_c('v-slide-x-reverse-transition',[(_vm.connector && _vm.connector.id)?_c('v-col',{staticClass:"version-col"},[_c('v-menu',{attrs:{"content-class":"selector-menu","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"version-chip",attrs:{"text-color":"white"}},'v-chip',{
                    ...menuAttrs,
                    ...attrs,
                  },false),{
                    ...menuOn,
                    ...on,
                  }),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(_vm.version ? _vm.version.v : `LTS (${_vm.connector.lts})`)+" ")],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.connector.versions),function(item,index){return _c('v-list-item',{key:index,attrs:{"two-line":""},on:{"click":function($event){return _vm.$emit('onConnectorVersionChanged', item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.v))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])],1)],1)}),1)],1)],1):_vm._e()],1)],1)]}}])},'v-tooltip',_vm.tooltipAttrs,false),[_c('span',[_vm._v(_vm._s(_vm.helpText))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }