import {
    API
} from '../Api.class';


export class ProfileAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        // this.TypesApi = new ServiceTypesAPI(baseUrl);
        // this.PlansAPI = new PlansAPI(baseUrl);
        // this.UseCasesAPI = new UseCaseAPI(baseUrl);
        // this.DeliverablesAPI = new ServiceDeliverableAPI(baseUrl);
        // this.SectionsAPI = new ServiceSectionsAPI(baseUrl);
    }


    async uploadAvatar(user, formData, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/users/${user.id}/profile/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, {
            meta
        });
    }


    // async get(id, meta) {
    //     this.loading = true

    //     return await this.__axiosInstance.get(`api/v1/users/${id}`, {
    //         meta
    //     });

    // }


    // async create(body, meta) {
    //     this.loading = true;

    //     return await this.__axiosInstance.post('api/v1/users', body, {
    //         meta
    //     });
    // }


    // async update(service, meta) {
    //     this.loading = true

    //     return await this.__axiosInstance.put(`api/v1/users/${service.id}`, service, {
    //         meta
    //     });
    // }
}