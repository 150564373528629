<template>
  <div class="preview-wrapper-dialog">
    <FeedbackComponent
      :stars="feedbackParams.stars"
      :like="feedbackParams.like"
      :size="feedbackParams.size"
      :allowActions="feedbackParams.allowActions"
      :allowComment="feedbackParams.allowComment"
      :dismissText="feedbackParams.dismissText"
      :saveText="feedbackParams.saveText"
      :titleText="feedbackParams.titleText"
      :contentText="feedbackParams.contentText"
      :commentLabel="feedbackParams.commentLabel"
      :commentHelpText="feedbackParams.commentHelpText"
      :color="feedbackParams.color"
      :starsColor="feedbackParams.starsColor"
      :taskId="feedbackParams.taskId"
      :userId="feedbackParams.userId"
      :promptId="feedbackParams.promptId"
      :promptVersion="feedbackParams.promptVersion"
      :promptVersionId="feedbackParams.promptVersionId"
      :source="feedbackParams.source"
      @onSubmit="onSubmit"
    ></FeedbackComponent>
  </div>
</template>
    
    <script>
import { FeedbackAPIInstance } from "../../components/api";
import FeedbackComponent from "./components/FeedbackComponent.vue";

export default {
  data() {
    return {
      short_token: "",
      feedbackParams: {
        allowActions: true,
        allowComment: true,
        color: "#109c1d",
        commentHelpText:
          "You can give us advanced feedback to make our system better!",
        commentLabel: "Your comment",
        size: 20,
        stars: true,
        starsColor: "",
        dismissText: "Dismiss",
        saveText: "Save",
        titleText: undefined,
        contentText: undefined,
        taskId: undefined,
        userId: undefined,
        promptId: undefined,
        promptVersion: undefined,
        promptVersionId: undefined,
        source: "public",
      },

      x: 0,
      y: 0,
    };
  },
  computed: {},
  components: {
    FeedbackComponent,
  },
  async created() {
    const feedbackParams = this.$route.query;

    this.feedbackParams = {
      ...this.feedbackParams,
      ...feedbackParams,
    };

    if (feedbackParams.allowActions)
      this.feedbackParams.allowActions = feedbackParams.allowActions === "true";
    if (feedbackParams.allowComment)
      this.feedbackParams.allowComment = feedbackParams.allowComment === "true";
    if (feedbackParams.size)
      this.feedbackParams.size = parseInt(feedbackParams.size);
    if (feedbackParams.stars)
      this.feedbackParams.stars = feedbackParams.stars === "true";
  },
  methods: {
    async onSubmit(result) {
      await FeedbackAPIInstance.publicCreate(result, {
        toast: {
          message: "Feedback Submitted!",
        },
      });
    },
    mouseMove() {},
  },
};
</script>
  
  
  <style lang="scss" scoped>
.preview-wrapper-dialog {
  width: 100%;
  height: 100%;
  background: var(--bgImage);
  background-size: 120% 120%;
}
</style>