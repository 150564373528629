
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="cols.length === 3"
            color="white"
            v-bind="attrs"
            v-on="on"
            @click="onCreate"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>
          Create a New Connector: Simply click on this button to initiate the
          process of generating a fresh connector. This allows you to start a
          new writing session or explore different ideas with ease.</span
        >
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
      ></HelpFormInput>
      <v-icon color="white">mdi-magnify</v-icon>
    </v-toolbar>
    

    <v-card-text>
      <v-slide-y-transition class="py-0 row" group tag="div">
        <v-col class="flex-col" v-for="item in cols" :key="item.id">
          <v-card>
            <v-card-title>
              <v-expand-transition>
                <span v-if="item.name">
                  {{ item.name }}
                </span>
              </v-expand-transition>
              <v-spacer></v-spacer>
              <v-btn color="error" icon><v-icon>mdi-delete</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
              <SelectorPrompts
                autoload
                v-model="item.prompt"
                return-object
                :label="'Choose Prompt for a test'"
                @change="onPromptSelected(item)"
              ></SelectorPrompts>
              <SelectorConnectorsVue
                autoload
                v-model="item.connector"
                return-object
                :label="'Choose Connector for a test'"
              ></SelectorConnectorsVue>
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>
        
<script>
import { ConnectorAPIInstance } from "../../components/api";
import SelectorConnectorsVue from "../../components/wad/atoms/common/autocompletes/SelectorConnectors.vue";
import SelectorPrompts from "../../components/wad/atoms/common/autocompletes/SelectorPrompts.vue";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      api: ConnectorAPIInstance,
      cols: [
        {
          id: uuidv4(),
          prompt: {},
          connector: {},
        },
      ],
      removeDialog: false,
      removeCandidate: undefined,
    };
  },
  components: {
    HelpFormInput,
    SelectorPrompts,
    SelectorConnectorsVue,
  },
  async created() {},
  methods: {
    async getList() {
      const { items, pagination } = await this.api.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      this.cols.push({
        id: uuidv4(),
      });
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Connector Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
    onPromptSelected(item) {
      item.name = `Test for ${item.prompt.name}`;
    },
  },
};
</script>