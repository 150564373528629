<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <SelectorSupportCategories
      v-model="form.category"
      :rules="categoryRules"
      label="Select Support Category"
      :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
      required
      autoload
      @input="$emit('input', value)"
      return-object
    ></SelectorSupportCategories>

    <HelpFormInput
      v-model="form.name"
      :rules="nameRules"
      label="Ticket Brief"
      :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
      required
      @input="$emit('input', value)"
    ></HelpFormInput>

    <HelpFormInput
      textarea
      :rows="5"
      v-model="form.description"
      :rules="descriptionRules"
      label="Your Question or Problem"
      :helpText="'Specify the initial value that the property should have if no explicit value is provided. This value serves as a fallback or a baseline for the property, ensuring consistent behavior in cases where a specific value is not assigned. Choose a default value that aligns with the expected data type and intended functionality of the property to achieve desired outcomes.'"
      required
      @input="$emit('input', value)"
    ></HelpFormInput>
  </v-form>
</template>
            
            
<script>
import SelectorSupportCategories from "../../../../components/wad/atoms/common/autocompletes/SelectorSupportCategories.vue";
import HelpFormInput from "../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

// import MenuTypesPicker from "../../../../components/wad/atoms/common/menus/MenuTypesPicker.vue";
// import SelectorDefault from "../../../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      types: [
        {
          value: "STRING",
          name: "String",
        },
        {
          value: "NUMBER",
          name: "Number",
        },
      ],

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      categoryRules: [(v) => !!v || "Category is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {},
  components: {
    HelpFormInput,
    SelectorSupportCategories,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
            
            
<style lang="scss" scoped>
.param-col {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.type-col {
  display: flex;
  flex-direction: row;
  flex: 0 0 fit-content;
  max-width: 100%;
  align-items: center;
}
</style>