<template>
  <div class="request-params-editor">
    <v-list>
      <v-slide-y-transition class="py-0" group tag="div">
        <v-list-item
          three-line
          v-for="prop in value"
          :key="`${prop.id}`"
          class="my-2 property-item"
        >
          <v-list-item-content>
            <v-list-item-title class="credential-property-text"
              >{{ prop.name }}
              <v-spacer></v-spacer>
            </v-list-item-title>
            <v-list-item-subtitle class="mt-2 pa-4">
              <v-row class="param-edit-container">
                <v-col class="edit-col-row py-2 px-0">
                  <HelpFormInput
                    html
                    :id="`${componentId}-${prop.id}`"
                    @contextmenu="(e) => show(e, prop)"
                    v-model="prop.value"
                    @input="inputUpdated(prop)"
                    :label="'Define a custom property name'"
                    class="inline-edit"
                  ></HelpFormInput>

                  <HelpFormInput
                    v-if="types && prop.type === 'list'"
                    class="list-input"
                    v-model="prop.options"
                    @input="inputUpdated(prop)"
                    :label="'List of options'"
                    :help-text="listHelpText"
                  ></HelpFormInput>
                </v-col>

                <v-col class="actions-col-row py-2 px-0">
                  <MenuTypesPicker
                    v-if="types"
                    v-model="prop.type"
                    @input="inputUpdated(prop)"
                    :help-text="'Pick the right type'"
                  >
                  </MenuTypesPicker>
                  <v-btn @click="onDelete(prop)" color="error" icon>
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>

                  <v-scroll-x-transition>
                    <v-icon v-if="prop.value" color="success">
                      mdi-check
                    </v-icon>
                  </v-scroll-x-transition>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card class="elevation-0 mt-6" :key="`new-cred-item`">
          <v-row class="px-2">
            <v-col cols="10" class="edit-col">
              <HelpFormInput
                :label="addLabel"
                @onEnter="onCreate"
                v-model="newItem"
              ></HelpFormInput>
            </v-col>
            <v-col cols="2" class="actions-col">
              <v-btn @click="onCreate" color="primary" icon>
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </v-list>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list class="connector-props-list">
        <template v-for="(prop, i) in availableParams">
          <v-list-item
            two-line
            v-if="prop.id"
            :key="prop.id"
            @click="addParam(prop)"
          >
            <v-list-item-content
              :class="{
                'primary--text': prop.issuer === 'system',
                'indigo-darken-2--text': prop.issuer === 'credentials',
                'pink-darken-2--text': prop.issuer === 'proxy',
              }"
            >
              <v-list-item-title
                >{{ prop.name }}
                <span v-if="prop.issuer"
                  >[{{ prop.issuer }}]</span
                ></v-list-item-title
              >
              <v-list-item-subtitle>{{
                cutString(prop.value, 40, "end")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-else>
            <v-subheader :key="`header-${i}`">{{ prop.section }}</v-subheader>
            <v-divider :key="`divider-${i}`"></v-divider>
          </template>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { v4 as uuidv4 } from "uuid";
import MenuTypesPicker from "../../../components/wad/atoms/common/menus/MenuTypesPicker.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    availableParams: {
      type: Array,
      default: () => [],
    },
    addLabel: {
      type: String,
      default: "New property",
    },
    types: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      componentId: uuidv4(),
      showMenu: false,
      x: 0,
      y: 0,
      cutString: FormatterHelper.cutString,
      listHelpText: 'Provide a list of possible values separate by ",". ',

      newItem: null,
      active: null,
    };
  },
  components: {
    HelpFormInput,
    MenuTypesPicker,
  },
  methods: {
    show(e, prop) {
      if (!(this.availableParams && this.availableParams.length)) return;

      this.active = prop;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    onCreate() {
      if (!this.value) this.value = [];

      // try to identify values

      const [possibleName, ...rest] = this.newItem.split(":");

      let name = this.newItem;
      let value = "";
      let type = "string";

      if (possibleName && rest.length) {
        name = possibleName.trim().replace(/^"|"$/g, "");

        value = rest.join(":").trim().replace(/^"|"$/g, "");

        console.log("value: ", value);
        console.log("test ", '  "Hello, World!"  ');

        if (!isNaN(Number(value))) {
          value = Number(value);
          type = "number";
        } else {
          try {
            value = JSON.parse(value);
            if (Array.isArray(value)) type = "array";
            else type = "json";
          } catch (error) {
            console.log("Value Not A JSON");
          }
        }
      }

      this.value.push({
        id: uuidv4(),
        name,
        value,
        type,
      });

      this.newItem = null;
      this.$emit("input", this.value);
    },
    onDelete(prop) {
      const removeIndex = this.value.findIndex((v) => v.id === prop.id);

      this.value.splice(removeIndex, 1);
      this.$emit("input", this.value);
    },
    addParam(prop) {
      if (!(this.availableParams && this.availableParams.length)) return;

      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const container = document.getElementById(
          `${this.componentId}-${this.active.id}`
        );

        const newSpan = document.createElement("span");
        newSpan.setAttribute("class", "request-param-hider");
        newSpan.setAttribute("key", prop.name);
        newSpan.setAttribute("data-before", prop.name);
        newSpan.setAttribute("id", `p-${prop.id}`);

        const cloned = newSpan.cloneNode(true);
        const node = cloned.getRootNode();

        range.surroundContents(node);


        const afterRendering = document.querySelector(`#p-${prop.id}`);


        afterRendering.style.width = afterRendering.scrollWidth + "px";

        const htmlAfterSelection = container.innerHTML;

        const replacementIndex = this.value.findIndex(
          (v) => v.id === this.active.id
        );

        if (replacementIndex !== -1)
          this.$set(this.value[replacementIndex], "value", htmlAfterSelection);

        this.$emit("input", this.value);
      }
    },

    inputUpdated() {
      this.$emit("input", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.request-params-editor {
  .property-item {
    border-radius: 4px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
  }

  .list-input {
    width: 100%;
  }

  .inline-edit {
    white-space: nowrap;
    overflow-x: auto;
    display: inline-flex !important;
  }

  .credential-property-text {
    line-height: 26px;
    font-size: 18px;
    font-weight: 600;
    color: #109c1d;
  }

  .edit-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .actions-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .param-edit-container {
    display: flex;
    flex-direction: row;

    .edit-col-row {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }
    .actions-col-row {
      max-width: 220px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 fit-content;
      max-width: 100%;
    }
  }
}
</style>