import {
    API
} from './Api.class';



export class ExecuteTasksAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/execute-tasks', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }


    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/execute-tasks/${id}`, {
            meta
        });

    }


    async update(ticket, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/support/tickets/${ticket.id}`, ticket, {
            meta
        });
    }

}