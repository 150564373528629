

<template>
  <div
    class="sign-in-form-container bg-grey-gradient"
    :class="{ 'loading-finish': selectedPortal }"
  ></div>
</template>
  
  
  
  <script>

export default {
  data: () => ({
  }),
  async mounted() {
    try {
      await this.$store.dispatch("MainStore/googleSignIn", {
        code: this.$route.query.code,
      });
      this.$router.push("/my-polls");
    } catch (error) {
        this.$router.push("/sign-in");
    }
  },
  components: {
  },
  methods: {
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
  
  
  
  