<template>
  <v-fade-transition v-if="visible" leave-absolute>
    <v-card class="elevation-0 pa-0 ma-0 transparent">
      <v-card-title v-if="titleText" class="text-h5">
        {{ titleText }}
      </v-card-title>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <v-card-text v-if="completed" class="status-small">
          <v-row>
            <v-col cols="12" class="completed-status">
              <div class="o-circle c-container__circle o-circle__sign--success">
                <div class="o-circle__sign"></div>
              </div>
              We truly appreciate your input and insights!
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          {{ contentText }}

          <div class="text-center" v-if="stars">
            <v-rating
              v-model="rating"
              :color="color"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              half-increments
              hover
              :size="size"
              @input="onRatingChanged"
            ></v-rating>
            <span
              v-if="allowComment"
              class="leave-a-comment"
              @click="displayComment = !displayComment"
              :style="{
                '--color': color === 'primary' ? '#109c1d' : color,
              }"
            >
              Leave a comment...
            </span>

            <v-slide-y-transition leave-absolute hide-on-leave>
              <HelpFormInput
                v-if="allowComment && displayComment"
                textarea
                :rows="2"
                v-model="comment"
                :label="commentLabel"
                :help-text="commentHelpText"
                :color="color"
              ></HelpFormInput>
            </v-slide-y-transition>
          </div>
        </v-card-text>
      </v-slide-y-transition>
      <v-divider></v-divider>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <v-card-actions v-if="!completed" class="justify-space-between">
          <v-btn text v-if="stars && allowActions" @click="onHide">
            No Thanks
          </v-btn>
          <v-btn text v-else-if="like" icon @click="onDislike">
            <v-icon> mdi-thumb-down </v-icon>
          </v-btn>
          <v-btn
            :color="color"
            v-if="stars && allowActions"
            text
            @click="onSubmit"
          >
            Rate Now
          </v-btn>
          <v-btn :color="color" v-else-if="like" icon @click="onLike">
            <v-icon> mdi-thumb-up </v-icon>
          </v-btn>
        </v-card-actions>
      </v-slide-y-transition>
    </v-card>
  </v-fade-transition>
</template>


<script>
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    stars: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 20,
    },
    allowActions: {
      type: Boolean,
      default: true,
    },
    allowComment: {
      type: Boolean,
      default: true,
    },
    dismissText: {
      type: String,
      default: undefined,
    },
    saveText: {
      type: String,
      default: undefined,
    },
    like: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      default: undefined,
    },
    contentText: {
      type: String,
      default: undefined,
    },
    commentLabel: {
      type: String,
      default: "Your comment",
    },
    commentHelpText: {
      type: String,
      default: "You can give us advanced feedback to make our system better!",
    },
    color: {
      type: String,
      default: "primary",
    },
    starsColor: {
      type: String,
      default: "yellow darken-3",
    },
    // Entity related props
    promptId: {
      default: undefined,
    },
    promptVersion: {
      default: undefined,
    },
    promptVersionId: {
      default: undefined,
    },
    taskId: {
      default: undefined,
    },
    userId: {
      default: undefined,
    },
    apiKey: {
      default: undefined,
    },
    source: {
      default: undefined,
    },
  },
  data: () => ({
    rating: 4.5,
    comment: "",
    displayComment: false,
    visible: true,
    completed: false,
  }),
  components: {
    HelpFormInput,
  },
  methods: {
    onRatingChanged() {
      if (!this.allowActions) return this.onSubmit();
    },
    onLike() {
      this.rating = 5;
      this.onSubmit();
    },
    onDislike() {
      this.rating = 0;
      this.onSubmit();
    },
    onHide() {
      this.visible = false;
    },
    onSubmit() {
      this.completed = true;
      this.$emit("onSubmit", {
        type: this.stars ? "stars" : "like",
        comment: this.comment,
        rating: this.rating,
        user_id: this.userId,
        task_id: this.taskId,
        prompt_id: this.promptId,
        promptVersion: this.promptVersion,
        prompt_version_id: this.promptVersionId,
        source: this.source,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leave-a-comment {
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s;
  &:hover {
    color: var(--color, #109c1d);
  }
}

.completed-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>