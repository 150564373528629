
<template>
  <v-card
    class="elevation-0 transparent page-card prompt-details"
    @mousemove="configConsoleMouseMove"
    @mouseup="configConsoleMouseUp"
    @drag="configConsoleMouseMove"
    @touchmove="configConsoleMouseMove"
  >
    <v-card-title class="page-card-title">
      <h2 class="page-title">Task: {{ task.id }}</h2>
      <v-spacer></v-spacer>

      <v-chip
        v-if="task.status"
        class="ma-2"
        :color="
          task.status.code === 'COMPLETED'
            ? 'primary'
            : task.status.code === 'FAILED'
            ? 'error'
            : 'blue'
        "
        text-color="white"
        label
      >
        {{ task.status.name }}
      </v-chip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="prompt-content">
      <v-row>
        <v-col cols="4">
          <InformationDisplaySimple
            :help-text="'Who initiated the task'"
            :icon="task.user ? 'mdi-account' : 'mdi-key-link'"
            :label="'Executed By'"
            :value="
              task.user ? task.user.email : task.api ? task.api.key : 'N/A'
            "
          ></InformationDisplaySimple>
        </v-col>
        <v-col cols="4">
          <InformationDisplaySimple
            :help-text="'The actual time that has been spent for request execution'"
            :icon="'mdi-timer'"
            :label="'Execution Time'"
            :value="getDuration(task.start, task.end)"
          ></InformationDisplaySimple>
        </v-col>
        <v-col cols="4">
          <InformationDisplaySimple
            :help-text="'The time when the task was initiated'"
            :icon="'mdi-calendar-clock'"
            :label="'Started At'"
            :value="getStartedAt(task.start)"
          ></InformationDisplaySimple>
        </v-col>
      </v-row>
      <v-divider horizontal></v-divider>
      <v-row class="mt-2">
        <v-col cols="6">
          <v-card>
            <v-card-title class="primary-gradient"> Used Prompt </v-card-title>
            <v-card-text>
              <v-list-item class="full-width">
                <v-list-item-icon>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-text-recognition</v-icon>
                  </v-btn>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="">
                    <span v-if="task.promptVersion">
                      {{ task.promptVersion.prompt.name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="task.promptVersion">
                      {{ task.promptVersion.v }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    @click.prevent.stop="
                      $router.push(`/prompts/${task.promptVersion.prompt_id}`)
                    "
                    icon
                  >
                    <v-icon small>mdi-link-variant</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-card-text>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title class="primary-gradient">
              Used Connector
            </v-card-title>
            <v-card-text>
              <v-list-item class="full-width">
                <v-list-item-icon>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-api</v-icon>
                  </v-btn>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="">
                    <span v-if="task.connectorVersion">
                      {{ task.connectorVersion.connector.name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="task.connectorVersion">
                      {{ task.connectorVersion.v }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-btn
                    @click.prevent.stop="
                      $router.push(`/prompts/${task.connectorVersion.connector_id}`)
                    "
                    icon
                  >
                    <v-icon small>mdi-link-variant</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-card-text>
            <v-card-actions> </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
      <v-card class="config-console-card" :style="configConsoleStyle">
        <div
          class="config-console primary-gradient"
          @mousedown="configConsoleMouseDown"
          @dragstart="configConsoleMouseDown"
          @touchstart="configConsoleMouseDown"
        ></div>
        <ExecutionLogConsole
          v-if="task && task.id"
          :value="task"
          ref="console"
        ></ExecutionLogConsole>
      </v-card>
    </v-card-text>
  </v-card>
</template>
      
    <script>
import moment from "moment";
import { ExecuteTasksAPIInstance } from "../../components/api";
import ExecutionLogConsole from "./components/ExecutionLogConsole.vue";
import InformationDisplaySimple from "./components/InformationDisplaySimple.vue";

export default {
  data() {
    return {
      api: ExecuteTasksAPIInstance,
      task: {
        id: "",
        logs: [],
      },
      configConsoleStyle: {
        top: "300px",
        height: `calc(100% - 318px)`,
      },
      configConsoleMove: false,
    };
  },
  components: {
    ExecutionLogConsole,
    InformationDisplaySimple,
  },
  mounted() {},
  created() {
    const { id } = this.$route.params;

    if (!id) this.$router.push("/executions");
    this.task.id = id;
    this.getFromApi();
  },
  methods: {
    configConsoleMouseDown() {
      this.configConsoleMove = true;
    },
    configConsoleMouseUp() {
      this.configConsoleMove = false;
    },
    configConsoleMouseMove(e) {
      if (!this.configConsoleMove) return;

      const touch = e.touches && e.touches[0]; // Get the first touch
      const movementY = touch && touch.movementY; // Access the movementY value

      const newVal =
        parseInt(this.configConsoleStyle.top) +
        (movementY ? movementY : e.movementY);
      this.configConsoleStyle.top = newVal + "px";

      this.configConsoleStyle.height = `calc(100% - ${newVal + 18}px)`;
    },

    getStartedAt(start) {
      if (!start) return "N/A";
      return moment(start).format("YYYY-MM-DD HH:mm:ss");
    },
    getDuration(start, end) {
      if (!start) return "N/A";
      if (!end) return "Calculating...";

      const duration = moment(end).diff(moment(start));
      return moment.utc(duration).format("HH:mm:ss");
    },
    async getFromApi() {
      this.task = await this.api.get(this.task.id);
    },
  },
  watch: {},
};
</script>
  
  
  <style lang="scss" scoped>
.prompt-content {
  height: calc(100% - 20px);
  position: relative;

  @media (max-width: 600px) {
    padding: 4px;
  }
  .prompt-debug-toolbar {
    // height: fit-content!important;
    .main-config-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      justify-content: flex-end;

      @media (max-width: 600px) {
        .connector-selector {
          margin-top: 14px;
        }

        hr {
          display: none;
        }
      }
    }

    .connector-proxy-params-row {
      border-top: 1px solid #e0e0e0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      // justify-content: flex-end;
    }
  }
}

.prompt-details {
  height: 100%;

  .page-card-title {
    justify-content: flex-end;
  }
}
.main-editor {
  line-height: 30px;
  height: 138px;
  overflow-y: auto;

  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.param-prefill {
  padding: 2px;
  line-height: 30px;
  height: 138px;
  overflow-y: auto;
  margin-top: -10px;
  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.config-console {
  width: 100%;
  height: 8px;
  cursor: n-resize;
}
.config-console-card {
  position: absolute;
  top: 300px;
  width: calc(100% - 30px);
  z-index: 3;

  @media (max-width: 600px) {
    top: 444px !important;
    width: calc(100% - 8px);
  }
}

.prefill-col {
  display: flex;
  transition: all 0.3s;
}
.play-params-col {
  display: flex;
  min-width: 340px;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 fit-content;
  transition: all 0.3s;

  .play-params-card {
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    height: 260px;
    overflow: hidden;
    .play-form {
      overflow-y: auto;
      height: 210px;
    }
  }
}
</style>