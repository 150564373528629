<template>
  <v-card class="elevation-0 transparent">
    <v-card-text class="pa-2">
      <v-row class="test-content d-flex justify-center">
        <v-col cols="12" lg="6" xl="6" md="8" sm="12">
          <MessageHistory
            id="messages-scroll-container"
            :value="history"
          ></MessageHistory>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <MessageBox class="mt-4" @onMessage="onMessage"></MessageBox>
    </v-card-actions>
  </v-card>
</template>


<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";
import MessageBox from "../../../components/wad/molecules/common/MessageBox.vue";
import MessageHistory from "../../../components/wad/molecules/common/MessageHistory.vue";
import { v4 as uuidv4 } from "uuid";
import goTo from "vuetify/lib/services/goto";
import { KnowledgeAPIInstance } from "../../../components/api";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MessageBox,
    MessageHistory,
  },
  data() {
    return {
      history: [],
      formatDuration: FormatterHelper.timeDuration,

      scrollTimeout: undefined,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
    async onMessage(message) {
      this.history.push(message);

      const newMessage = {
        id: uuidv4(),
        loading: true,
        isMe: false,
        isBot: true,
        message: "Hi There!",
      };

      this.history.push(newMessage);

      const response = await KnowledgeAPIInstance.test({
        question: message.message,
      });

      console.log('response: ', response)
      this.$set(newMessage, "loading", false);
      this.$set(newMessage, "message", response);

      this.scrollToBottom(newMessage.id);
    },
    scrollToBottom(id) {
      //   container.scrollTop = container.scrollHeight;

      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        return goTo(`#message-${id}`, {
          duration: 800,
          offset: 0,
          easing: "easeInOutCubic",
          container: "#messages-scroll-container",
        });
      }, 200);
    },
  },
};
</script>
        
        
    <style lang="scss" scoped>
.test-content {
  max-height: 400px;
  overflow: auto;
}
</style>
        
        
        
