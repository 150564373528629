<template>
  <v-card class="recent-generations">
    <v-list-item dark class="primary-gradient" two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Recent Generations
        </v-list-item-title>
        <v-list-item-subtitle>Last 10</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text class="content-container">
      <v-list class="content log-list" three-line>
        <v-slide-y-transition class="py-0 px-0" group tag="div">
          <template v-if="value && value.length">
            <ExecuteTaskItem
              v-for="task in value"
              :key="task.id"
              :value="task"
              class="log-item"
            ></ExecuteTaskItem>
          </template>
          <v-alert key="empty-alert" v-else prominent text type="info">
            No recent generations found...
          </v-alert>
        </v-slide-y-transition>
        <div id="bottom-item"></div>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text> Full Report </v-btn>
    </v-card-actions>
  </v-card>
</template>
    
    
<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";
import ExecuteTaskItem from '../../ExecuteTasks/components/ExecuteTaskItem.vue';




export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ExecuteTaskItem
  },
  data() {
    return {
      formatDuration: FormatterHelper.timeDuration,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.recent-generations {
  height: 50vh;

  .content-container {
    max-height: calc(50vh - 140px);
    overflow-y: auto;

    .content {
      height: fit-content;
    }

    .generation-actions-row {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
    
    
    