import {
    API
} from '../Api.class';

export class PromptParamsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(promptId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${promptId}/params`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(promptId, id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${promptId}/params/${id}`, {
            meta
        });

    }


    async create(promptVersionId, promptId, body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/prompts/${promptId}/versions/${promptVersionId}/params`, body, {
            meta
        });
    }

    async update(promptId, param, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/prompts/${promptId}/params/${param.id}`, param, {
            meta
        });
    }

    async delete(promptId, param, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/prompts/${promptId}/params/${param.id}`, {
            meta
        });
    }

}