<template>
  <v-app light class="app">
    <router-view></router-view>

    <!-- <notifications /> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        {
          icon: "bubble_chart",
          title: "Inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Admin Panel",
    };
  },
  components: {
  },
  computed: {
    SSOUrl() {
      return `${process.env.VUE_APP_SSO_API}/api/v1/auth/shared`;
    },
    ...mapState("MainStore", ["isSplash"]),
  },
  async mounted() {
  },
};
</script>



<style lang="scss" scoped>
.app{
  overflow: hidden;
  height: 100%;
}

#sso {
  display: none;
  
}

</style>
