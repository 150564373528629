import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import ResetPassword from '../views/sso/ResetPassword.vue'
import SignInTemplate from '../views/SignInTemplate.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import GoogleCallback from '../views/GoogleCallback.vue'
import PromptLibrary from '../views/PromptsLibrary/PromptLibrary.vue'
import Prompts from '../views/Prompts/Prompts.vue'
import PromptDetails from '../views/Prompts/PromptDetails.vue'
import Connectors from '../views/Connectors_v2/Connectors.vue'
import ConnectorDetails from '../views/Connectors_v2/ConnectorDetails.vue'
import Experiments from '../views/Experiments/Experiments.vue'
import EditorPreview from '../views/Prompts/EditorPreviewv2.vue';
import PricingModels from '../views/PricingModels/PricingModels.vue'
import PricingModelDetails from '../views/PricingModels/PricingModelDetails.vue'
import ExecuteTasks from '../views/ExecuteTasks/ExecuteTasks.vue'
import ExecutionDetails from '../views/ExecuteTasks/ExecutionDetails.vue';
import Feedbacks from '../views/Feedbacks/Feedbacks.vue'
import FeedbackPublic from '../views/Feedbacks/FeedbackPublic.vue'
import Knowledgebase from '../views/Knowledgebase/Knowledgebase.vue'
import APICredentials from '../views/APICredentials/APICredentials.vue';
import SupportTickets from '../views/SupportTickets/SupportTickets.vue';

// import {
//   AuthAPIInstance
// } from '../components/api'
import {
  store
} from '../main'



Vue.use(VueRouter)

const routes = [{
    path: '/sign-in',
    name: 'SignIn',
    component: SignInTemplate,
    children: [{
        path: '/',
        name: 'SignIn',
        component: SignIn,
      },
      {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,

      }
    ]
  },
  {
    path: '/public/editor-preview',
    name: 'PublicEditorPreview',
    component: EditorPreview,
  },
  {
    path: '/public/feedback',
    name: 'FeedbackPublic',
    component: FeedbackPublic,
  },

  {
    path: '/callback/google',
    name: 'Callback',
    component: SignInTemplate,
    children: [{
      path: '/',
      name: 'GoogleCallback',
      component: GoogleCallback,
    }]
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    },
    children: [
      // Dashboard
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          auth: true
        },
      },
      {
        path: '/library',
        name: 'PromptLibrary',
        component: PromptLibrary,
        meta: {
          auth: true
        },
      },
      {
        path: '/prompts',
        name: 'Prompts',
        component: Prompts,
        meta: {
          auth: true
        },
      },

      {
        path: '/prompts/:id',
        name: 'PromptDetails',
        component: PromptDetails,
        meta: {
          auth: true
        },
      },
      {
        path: '/pricing-models',
        name: 'PricingModels',
        component: PricingModels,
        meta: {
          auth: true
        },
      },

      {
        path: '/pricing-models/:id',
        name: 'PricingModelDetails',
        component: PricingModelDetails,
        meta: {
          auth: true
        },
      },
      {
        path: '/connectors',
        name: 'Connectors',
        component: Connectors,
        meta: {
          auth: true
        },
      },
      {
        path: '/connectors/:id',
        name: 'ConnectorDetails',
        component: ConnectorDetails,
        meta: {
          auth: true
        },
      },
      {
        path: '/Experiments',
        name: 'Experiments',
        component: Experiments,
        meta: {
          auth: true
        },
      },
      {
        path: '/executions',
        name: 'Executions',
        component: ExecuteTasks,
        meta: {
          auth: true
        },
      },

      {
        path: '/executions/:id',
        name: 'ExecutionDetails',
        component: ExecutionDetails,
        meta: {
          auth: true
        },
      },

      {
        path: '/feedbacks',
        name: 'Feedbacks',
        component: Feedbacks,
        meta: {
          auth: true
        },
      },
      {
        path: '/knowledgebase',
        name: 'Knowledgebase',
        component: Knowledgebase,
        meta: {
          auth: true
        },
      },
      {
        path: '/api-credentials',
        name: 'APICredentials',
        component: APICredentials,
        meta: {
          auth: true
        },
      },
      {
        path: '/support-requests',
        name: 'SupportTickets',
        component: SupportTickets,
        meta: {
          auth: true
        },
      },


    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router
  .beforeEach(async (to, from, next) => {
    if (to.meta.auth) {
      const [token, user] = await Promise.all([
        store.dispatch("MainStore/getToken"),
        store.dispatch("MainStore/getUser"),
      ]);

      if (!token || !user) return next({
        path: '/sign-in',
        replace: true
      });

      try {
        // await AuthAPIInstance.validateToken(token);
        // console.log("TOKEN VALID");

        return next();

      } catch (error) {
        console.log("ERROR", error);
        store.dispatch("MainStore/setAuth", {});
        return next({
          path: '/sign-in',
          replace: true
        });

      }

    }

    return next();
  });

export default router