export const MOCKED_PROMPT = {
    "created_at": "2023-06-06T14:28:24.154Z",
    "updated_at": "2023-06-13T00:01:34.987Z",
    "id": 9,
    "v": "v1",
    "text": "",
    "execution_config": {},
    "prompt_id": 47,
    "setting_id": null,
    "status": {
        "created_at": "2023-05-21T17:05:00.728Z",
        "updated_at": "2023-05-21T17:05:00.728Z",
        "id": 1,
        "code": "DRAFT",
        "name": "Draft",
        "icon": null,
        "description": "Prompt in this status is only available for the pay mode."
    },
    "defaultConnector": {
        "created_at": "2023-06-16T11:06:26.490Z",
        "updated_at": "2023-06-17T01:53:16.778Z",
        "id": 34,
        "name": "Open AI Connector",
        "iv": "4a7643503070eeb2a130e4ffe1e97260",
        "package": null,
        "status_id": 2,
        "owner_id": 4,
        "versions": [{
            "created_at": "2023-06-16T11:06:26.508Z",
            "updated_at": "2023-06-17T01:27:49.224Z",
            "id": 21,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Content-type\": \"application/json\",\n\t\"Authorization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-bb3fe64b-cc27-4e3d-89f2-929425203c61\" data-before=\"OPEN_AI_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"OPEN_AI_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"7185166a-ee6c-4d61-adc3-2d74eb338a72\" style=\"width: 141px;\"></span>\"\n}",
                    "body": "{\n\t\"model\": \"<span class=\"request-param-hider proxy\" id=\"p-678bf423-dc86-4d05-a82a-233d77f639d9\" data-before=\"model\" prst-param-issuer=\"proxy\" prst-key=\"model\" prst-param-type=\"list\" prst-reference-id=\"02110493-5471-4837-8153-45820db09f44\" style=\"width: 64px;\">gpt-3.5-turbo</span>\",\n\t\"messages\": [\n\t\t{\n\t\t\t\"role\": \"<span class=\"request-param-hider proxy\" id=\"p-0d3b71c5-2aff-4045-a1d7-aff9b7868704\" data-before=\"role\" prst-param-issuer=\"proxy\" prst-key=\"role\" prst-param-type=\"list\" prst-reference-id=\"443e738f-c17e-4ae9-88c5-8d13d0872209\" style=\"width: 54px;\">user</span>\",\n\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-8dd2985a-065f-4b4d-8be4-469af84e2e4c\" data-before=\"PRST_PROMPT\" prst-param-issuer=\"system\" prst-key=\"PRST_PROMPT\" prst-reference-id=\"PRST_PROMPT\" style=\"width: 122px;\">Say this is a test!</span>\"\n\t\t}\n\t],\n\t\"temperature\": <span class=\"request-param-hider proxy\" id=\"p-b7240cf6-692b-4944-9896-26c57fa13151\" data-before=\"temperature\" prst-param-issuer=\"proxy\" prst-key=\"temperature\" prst-param-type=\"string\" prst-reference-id=\"bb42eff2-f9f5-4807-825f-1145bda54624\" style=\"width: 122px;\">0.7</span>\n}",
                    "response": "{\n\t\"id\": \"<span class=\"request-param-hider custom\" id=\"p-3ed9c6e1-19a6-4de1-893c-ff9cda601eec\" data-before=\"chatcmpl_abc_123\" prst-param-issuer=\"custom\" prst-key=\"chatcmpl_abc_123\" prst-param-type=\"string\" style=\"width: 170px;\" prst-param-path=\"external_id\">chatcmpl-abc123</span>\",\n\t\"object\": \"chat.completion\",\n\t\"created\": 1677858242,\n\t\"model\": \"<span class=\"request-param-hider custom\" id=\"p-45aea821-4db7-4e7b-bb40-db9a4e26bec7\" data-before=\"actual_model\" prst-param-issuer=\"custom\" prst-key=\"actual_model\" prst-param-type=\"string\" style=\"width: 131px;\">gpt-3.5-turbo-0301</span>\",\n\t\"usage\": {\n\t\t\"prompt_tokens\": 13,\n\t\t\"completion_tokens\": 7,\n\t\t\"total_tokens\": <span class=\"request-param-hider custom\" id=\"p-e99ea348-0d0d-4056-bd8c-9dda1a2d6f95\" data-before=\"total_tokens\" prst-param-issuer=\"custom\" prst-key=\"total_tokens\" prst-param-type=\"string\" style=\"width: 131px;\">20</span>\n\t},\n\t\"choices\": [\n\t\t{\n\t\t\t\"message\": {\n\t\t\t\t\"role\": \"assistant\",\n\t\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-017e6e3d-fa6f-491b-a17e-bc186af83148\" data-before=\"MODEL_RESPONSE\" prst-param-issuer=\"system\" prst-key=\"MODEL_RESPONSE\" prst-reference-id=\"MODEL_RESPONSE\" style=\"width: 150px;\">\\n\\nThis is a test!</span>\"\n\t\t\t},\n\t\t\t\"finish_reason\": \"stop\",\n\t\t\t\"index\": 0\n\t\t}\n\t]\n}",
                    "url": "https://api.openai.com/v1/chat/completions"
                }
            },
            "proxyParams": [{
                "id": "02110493-5471-4837-8153-45820db09f44",
                "name": "model",
                "value": "gpt-3.5-turbo",
                "type": "list",
                "options": "gpt-4,gpt-3.5-turbo"
            }, {
                "id": "443e738f-c17e-4ae9-88c5-8d13d0872209",
                "name": "role",
                "value": "user",
                "type": "list",
                "options": "assitant,user"
            }, {
                "id": "bb42eff2-f9f5-4807-825f-1145bda54624",
                "name": "temperature",
                "value": "0.7",
                "type": "string"
            }],
            "connector": {
                "created_at": "2023-06-16T11:06:26.490Z",
                "updated_at": "2023-06-17T01:53:16.778Z",
                "id": 34,
                "name": "Latest OpenAI",
                "iv": "4a7643503070eeb2a130e4ffe1e97260",
                "package": null,
                "status_id": 2,
                "owner_id": 4,
                "latest": null,
                "lts": "v1"
            },
            "decryptedCredentials": [{
                "id": "7185166a-ee6c-4d61-adc3-2d74eb338a72",
                "name": "OPEN_AI_TOKEN",
                "value": "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",
                "type": "string"
            }]
        }],
        "latest": {
            "created_at": "2023-06-16T11:06:26.508Z",
            "updated_at": "2023-06-17T01:27:49.224Z",
            "id": 21,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Content-type\": \"application/json\",\n\t\"Authorization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-bb3fe64b-cc27-4e3d-89f2-929425203c61\" data-before=\"OPEN_AI_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"OPEN_AI_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"7185166a-ee6c-4d61-adc3-2d74eb338a72\" style=\"width: 141px;\"></span>\"\n}",
                    "body": "{\n\t\"model\": \"<span class=\"request-param-hider proxy\" id=\"p-678bf423-dc86-4d05-a82a-233d77f639d9\" data-before=\"model\" prst-param-issuer=\"proxy\" prst-key=\"model\" prst-param-type=\"list\" prst-reference-id=\"02110493-5471-4837-8153-45820db09f44\" style=\"width: 64px;\">gpt-3.5-turbo</span>\",\n\t\"messages\": [\n\t\t{\n\t\t\t\"role\": \"<span class=\"request-param-hider proxy\" id=\"p-0d3b71c5-2aff-4045-a1d7-aff9b7868704\" data-before=\"role\" prst-param-issuer=\"proxy\" prst-key=\"role\" prst-param-type=\"list\" prst-reference-id=\"443e738f-c17e-4ae9-88c5-8d13d0872209\" style=\"width: 54px;\">user</span>\",\n\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-8dd2985a-065f-4b4d-8be4-469af84e2e4c\" data-before=\"PRST_PROMPT\" prst-param-issuer=\"system\" prst-key=\"PRST_PROMPT\" prst-reference-id=\"PRST_PROMPT\" style=\"width: 122px;\">Say this is a test!</span>\"\n\t\t}\n\t],\n\t\"temperature\": <span class=\"request-param-hider proxy\" id=\"p-b7240cf6-692b-4944-9896-26c57fa13151\" data-before=\"temperature\" prst-param-issuer=\"proxy\" prst-key=\"temperature\" prst-param-type=\"string\" prst-reference-id=\"bb42eff2-f9f5-4807-825f-1145bda54624\" style=\"width: 122px;\">0.7</span>\n}",
                    "response": "{\n\t\"id\": \"<span class=\"request-param-hider custom\" id=\"p-3ed9c6e1-19a6-4de1-893c-ff9cda601eec\" data-before=\"chatcmpl_abc_123\" prst-param-issuer=\"custom\" prst-key=\"chatcmpl_abc_123\" prst-param-type=\"string\" style=\"width: 170px;\" prst-param-path=\"external_id\">chatcmpl-abc123</span>\",\n\t\"object\": \"chat.completion\",\n\t\"created\": 1677858242,\n\t\"model\": \"<span class=\"request-param-hider custom\" id=\"p-45aea821-4db7-4e7b-bb40-db9a4e26bec7\" data-before=\"actual_model\" prst-param-issuer=\"custom\" prst-key=\"actual_model\" prst-param-type=\"string\" style=\"width: 131px;\">gpt-3.5-turbo-0301</span>\",\n\t\"usage\": {\n\t\t\"prompt_tokens\": 13,\n\t\t\"completion_tokens\": 7,\n\t\t\"total_tokens\": <span class=\"request-param-hider custom\" id=\"p-e99ea348-0d0d-4056-bd8c-9dda1a2d6f95\" data-before=\"total_tokens\" prst-param-issuer=\"custom\" prst-key=\"total_tokens\" prst-param-type=\"string\" style=\"width: 131px;\">20</span>\n\t},\n\t\"choices\": [\n\t\t{\n\t\t\t\"message\": {\n\t\t\t\t\"role\": \"assistant\",\n\t\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-017e6e3d-fa6f-491b-a17e-bc186af83148\" data-before=\"MODEL_RESPONSE\" prst-param-issuer=\"system\" prst-key=\"MODEL_RESPONSE\" prst-reference-id=\"MODEL_RESPONSE\" style=\"width: 150px;\">\\n\\nThis is a test!</span>\"\n\t\t\t},\n\t\t\t\"finish_reason\": \"stop\",\n\t\t\t\"index\": 0\n\t\t}\n\t]\n}",
                    "url": "https://api.openai.com/v1/chat/completions"
                }
            },
            "proxyParams": [{
                "id": "02110493-5471-4837-8153-45820db09f44",
                "name": "model",
                "value": "gpt-3.5-turbo",
                "type": "list",
                "options": "gpt-4,gpt-3.5-turbo"
            }, {
                "id": "443e738f-c17e-4ae9-88c5-8d13d0872209",
                "name": "role",
                "value": "user",
                "type": "list",
                "options": "assitant,user"
            }, {
                "id": "bb42eff2-f9f5-4807-825f-1145bda54624",
                "name": "temperature",
                "value": "0.7",
                "type": "string"
            }],
            "credentials": "9bb95dc1b17f2605c60b404cd63ad8d0f9b6aff80b962c04ff37dcb2b0c42be425bdf718c79a3e5c13b0ae51336cf609e85e645a3eb6d94b380ebf4521dfb0dc8e7e00bad4e3824a3e522239b77dc017a5bdf736777d5f9f53038bef01306c96156070de1d51aea497d9f94b1b95ae72001c2ffde16a84730a742691df56b9633cbff456b114441bacac17de9d9d0152c038884d731f63473f06f462100d7cbb",
            "status_id": 1,
            "owner_id": 4,
            "connector_id": 34,
            "connector": {
                "created_at": "2023-06-16T11:06:26.490Z",
                "updated_at": "2023-06-17T01:53:16.778Z",
                "id": 34,
                "name": "Latest OpenAI",
                "iv": "4a7643503070eeb2a130e4ffe1e97260",
                "package": null,
                "status_id": 2,
                "owner_id": 4,
                "latest": null,
                "lts": "v1"
            },
            "decryptedCredentials": [{
                "id": "7185166a-ee6c-4d61-adc3-2d74eb338a72",
                "name": "OPEN_AI_TOKEN",
                "value": "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",
                "type": "string"
            }]
        },
        "lts": "v1"
    },
    "defaultConnectorVersion": {
        "created_at": "2023-06-16T11:06:26.508Z",
        "updated_at": "2023-06-17T01:27:49.224Z",
        "id": 21,
        "v": "v1",
        "config": {
            "auth": {
                "skip": true
            },
            "execute": {
                "method": "post",
                "headers": "{\n\t\"Content-type\": \"application/json\",\n\t\"Authorization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-bb3fe64b-cc27-4e3d-89f2-929425203c61\" data-before=\"OPEN_AI_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"OPEN_AI_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"7185166a-ee6c-4d61-adc3-2d74eb338a72\" style=\"width: 141px;\"></span>\"\n}",
                "body": "{\n\t\"model\": \"<span class=\"request-param-hider proxy\" id=\"p-678bf423-dc86-4d05-a82a-233d77f639d9\" data-before=\"model\" prst-param-issuer=\"proxy\" prst-key=\"model\" prst-param-type=\"list\" prst-reference-id=\"02110493-5471-4837-8153-45820db09f44\" style=\"width: 64px;\">gpt-3.5-turbo</span>\",\n\t\"messages\": [\n\t\t{\n\t\t\t\"role\": \"<span class=\"request-param-hider proxy\" id=\"p-0d3b71c5-2aff-4045-a1d7-aff9b7868704\" data-before=\"role\" prst-param-issuer=\"proxy\" prst-key=\"role\" prst-param-type=\"list\" prst-reference-id=\"443e738f-c17e-4ae9-88c5-8d13d0872209\" style=\"width: 54px;\">user</span>\",\n\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-8dd2985a-065f-4b4d-8be4-469af84e2e4c\" data-before=\"PRST_PROMPT\" prst-param-issuer=\"system\" prst-key=\"PRST_PROMPT\" prst-reference-id=\"PRST_PROMPT\" style=\"width: 122px;\">Say this is a test!</span>\"\n\t\t}\n\t],\n\t\"temperature\": <span class=\"request-param-hider proxy\" id=\"p-b7240cf6-692b-4944-9896-26c57fa13151\" data-before=\"temperature\" prst-param-issuer=\"proxy\" prst-key=\"temperature\" prst-param-type=\"string\" prst-reference-id=\"bb42eff2-f9f5-4807-825f-1145bda54624\" style=\"width: 122px;\">0.7</span>\n}",
                "response": "{\n\t\"id\": \"<span class=\"request-param-hider custom\" id=\"p-3ed9c6e1-19a6-4de1-893c-ff9cda601eec\" data-before=\"chatcmpl_abc_123\" prst-param-issuer=\"custom\" prst-key=\"chatcmpl_abc_123\" prst-param-type=\"string\" style=\"width: 170px;\" prst-param-path=\"external_id\">chatcmpl-abc123</span>\",\n\t\"object\": \"chat.completion\",\n\t\"created\": 1677858242,\n\t\"model\": \"<span class=\"request-param-hider custom\" id=\"p-45aea821-4db7-4e7b-bb40-db9a4e26bec7\" data-before=\"actual_model\" prst-param-issuer=\"custom\" prst-key=\"actual_model\" prst-param-type=\"string\" style=\"width: 131px;\">gpt-3.5-turbo-0301</span>\",\n\t\"usage\": {\n\t\t\"prompt_tokens\": 13,\n\t\t\"completion_tokens\": 7,\n\t\t\"total_tokens\": <span class=\"request-param-hider custom\" id=\"p-e99ea348-0d0d-4056-bd8c-9dda1a2d6f95\" data-before=\"total_tokens\" prst-param-issuer=\"custom\" prst-key=\"total_tokens\" prst-param-type=\"string\" style=\"width: 131px;\">20</span>\n\t},\n\t\"choices\": [\n\t\t{\n\t\t\t\"message\": {\n\t\t\t\t\"role\": \"assistant\",\n\t\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-017e6e3d-fa6f-491b-a17e-bc186af83148\" data-before=\"MODEL_RESPONSE\" prst-param-issuer=\"system\" prst-key=\"MODEL_RESPONSE\" prst-reference-id=\"MODEL_RESPONSE\" style=\"width: 150px;\">\\n\\nThis is a test!</span>\"\n\t\t\t},\n\t\t\t\"finish_reason\": \"stop\",\n\t\t\t\"index\": 0\n\t\t}\n\t]\n}",
                "url": "https://api.openai.com/v1/chat/completions"
            }
        },
        "proxyParams": [{
            "id": "02110493-5471-4837-8153-45820db09f44",
            "name": "model",
            "value": "gpt-3.5-turbo",
            "type": "list",
            "options": "gpt-4,gpt-3.5-turbo"
        }, {
            "id": "443e738f-c17e-4ae9-88c5-8d13d0872209",
            "name": "role",
            "value": "user",
            "type": "list",
            "options": "assitant,user"
        }, {
            "id": "bb42eff2-f9f5-4807-825f-1145bda54624",
            "name": "temperature",
            "value": "0.7",
            "type": "string"
        }],
        "credentials": "9bb95dc1b17f2605c60b404cd63ad8d0f9b6aff80b962c04ff37dcb2b0c42be425bdf718c79a3e5c13b0ae51336cf609e85e645a3eb6d94b380ebf4521dfb0dc8e7e00bad4e3824a3e522239b77dc017a5bdf736777d5f9f53038bef01306c96156070de1d51aea497d9f94b1b95ae72001c2ffde16a84730a742691df56b9633cbff456b114441bacac17de9d9d0152c038884d731f63473f06f462100d7cbb",
        "status_id": 1,
        "owner_id": 4,
        "connector_id": 34,
        "connector": {
            "created_at": "2023-06-16T11:06:26.490Z",
            "updated_at": "2023-06-17T01:53:16.778Z",
            "id": 34,
            "name": "Latest OpenAI",
            "iv": "4a7643503070eeb2a130e4ffe1e97260",
            "package": null,
            "status_id": 2,
            "owner_id": 4,
            "latest": null,
            "lts": "v1"
        },
        "decryptedCredentials": [{
            "id": "7185166a-ee6c-4d61-adc3-2d74eb338a72",
            "name": "OPEN_AI_TOKEN",
            "value": "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",
            "type": "string"
        }]
    },
    "parameters": [],
    "prompt": {
        "created_at": "2023-06-06T14:28:24.141Z",
        "updated_at": "2023-06-09T11:19:38.373Z",
        "id": 47,
        "name": "Your Awesome Prompt",
        "status_id": 2,
        "owner_id": 4,
        "api_id": null,
        "status": {
            "created_at": "2023-05-21T17:05:00.728Z",
            "updated_at": "2023-05-21T17:05:00.728Z",
            "id": 2,
            "code": "ACTIVE",
            "name": "Active",
            "icon": null,
            "description": "Prompt in this status is available for the production mode."
        },
        "latest": {
            "created_at": "2023-06-06T14:28:24.154Z",
            "updated_at": "2023-06-13T00:01:34.987Z",
            "id": 9,
            "v": "v1",
            "text": "",
            "execution_config": {},
            "prompt_id": 47,
            "setting_id": null,
            "status_id": 1,
            "default_connector_id": 34,
            "default_connector_version_id": 21,
            "prompt": {
                "created_at": "2023-06-06T14:28:24.141Z",
                "updated_at": "2023-06-09T11:19:38.373Z",
                "id": 47,
                "name": "Building prompt",
                "status_id": 2,
                "latest": null,
                "lts": "v1"
            }
        },
        "lts": "v1"
    }
}


export const MOCKED_LOGS = {
    "items": [{
        "created_at": "2023-06-17T10:18:01.858Z",
        "updated_at": "2023-06-17T10:18:01.858Z",
        "id": 1783,
        "date": "2023-06-17T10:18:01.798Z",
        "severity": "info",
        "message": "PROMPT_TASK_INIT_COMPLETED",
        "payload": {},
        "task_id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
        "task": {
            "created_at": "2023-06-17T10:18:01.755Z",
            "updated_at": "2023-06-17T10:18:06.628Z",
            "id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
            "start": "2023-06-17T10:18:01.746Z",
            "end": "2023-06-17T10:18:06.623Z",
            "result": {
                "result": "Looking for a rental building that offers excellent amenities and is located in a prime location? Look no further! Our rental building in [location] is the perfect place for you. With [amenity 1], [amenity 2], and [amenity 3], you'll have everything you need and more! Our units are spacious and modern, offering comfortable living and convenience. Don't miss out on this opportunity to live in the perfect rental building. Contact us today to schedule a tour!",
                "output": {
                    "external_id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 136
                },
                "original": {
                    "id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "object": "chat.completion",
                    "created": 1686997082,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 36,
                        "completion_tokens": 100,
                        "total_tokens": 136
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": "Looking for a rental building that offers excellent amenities and is located in a prime location? Look no further! Our rental building in [location] is the perfect place for you. With [amenity 1], [amenity 2], and [amenity 3], you'll have everything you need and more! Our units are spacious and modern, offering comfortable living and convenience. Don't miss out on this opportunity to live in the perfect rental building. Contact us today to schedule a tour!"
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "displayHtml": "<span class=\"log-record-info\"> Task d2b496e3-d496-4cc4-b35e-7f407ef8cda6 successfully initialized. </span> "
    }, {
        "created_at": "2023-06-17T10:18:01.858Z",
        "updated_at": "2023-06-17T10:18:01.858Z",
        "id": 1784,
        "date": "2023-06-17T10:18:01.809Z",
        "severity": "info",
        "message": "PROMPT_TASK_COMPILE_COMPLETED",
        "payload": {
            "compiled": "Take control of your AI prompts like never before with our powerful and intuitive Prompt Management Tool. "
        },
        "task_id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
        "task": {
            "created_at": "2023-06-17T10:18:01.755Z",
            "updated_at": "2023-06-17T10:18:06.628Z",
            "id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
            "start": "2023-06-17T10:18:01.746Z",
            "end": "2023-06-17T10:18:06.623Z",
            "result": {
                "result": "Looking for a rental building that offers excellent amenities and is located in a prime location? Look no further! Our rental building in [location] is the perfect place for you. With [amenity 1], [amenity 2], and [amenity 3], you'll have everything you need and more! Our units are spacious and modern, offering comfortable living and convenience. Don't miss out on this opportunity to live in the perfect rental building. Contact us today to schedule a tour!",
                "output": {
                    "external_id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 136
                },
                "original": {
                    "id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "object": "chat.completion",
                    "created": 1686997082,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 36,
                        "completion_tokens": 100,
                        "total_tokens": 136
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": "Looking for a rental building that offers excellent amenities and is located in a prime location? Look no further! Our rental building in [location] is the perfect place for you. With [amenity 1], [amenity 2], and [amenity 3], you'll have everything you need and more! Our units are spacious and modern, offering comfortable living and convenience. Don't miss out on this opportunity to live in the perfect rental building. Contact us today to schedule a tour!"
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "displayHtml": "<span class=\"log-record-info\"> Prompt compiled:  </span> <div class=\"log-record-prompt-result-text\"> Take control of your AI prompts like never before with our powerful and intuitive Prompt Management Tool.  </div>"
    }, {
        "created_at": "2023-06-17T10:18:01.859Z",
        "updated_at": "2023-06-17T10:18:01.859Z",
        "id": 1785,
        "date": "2023-06-17T10:18:01.850Z",
        "severity": "info",
        "message": "Trying to execute... post :: https://api.openai.com/v1/chat/completions",
        "payload": {
            "headers": {
                "Content-type": "application/json",
                "Authorization": "Bearer YOUR_API_KEY"
            },
            "params": {},
            "body": {
                "model": "gpt-3.5-turbo",
                "messages": [{
                    "role": "user",
                    "content": "Take control of your AI prompts like never before with our powerful and intuitive Prompt Management Tool. "
                }],
                "temperature": 0.7
            },
            "connector": {
                "id": 34,
                "name": "Latest OpenAI",
                "version": "v1"
            }
        },
        "task_id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
        "task": {
            "created_at": "2023-06-17T10:18:01.755Z",
            "updated_at": "2023-06-17T10:18:06.628Z",
            "id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
            "start": "2023-06-17T10:18:01.746Z",
            "end": "2023-06-17T10:18:06.623Z",
            "result": {
                "result": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

                ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
                
                #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`,
                "output": {
                    "external_id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 136
                },
                "original": {
                    "id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "object": "chat.completion",
                    "created": 1686997082,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 36,
                        "completion_tokens": 100,
                        "total_tokens": 136
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

                            ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
                            
                            #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "displayHtml": "Trying to execute... post :: https://api.openai.com/v1/chat/completions"
    }, {
        "created_at": "2023-06-17T10:18:06.639Z",
        "updated_at": "2023-06-17T10:18:06.639Z",
        "id": 1786,
        "date": "2023-06-17T10:18:06.639Z",
        "severity": "info",
        "message": "PROMPT_TASK_COMPLETED",
        "payload": {
            "result": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

            ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
            
            #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`,
            "output": {
                "external_id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                "actual_model": "gpt-3.5-turbo-0301",
                "total_tokens": 136
            },
            "original": {
                "id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                "object": "chat.completion",
                "created": 1686997082,
                "model": "gpt-3.5-turbo-0301",
                "usage": {
                    "prompt_tokens": 36,
                    "completion_tokens": 100,
                    "total_tokens": 136
                },
                "choices": [{
                    "message": {
                        "role": "assistant",
                        "content": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

                        ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
                        
                        #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`
                    },
                    "finish_reason": "stop",
                    "index": 0
                }]
            }
        },
        "task_id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
        "task": {
            "created_at": "2023-06-17T10:18:01.755Z",
            "updated_at": "2023-06-17T10:18:06.628Z",
            "id": "d2b496e3-d496-4cc4-b35e-7f407ef8cda6",
            "start": "2023-06-17T10:18:01.746Z",
            "end": "2023-06-17T10:18:06.623Z",
            "result": {
                "result": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

                ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
                
                #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`,
                "output": {
                    "external_id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 136
                },
                "original": {
                    "id": "chatcmpl-7SNIIo4eVhqssUBEULATpnW1s2uFg",
                    "object": "chat.completion",
                    "created": 1686997082,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 36,
                        "completion_tokens": 100,
                        "total_tokens": 136
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": `🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

                            ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
                            
                            #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation`
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "displayHtml": `<span class="log-record-completed"> Task d2b496e3-d496-4cc4-b35e-7f407ef8cda6 execution <strong>COMPLETED</strong>. </span> <div class="log-record-prompt-result-text"> 🎉 Exciting Announcement! Introducing #prstai - the groundbreaking self-hosted LLM tool that takes your business to new heights! 🚀

        ✅ No-Code Simplicity: With #prstai, you don't need to be a developer. Enjoy a no-code environment that allows you to customize and tailor your AI workflows without any technical expertise. Simplify your processes with ease! 💪💼
        
        #LLMTech #LLM #LLMSolutions #NoCode #DevOps #Productivity #Efficiency #DataPrivacy #DigitalTransformation </div>`
    }],
    "pagination": {
        "page": 1,
        "pageSize": 10,
        "total": 24,
        "pages": 3
    }
}