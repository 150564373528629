<template>
  <v-list-item class="" @click="$emit('click', value)">
    <v-list-item-avatar>
      <v-icon
        v-if="value.sentimental_score >= 0.75"
        color="success"
        class="mx-auto"
        :size="30"
        >mdi-emoticon-outline</v-icon
      >
      <v-icon
        v-else-if="
          value.sentimental_score >= 0.4 && value.sentimental_score < 0.75
        "
        :size="30"
        color="yellow darken-3"
        class="mx-auto"
        >mdi-emoticon-neutral-outline</v-icon
      >

      <v-icon
        v-else-if="value.sentimental_score < 0.4"
        :size="30"
        color="error"
        class="mx-auto"
        >mdi-emoticon-dead-outline</v-icon
      >
    </v-list-item-avatar>
    <v-list-item-content class="ml-4">
      <v-list-item-title v-if="value.comment"
        >{{ value.comment || -"No Comment" }}
      </v-list-item-title>
      <v-list-item-subtitle class="ml-4">
        <v-rating
          v-if="value.type === 'stars'"
          v-model="value.rating"
          color="primary"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          half-increments
          hover
          :size="20"
          readonly
        ></v-rating>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="ml-4">
        Commented:
        {{ fromNow(value.end) }}
        <span v-if="value.user">
          <v-icon>mdi-account</v-icon>
          by {{ value.user && value.user.email }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
    
    
  <script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      formatDuration: FormatterHelper.timeDuration,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
};
</script>
      
      
  <style lang="scss" scoped>
</style>
      
      
      