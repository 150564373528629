<template>
  <v-card>
    <v-card-text>
      <HelpFormInput
        v-model="value.timeout"
        :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
        :label="'Execution Timeout'"
        required
        @input="$emit('input', value)"
        number
      ></HelpFormInput>

      <HelpFormInput
        v-model="value.retries"
        :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
        :label="'N of retries'"
        required
        @input="$emit('input', value)"
        number
      ></HelpFormInput>

      <HelpFormInput
        v-model="value.retries"
        :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
        :label="'N of concurrent executions'"
        required
        @input="$emit('input', value)"
        number
      ></HelpFormInput>
      <NumberInput></NumberInput>
    </v-card-text>
  </v-card>
</template>
    
    
  <script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import NumberInput from "../../../components/wad/atoms/common/NumberInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: String,
      default: null,
    },
  },
  components: {
    HelpFormInput,
    NumberInput,
  },
  data() {
    return {
      showMenu: false,
      x: 0,
      y: 0,
      tab: null,
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3],
      cutString: FormatterHelper.cutString,
    };
  },
  watch: {
    selected(newVal) {
      console.log("newVal: ", newVal);

      this.tab = `tab_${newVal}`;
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.parameter-tabs-headers {
  width: 150px;
}
</style>
    
    
    