import {
    API
} from './Api.class';

export class AnalyticsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async recentExecutions(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/tasks/recent', {
            meta,
        })

    }

    async promptByDay(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/prompts/daily', {
            meta,
        })

    }

    async recentSentimentalScore(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/sentimental-score/recent', {
            meta,
        })

    }

    async totalGenerationsRecent(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/generations/count', {
            meta,
        })

    }

    async recentErrorsCount(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/errors/count', {
            meta,
        })
    }

    async monthlySpent(filters = {}, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/analytics/pricing/recent', {
            params: filters,
            meta,
        })
    }



}