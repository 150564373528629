<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on"  icon>
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>
          Search for executions: Simply type in the search bar to find the
          execution Task with result or error.
        </span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for executions'"
      ></HelpFormInput>
    </v-toolbar>

    <v-card-text class="page-content">
      <v-list class="content log-list transparent" three-line>
        <v-slide-y-transition class="py-0 px-0" group tag="div">
          <template v-if="items && items.length">
            <ExecuteTaskItem
              v-for="task in items"
              :key="task.id"
              @click="$router.push(`/executions/${task.id}`)"
              :value="task"
              class="log-item"
            ></ExecuteTaskItem>
          </template>
          <v-alert key="empty-alert" v-else prominent text type="info">
            No recent generations found...
          </v-alert>
        </v-slide-y-transition>
      </v-list>
      <div class="text-center mt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="getList"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>
      
    <script>
import {
  ExecuteTasksAPIInstance,
  FeedbackAPIInstance,
} from "../../components/api";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import ExecuteTaskItem from "./components/ExecuteTaskItem.vue";

export default {
  data() {
    return {
      api: ExecuteTasksAPIInstance,
      pagination: {
        page: 1,
        pageSize: 10,
      },
      filter: {},
      items: [],
      removeDialog: false,
      removeCandidate: undefined,
    };
  },
  components: {
    ExecuteTaskItem,
    HelpFormInput,
  },
  async created() {
    this.getList();
  },
  methods: {
    openDetails(value) {
      this.$router.push(`/prompts/${value.id}`);
    },
    async onFeedbackSubmit(rating) {
      await FeedbackAPIInstance.create({
        ...rating,
      });
    },
    async getList() {
      const { items, pagination } = await this.api.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      await this.api.create(
        {
          name: "New Prompt",
        },
        {
          toast: {
            message: "New Prompt Created",
          },
        }
      );
      this.getList();
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Prompt Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
  },
};
</script>


<style lang="scss" scoped>
.content {
  // margin-left: 8px;
  padding: 0; // margin-right: 8px;

  .log-item {
    margin: 0;
    padding: 10px;
  }
}
</style>

