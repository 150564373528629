<template>
  <v-card class="feedback-config elevation-0 transparent">
    <v-card-text class="terminal-content" ref="scrollContainer">
      <v-row>
        <v-col cols="7">
          <v-card>
            <v-card-text>
              {{ integrationHTML }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="copyText">
                <v-icon> mdi-content-copy</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <FeedbackConfigForm v-model="config"> </FeedbackConfigForm>
        </v-col>
        <v-col cols="5">
          <v-card class="mx-2 pa-2">
            <div class="sample-container">
              <div class="sample-text">
                Discover luxury living at Shipyard, NY Manhattan! 🌇 Indulge in
                our stunning rental building with a pool, cozy fireplace, and
                private garage. Elevate your lifestyle - make Shipyard your new
                home! 🏊🔥🚗
              </div>

              <div class="sample-label">SAMPLE</div>
            </div>

            <FeedbackComponent
              :stars="config.stars"
              :like="config.like"
              :size="config.size"
              :allowActions="config.allowActions"
              :allowComment="config.allowComment"
              :dismissText="config.dismissText"
              :saveText="config.saveText"
              :titleText="config.titleText"
              :contentText="config.contentText"
              :commentLabel="config.commentLabel"
              :commentHelpText="config.commentHelpText"
              :color="config.color"
              :starsColor="config.starsColor"
            ></FeedbackComponent>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import FeedbackComponent from "../../Feedbacks/components/FeedbackComponent.vue";
import FeedbackConfigForm from "../../Feedbacks/components/forms/FeedbackConfigForm.vue";

export default {
  props: {},
  components: {
    FeedbackComponent,
    FeedbackConfigForm,
  },
  data() {
    return {
      config: {
        stars: true,
        color: "#109c1d",
        size: 20,
        // starsColor: "",
        allowActions: true,
        allowComment: true,
        commentLabel: "Your comment",
        commentHelpText:
          "You can give us advanced feedback to make our system better!",
      },
    };
  },
  computed: {
    integrationHTML() {
      const urlSearchParams = new URLSearchParams();

      // Iterate over the object properties and append them as query parameters
      for (const key in this.config) {
        urlSearchParams.append(key, this.config[key]);
      }

      // Get the string representation of the query parameters
      const queryString = urlSearchParams.toString();
      return `
            <iframe src="${window.location.protocol}//${
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
      }/public/feedback?${queryString}" frameborder="0"></iframe>
        `;
    },
  },
  methods: {
    async copyText() {
      await navigator.clipboard.writeText(this.integrationHTML);

      this.$toast.open({
        type: "success",
        message: "Code copied to clipboard",
        position: "top-right",
      });
    },
  },
};
</script>
    
<style lang="scss" scoped>
.sample-container {
  position: relative;
  overflow: hidden;

  .sample-text {
    z-index: 2;
  }
  .sample-label {
    font-size: 40px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    // background-color: rgba($color: #000000, $alpha: 0.1);
    font-weight: 600;
    color: rgba($color: #000000, $alpha: 0.15);
    z-index: 1;
    width: 100%;
    letter-spacing: 20px;
    transform: rotate(8deg);
    top: 25px;
    left: -26px;
    text-align: center;
  }
}
</style>
    
    
    