
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-card-title class="page-card-title">
      <!-- <v-btn
        icon
        class="mr-2"
        color="primary"
        @click="() => (newPollDialog = !newPollDialog)"
        ><v-icon>mdi-plus</v-icon>
      </v-btn> -->
      <h2 class="page-title">Library</h2>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="6" lg="6" md="6">
          <LibraryItemVue></LibraryItemVue>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
<script>
import LibraryItemVue from "./components/LibraryItem.vue";

export default {
  data() {
    return {};
  },
  components: {
    LibraryItemVue,
  },
};
</script>