<template>
  <v-list-item
    class="full-height"
    :class="{
      'bg-grey-light': grey,
      'bg-grey': !grey,
    }"
  >
    <v-list-item-icon v-if="info || icon">
      <slot v-if="hasSlot('action')" name="action"></slot>

      <v-progress-circular
        v-else-if="pie"
        :rotate="270"
        :size="50"
        :width="3"
        :value="pieValue"
        :color="color"
      >
        {{ pieValue }}%
      </v-progress-circular>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon color="grey lighten-1"
              >{{ icon || "mdi-information" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class=""> {{ value }} </v-list-item-title>
      <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon v-if="copy">
      <v-btn @click.prevent.stop="copyToClipBoard" icon>
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>


<script>
export default {
  props: {
    value: {},
    label: { type: String, default: "" },
    copy: {
      type: Boolean,
      default: true,
    },
    grey: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    helpText: {
      type: String,
      default: "",
    },
    pie: {
      type: Boolean,
      default: false,
    },
    pieValue: {
      type: Number,
      default: 50,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
  methods: {
    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
    async copyToClipBoard() {
      await navigator.clipboard.writeText(this.value);
      this.$emit("onCopy", {
        value: this.value,
        label: this.label,
      });

      this.$toast.open({
        type: "success",
        message: "Copied to clipboard",
        position: "top-right",
      });
    },
  },
};
</script>