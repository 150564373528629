import { render, staticRenderFns } from "./PricingModelDetails.vue?vue&type=template&id=7b742938&scoped=true&"
import script from "./PricingModelDetails.vue?vue&type=script&lang=js&"
export * from "./PricingModelDetails.vue?vue&type=script&lang=js&"
import style0 from "./PricingModelDetails.vue?vue&type=style&index=0&id=7b742938&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b742938",
  null
  
)

export default component.exports