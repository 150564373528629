var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function({ on: menuOn, attrs: menuAttrs }){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.value)?_c('v-chip',_vm._g(_vm._b({staticClass:"display-chip",attrs:{"label":""},on:{"click":function($event){return _vm.$emit('click', $event)}}},'v-chip',{
            ...menuAttrs,
            ...attrs,
          },false),{
            ...menuOn,
            ...on,
          }),[(_vm.value.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.value.iconColor ? _vm.value.iconColor : 'primary'}},[_vm._v(_vm._s(_vm.value.icon))]):_vm._e(),_c('span',{staticClass:"selection"},[_vm._v(" "+_vm._s(_vm.value.name)+" ")]),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.value.value || _vm.value.default_value)+" ")])],1):_vm._e()]}}],null,true)},[_c('v-card',[_c('v-card-text',[(_vm.value.type === 'list')?_c('v-list',{staticClass:"statuses-list"},_vm._l((_vm.value.options
                .split(',')
                .map((el) => el.trim())),function(item,index){return _c('v-list-item',{key:index,attrs:{"two-line":""},on:{"click":function($event){_vm.value.value = item}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)],1)}),1):_c('HelpFormInput',{attrs:{"label":"Value","value":_vm.value.default_value,"helpText":'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.',"required":""},on:{"input":() => {
                _vm.$emit('input', _vm.value);
                _vm.$emit('onNameChanged', _vm.value);
              }},model:{value:(_vm.value.value),callback:function ($$v) {_vm.$set(_vm.value, "value", $$v)},expression:"value.value"}})],1)],1)],1)]}}])},'v-tooltip',_vm.tooltipAttrs,false),[_c('span',[_vm._v(_vm._s(_vm.helpText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }