<template>
  <div class="request-params-editor">
    <v-toolbar class="json-edit-toolbar elevation-0" dense>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <slot name="top-actions"></slot>

        <!-- ERROR -->
        <v-slide-x-reverse-transition>
          <div v-if="jsonParsingError" class="json-error">
            <v-icon>mdi-alert</v-icon>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="message"
                  >JSON Parsing Error</span
                >
              </template>
              <span>
                {{ jsonParsingError }}
              </span>
            </v-tooltip>
          </div>
        </v-slide-x-reverse-transition>

        <v-spacer></v-spacer>

        <v-divider vertical></v-divider>

        <v-btn @click="beautifyJSON" text>
          <v-icon>mdi-code-json</v-icon>
        </v-btn>

        <v-btn @click="clearAll" text>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn @click="reloadText" text>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-toolbar>

    <HelpFormInput
      class="main-editor"
      :id="componentId"
      html
      :value="value"
      :rows="16"
      @input="(newVal) => $emit('input', newVal)"
      @contextmenu="showParamsMenu"
      @click="onParamClicked"
    >
    </HelpFormInput>

    <!-- MENU -->
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :close-on-click="false"
      :close-on-content-click="false"
      v-click-outside="outsideClickHandler"
    >
      <v-list class="connector-props-list" v-if="!spanClicked">
        <v-list-item v-if="allowCreate" @click="onAddParam">
          <v-list-item-icon
            ><v-icon color="primary">mdi-plus</v-icon></v-list-item-icon
          >
          <v-list-item-title>Use as Parameter</v-list-item-title>
        </v-list-item>
        <v-divider v-if="allowCreate"></v-divider>
        <template v-for="(prop, i) in availableParams">
          <v-list-item
            two-line
            v-if="prop.id"
            :key="prop.id"
            @click="addParam(prop)"
          >
            <v-list-item-content
              :class="{
                'primary--text': prop.issuer === 'system',
                'indigo-darken-2--text': prop.issuer === 'credentials',
                'pink-darken-2--text': prop.issuer === 'proxy',
                'purple-darken-2--text': prop.issuer === 'custom',
              }"
            >
              <v-list-item-title
                >{{ prop.name }}
                <span v-if="prop.issuer"
                  >[{{ prop.issuer }}]</span
                ></v-list-item-title
              >
              <v-list-item-subtitle>{{
                cutString(prop.value, 40, "end")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-else>
            <v-subheader :key="`header-${i}`">{{ prop.section }}</v-subheader>
            <v-divider :key="`divider-${i}`"></v-divider>
          </template>
        </template>
      </v-list>

      <v-list
        v-else-if="spanClicked && selectedId && selectedIssuer !== 'custom'"
      >
        <v-list-item @click="removeParam">
          <v-list-item-icon
            ><v-icon color="error">mdi-delete</v-icon></v-list-item-icon
          >
          <v-list-item-title>Unassign</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-card v-else-if="spanClicked && selectedId" :id="menuId">
        <v-card-title class="pa-1">
          <v-spacer></v-spacer>
          <v-btn icon @click="paramMenu = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <RequestParamsForm
            :value="{
              id: selectedId,
              type: selectedType,
              path: selectedPath,
              name: selectedName,
            }"
            @onNameChanged="(name) => (selectedName = name)"
            @onPathChanged="(path) => (selectedPath = path)"
            @onRemove="removeParam"
            @onSubmit="showMenu = false"
          ></RequestParamsForm>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
  
  <script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { v4 as uuidv4 } from "uuid";
import RequestParamsForm from "./forms/RequestParamsForm.vue";
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
      default: "{}",
    },
    availableParams: {
      type: Array,
      default: () => [],
    },
    addLabel: {
      type: String,
      default: "New property",
    },
    types: {
      type: Boolean,
      default: true,
    },
    generationResult: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedType: {
      get() {
        const element = document.getElementById(`p-${this.selectedId}`);

        return element.getAttribute("prst-param-type") || "";
      },
      set(newValue) {
        const element = document.getElementById(`p-${this.selectedId}`);

        element.setAttribute("prst-param-type", newValue);

        const container = document.getElementById(`${this.componentId}`);

        this.$emit("input", container.innerHTML);
      },
    },

    selectedName: {
      get() {
        const element = document.getElementById(`p-${this.selectedId}`);

        return element.getAttribute("prst-key") || "";
      },
      set(newValue) {
        const element = document.getElementById(`p-${this.selectedId}`);

        element.setAttribute("prst-key", newValue);
        element.setAttribute("data-before", newValue);

        const tempElement = document.createElement("span");
        tempElement.setAttribute("class", "request-param-hider");
        tempElement.style.visibility = "hidden";
        tempElement.style.position = "absolute";
        tempElement.style.top = 0;
        tempElement.style.left = 0;
        tempElement.style.fontSize = "16px";

        tempElement.textContent = newValue;

        // Append the temporary element to the DOM
        document.body.appendChild(tempElement);

        // Get the width of the temporary element
        const textWidth = tempElement.offsetWidth;

        // Remove the temporary element from the DOM
        document.body.removeChild(tempElement);

        // Set the calculated width to the target element
        element.style.width = `${textWidth}px`;

        const container = document.getElementById(`${this.componentId}`);

        this.$emit("input", container.innerHTML);
      },
    },

    selectedPath: {
      get() {
        const element = document.getElementById(`p-${this.selectedId}`);

        return element.getAttribute("prst-param-path") || "";
      },
      set(newValue) {
        const element = document.getElementById(`p-${this.selectedId}`);

        element.setAttribute("prst-param-path", newValue);

        const container = document.getElementById(`${this.componentId}`);

        this.$emit("input", container.innerHTML);
      },
    },

    selectedIssuer: {
      get() {
        const element = document.getElementById(`p-${this.selectedId}`);

        return element.getAttribute("prst-param-issuer") || "";
      },
      // set(newValue) {
      //   const element = document.getElementById(`p-${this.selectedId}`);

      //   element.setAttribute("prst-param-path", newValue);

      //   const container = document.getElementById(`${this.componentId}`);

      //   this.$emit("input", container.innerHTML);
      // },
    },
  },

  data() {
    return {
      componentId: uuidv4(),
      menuId: uuidv4(),
      showMenu: false,
      paramMenu: false,
      x: 0,
      y: 0,
      cutString: FormatterHelper.cutString,
      listHelpText: 'Provide a list of possible values separate by ",". ',
      spanClicked: false,
      selectedId: undefined,

      newItem: null,
      active: null,

      jsonParsingError: undefined,
      parsingInterval: undefined,
    };
  },
  components: {
    HelpFormInput,
    RequestParamsForm,
    // MenuTypesPicker,
  },
  mounted() {
    this.parsingInterval = setInterval(() => {
      this.JsonFormatChecker();
    }, 1000);
  },
  unmounted() {
    clearInterval(this.parsingInterval);
  },
  methods: {
    reloadText() {
      const regex = /<span\b[^>]*>(.*?)<\/span>/gi;
      const clean = this.value.replace(regex, "$1");

      this.$emit("input", clean);
    },
    clearAll() {
      const clear = "{}";
      const container = document.getElementById(`${this.componentId}`);
      container.innerHTML = clear;

      this.$emit("input", clear);
    },
    JsonFormatChecker() {
      try {
        FormatterHelper.beautifyJSON(this.value);
        this.jsonParsingError = undefined;
      } catch (error) {
        this.jsonParsingError = error.message;
      }
    },

    beautifyJSON() {
      try {
        const prettified = FormatterHelper.beautifyJSON(this.value);

        console.log("prettified: ", prettified);

        const container = document.getElementById(`${this.componentId}`);
        container.innerHTML = prettified;

        console.log("container.innerHTML: ", container.innerHTML);

        this.$emit("input", prettified);

        console.log("this.value: ", this.value);

        this.jsonParsingError = undefined;
      } catch (error) {
        console.log("beautifyJSON Error: ", error.message);
        this.jsonParsingError = error.message;
      }
    },
    onParamClicked(event) {
      if (event.target.tagName.toLowerCase() === "span") {
        event.preventDefault();
        event.stopPropagation();

        this.spanClicked = true;
        const [id] = event.target.getAttribute("id").split("p-").reverse();
        this.selectedId = id;

        this.x = event.clientX;
        this.y = event.clientY;
        this.showMenu = true;
      }
    },
    showParamsMenu(e) {
      e.preventDefault();
      if (e.target.tagName.toLowerCase() === "span") {
        this.spanClicked = true;
        const [id] = e.target.getAttribute("id").split("p-").reverse();
        this.selectedId = id;
      } else this.spanClicked = false;

      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = true;
    },

    outsideClickHandler(e) {
      const menu = document.getElementById(this.menuId);

      if (
        (e.target.tagName.toLowerCase() !== "span" &&
          menu &&
          !menu.contains(e.target)) ||
        !menu
      ) {
        this.showMenu = false;
      }
    },
    onAddParam() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const name = _.snakeCase(range.toString()) || "new_param";
        const newParam = {
          name,
          type: "string",
          issuer: "custom",
        };

        this.addParam(newParam);
      }

      this.showMenu = false;
    },
    addParam(
      prop = {
        type: "string",
        issuer: "custom",
      }
    ) {
      if (!(this.availableParams && this.availableParams.length)) return;

      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const newParam = {
          ...prop,
          id: uuidv4(),
          reference: prop.id ? prop : undefined,
        };

        const range = selection.getRangeAt(0);

        const newSpan = document.createElement("span");
        newSpan.setAttribute(
          "class",
          "request-param-hider " + (newParam.issuer ? newParam.issuer : "")
        );
        newSpan.setAttribute("id", `p-${newParam.id}`);
        newSpan.setAttribute("data-before", newParam.name);
        newSpan.setAttribute("prst-param-issuer", newParam.issuer);
        newSpan.setAttribute("prst-key", newParam.name);

        if (newParam.type)
          newSpan.setAttribute("prst-param-type", newParam.type);
        if (newParam.path)
          newSpan.setAttribute("prst-param-path", newParam.path);
        if (newParam.reference)
          newSpan.setAttribute("prst-reference-id", newParam.reference.id);

        const cloned = newSpan.cloneNode(true);
        const node = cloned.getRootNode();

        range.surroundContents(node);

        const afterRendering = document.querySelector(`#p-${newParam.id}`);

        afterRendering.style.width = afterRendering.scrollWidth + "px";

        const container = document.getElementById(`${this.componentId}`);

        this.showMenu = false;

        this.$emit("input", container.innerHTML);
      }
    },
    removeParam() {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(this.value, "text/html");

      const spans = parsedDocument.querySelectorAll(`#p-${this.selectedId}`);

      spans.forEach((span) => {
        const textNode = document.createTextNode(span.textContent);
        span.parentNode.replaceChild(textNode, span);
      });

      this.$emit("input", parsedDocument.body.innerHTML);
      this.showMenu = false;
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.request-params-editor {
  .json-edit-toolbar {
    border-radius: 4px;
    margin-bottom: 14px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);

    .json-error {
      color: red;
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      justify-content: center;

      .message {
        margin-top: 4px;
        margin-left: 6px;
      }
      i {
        color: red;
      }
    }
  }
  .main-editor {
    overflow: auto;
  }
  .property-item {
    border-radius: 4px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
  }

  .list-input {
    width: 100%;
  }

  .inline-edit {
    white-space: nowrap;
    overflow-x: auto;
    display: inline-flex !important;
  }

  .credential-property-text {
    line-height: 26px;
    font-size: 18px;
    font-weight: 600;
    color: #109c1d;
  }

  .edit-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .actions-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .param-edit-container {
    display: flex;
    flex-direction: row;

    .edit-col-row {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }
    .actions-col-row {
      max-width: 220px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 fit-content;
      max-width: 100%;
    }
  }
}
</style>