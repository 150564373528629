<template>
  <v-list-item class="" @click="$emit('click', value)">
    <v-list-item-avatar> <v-icon>mdi-file</v-icon> </v-list-item-avatar>
    <v-list-item-content class="ml-4">
      <v-list-item-title>{{ value.body || -"Empty" }} </v-list-item-title>

      <v-list-item-subtitle class="ml-4">
        Added:
        {{ fromNow(value.end) }}
        <span v-if="value.owner">
          <v-icon>mdi-account</v-icon>
          by {{ value.owner && value.owner.email }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
      
      
    <script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      formatDuration: FormatterHelper.timeDuration,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
};
</script>
        
        
    <style lang="scss" scoped>
</style>
        
        
        