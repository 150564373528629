<template>
  <v-card color="transparent elevation-0 " :dark="dark">
    <h2 class="sign-in-name">{{ name }}</h2>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          prepend-icon="mdi-account"
          label="E-mail"
          required
          class="sign-in-input"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          prepend-icon="mdi-lock"
          label="Password"
          type="password"
          required
          class="sign-in-input"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!valid || pLoading"
        color="primary"
        class="mr-4 sign-in-btn"
        @click="validate"
        :loading="pLoading"
        
      >
        Sign In
      </v-btn>
    </v-card-actions>

  </v-card>
</template>



<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "Customer Portal",
    },
  },
  data() {
    return {
      pLoading: this.loading,

      valid: true,
      password: "",
      email: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  methods: {
    validate() {
      const t = this.$refs.form.validate();
      if (t)
        this.$emit("onSubmit", {
          password: this.password,
          email: this.email,
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    loading(newVal) {
      this.pLoading = newVal;
    },
  },
};
</script>




<style lang="scss" scoped>
.sign-in-name {
  display: flex;
  justify-content: center;

  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.sign-in-input {
  &:focus {
    color: #000 !important;
  }
  &:active {
    color: #000 !important;
  }
}

.sign-in-btn{
  font-weight: 600;
}

.alternative-sign-in {
  padding: 20px;
  margin-top: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.google-btn {
  width: 100%;
  min-width: 184px;
  // max-width: 384px;
  height: 42px;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  cursor: hand;
  align-self: center;
  user-select: none;
  transition: all 400ms ease 0s;
  display: flex;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  user-select: none;
}
.google-btn .google-icon-svg {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  user-select: none;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 14px 40px 40px;
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Roboto;
  user-select: none;
}
.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}
.google-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #f8f8f8;
  color: #fff;
  user-select: none;
}

.normal-btn {
  margin: 25px;
  width: auto;
  display: inline-block;
  min-width: auto;
  // max-width: 184px;
  height: 42px;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  cursor: hand;
  align-self: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 400ms ease 0s;
}
.normal-btn .btn-text {
  float: left;
  margin: 11px 14px 11px 14px;
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: Roboto;
  user-select: none;
}
.normal-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}
.normal-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #f8f8f8;
  color: #fff;
  user-select: none;
}
</style>