import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import VueLocalStorage from 'vue-localstorage'
import VueFullscreen from 'vue-fullscreen'
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import VueToast from 'vue-toast-notification';
import "flag-icons/css/flag-icons.min.css";

import "pretty-print-json/dist/css/pretty-print-json.min.css"


// import './assets/css/MaterialIcons.css';
import '@mdi/font/css/materialdesignicons.css'

import './assets/sass/custom.scss'
import Vuex from 'vuex'
import  * as Stores  from './stores'
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';



Vue.use(Vuex)


// =============== DIRECTIVES =======================
// Vue.directive('click-out', {
//   bind: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {

//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         if (vnode.context[binding.expression])
//           vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// });

export const store = new Vuex.Store({
  modules: {
    ...Stores
  },
})



Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueFullscreen)
Vue.use(VueToast);

Vue.use(VueFileAgent)

Vue.use(VueLocalStorage)
// Or you can specify any other name and use it via this.$ls, this.$whatEverYouWant
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true //created computed members from your variable declarations
})
// Vue.use(MainStore);


new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')