export const MOCKED_CONNECTORS = {
    "items": [{
        "created_at": "2023-06-17T01:46:26.944Z",
        "updated_at": "2023-06-17T01:46:26.944Z",
        "id": 36,
        "name": "Rytr.me",
        "package": null,
        "status_id": 1,
        "status": {
            "created_at": "2023-05-22T22:10:50.637Z",
            "updated_at": "2023-05-22T22:10:50.637Z",
            "code": "ACTIVE",
            "name": "Active",
            "icon": null,
            "description": "Connector in this status is only available for the pay mode."
        },
        "versions": [{
            "created_at": "2023-06-17T01:46:26.957Z",
            "updated_at": "2023-06-17T01:50:46.907Z",
            "id": 23,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Authentization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-e361ef81-7c21-4b38-8c48-f90694126117\" data-before=\"RYTR_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"RYTR_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"457bb4e2-3f4f-4682-8f72-fc1c1e9d93f3\" style=\"width: 112px;\"></span>\"\n}",
                    "url": "https://api.rytr.me/v1/ryte",
                    "response": "{\n\t\"languageId\": \"LANUGAGE ID\",\n\t\"toneId\": \"TONE ID\",\n\t\"useCaseId\": \"USE-CASE ID\",\n\t\"inputContexts\": {\n\t\t\"USE-CASE CONTEXT-INPUT KEY-LABEL\": \"VALUE\"\n\t},\n\t\"variations\": 1,\n\t\"userId\": \"UNIQUE USER ID\",\n\t\"format\": \"html\",\n\t\"creativityLevel\": \"default\"\n}"
                }
            },
            "proxyParams": null,
            "status_id": 1,
            "connector_id": 36,
        }],
        "latest": {
            "created_at": "2023-06-17T01:46:26.957Z",
            "updated_at": "2023-06-17T01:50:46.907Z",
            "id": 23,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Authentization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-e361ef81-7c21-4b38-8c48-f90694126117\" data-before=\"RYTR_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"RYTR_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"457bb4e2-3f4f-4682-8f72-fc1c1e9d93f3\" style=\"width: 112px;\"></span>\"\n}",
                    "url": "https://api.rytr.me/v1/ryte",
                    "response": "{\n\t\"languageId\": \"LANUGAGE ID\",\n\t\"toneId\": \"TONE ID\",\n\t\"useCaseId\": \"USE-CASE ID\",\n\t\"inputContexts\": {\n\t\t\"USE-CASE CONTEXT-INPUT KEY-LABEL\": \"VALUE\"\n\t},\n\t\"variations\": 1,\n\t\"userId\": \"UNIQUE USER ID\",\n\t\"format\": \"html\",\n\t\"creativityLevel\": \"default\"\n}"
                }
            },
            "proxyParams": null,
            "status_id": 1,
            "connector_id": 36,
        },
        "lts": "v1"
    }, {
        "created_at": "2023-06-16T11:06:26.490Z",
        "updated_at": "2023-06-17T01:53:16.778Z",
        "id": 34,
        "name": "OpenAI GPT",
        "package": null,
        "status_id": 2,
        "status": {
            "created_at": "2023-05-22T22:10:50.637Z",
            "updated_at": "2023-05-22T22:10:50.637Z",
            "id": 2,
            "code": "ACTIVE",
            "name": "Active",
            "icon": null,
            "description": "Connector in this status is available for the production mode."
        },
        "versions": [{
            "created_at": "2023-06-16T11:06:26.508Z",
            "updated_at": "2023-06-17T01:27:49.224Z",
            "id": 21,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Content-type\": \"application/json\",\n\t\"Authorization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-bb3fe64b-cc27-4e3d-89f2-929425203c61\" data-before=\"OPEN_AI_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"OPEN_AI_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"7185166a-ee6c-4d61-adc3-2d74eb338a72\" style=\"width: 141px;\"></span>\"\n}",
                    "body": "{\n\t\"model\": \"<span class=\"request-param-hider proxy\" id=\"p-678bf423-dc86-4d05-a82a-233d77f639d9\" data-before=\"model\" prst-param-issuer=\"proxy\" prst-key=\"model\" prst-param-type=\"list\" prst-reference-id=\"02110493-5471-4837-8153-45820db09f44\" style=\"width: 64px;\">gpt-3.5-turbo</span>\",\n\t\"messages\": [\n\t\t{\n\t\t\t\"role\": \"<span class=\"request-param-hider proxy\" id=\"p-0d3b71c5-2aff-4045-a1d7-aff9b7868704\" data-before=\"role\" prst-param-issuer=\"proxy\" prst-key=\"role\" prst-param-type=\"list\" prst-reference-id=\"443e738f-c17e-4ae9-88c5-8d13d0872209\" style=\"width: 54px;\">user</span>\",\n\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-8dd2985a-065f-4b4d-8be4-469af84e2e4c\" data-before=\"PRST_PROMPT\" prst-param-issuer=\"system\" prst-key=\"PRST_PROMPT\" prst-reference-id=\"PRST_PROMPT\" style=\"width: 122px;\">Say this is a test!</span>\"\n\t\t}\n\t],\n\t\"temperature\": <span class=\"request-param-hider proxy\" id=\"p-b7240cf6-692b-4944-9896-26c57fa13151\" data-before=\"temperature\" prst-param-issuer=\"proxy\" prst-key=\"temperature\" prst-param-type=\"string\" prst-reference-id=\"bb42eff2-f9f5-4807-825f-1145bda54624\" style=\"width: 122px;\">0.7</span>\n}",
                    "response": "{\n\t\"id\": \"<span class=\"request-param-hider custom\" id=\"p-3ed9c6e1-19a6-4de1-893c-ff9cda601eec\" data-before=\"chatcmpl_abc_123\" prst-param-issuer=\"custom\" prst-key=\"chatcmpl_abc_123\" prst-param-type=\"string\" style=\"width: 170px;\" prst-param-path=\"external_id\">chatcmpl-abc123</span>\",\n\t\"object\": \"chat.completion\",\n\t\"created\": 1677858242,\n\t\"model\": \"<span class=\"request-param-hider custom\" id=\"p-45aea821-4db7-4e7b-bb40-db9a4e26bec7\" data-before=\"actual_model\" prst-param-issuer=\"custom\" prst-key=\"actual_model\" prst-param-type=\"string\" style=\"width: 131px;\">gpt-3.5-turbo-0301</span>\",\n\t\"usage\": {\n\t\t\"prompt_tokens\": 13,\n\t\t\"completion_tokens\": 7,\n\t\t\"total_tokens\": <span class=\"request-param-hider custom\" id=\"p-e99ea348-0d0d-4056-bd8c-9dda1a2d6f95\" data-before=\"total_tokens\" prst-param-issuer=\"custom\" prst-key=\"total_tokens\" prst-param-type=\"string\" style=\"width: 131px;\">20</span>\n\t},\n\t\"choices\": [\n\t\t{\n\t\t\t\"message\": {\n\t\t\t\t\"role\": \"assistant\",\n\t\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-017e6e3d-fa6f-491b-a17e-bc186af83148\" data-before=\"MODEL_RESPONSE\" prst-param-issuer=\"system\" prst-key=\"MODEL_RESPONSE\" prst-reference-id=\"MODEL_RESPONSE\" style=\"width: 150px;\">\\n\\nThis is a test!</span>\"\n\t\t\t},\n\t\t\t\"finish_reason\": \"stop\",\n\t\t\t\"index\": 0\n\t\t}\n\t]\n}",
                    "url": "https://api.openai.com/v1/chat/completions"
                }
            },
            "proxyParams": [{
                "id": "02110493-5471-4837-8153-45820db09f44",
                "name": "model",
                "value": "gpt-3.5-turbo",
                "type": "list",
                "options": "gpt-4,gpt-3.5-turbo"
            }, {
                "id": "443e738f-c17e-4ae9-88c5-8d13d0872209",
                "name": "role",
                "value": "user",
                "type": "list",
                "options": "assitant,user"
            }, {
                "id": "bb42eff2-f9f5-4807-825f-1145bda54624",
                "name": "temperature",
                "value": "0.7",
                "type": "string"
            }],
            "status_id": 1,
            "connector_id": 34,
        }],
        "latest": {
            "created_at": "2023-06-16T11:06:26.508Z",
            "updated_at": "2023-06-17T01:27:49.224Z",
            "id": 21,
            "v": "v1",
            "config": {
                "auth": {
                    "skip": true
                },
                "execute": {
                    "method": "post",
                    "headers": "{\n\t\"Content-type\": \"application/json\",\n\t\"Authorization\": \"Bearer <span class=\"request-param-hider credentials\" id=\"p-bb3fe64b-cc27-4e3d-89f2-929425203c61\" data-before=\"OPEN_AI_TOKEN\" prst-param-issuer=\"credentials\" prst-key=\"OPEN_AI_TOKEN\" prst-param-type=\"string\" prst-reference-id=\"7185166a-ee6c-4d61-adc3-2d74eb338a72\" style=\"width: 141px;\"></span>\"\n}",
                    "body": "{\n\t\"model\": \"<span class=\"request-param-hider proxy\" id=\"p-678bf423-dc86-4d05-a82a-233d77f639d9\" data-before=\"model\" prst-param-issuer=\"proxy\" prst-key=\"model\" prst-param-type=\"list\" prst-reference-id=\"02110493-5471-4837-8153-45820db09f44\" style=\"width: 64px;\">gpt-3.5-turbo</span>\",\n\t\"messages\": [\n\t\t{\n\t\t\t\"role\": \"<span class=\"request-param-hider proxy\" id=\"p-0d3b71c5-2aff-4045-a1d7-aff9b7868704\" data-before=\"role\" prst-param-issuer=\"proxy\" prst-key=\"role\" prst-param-type=\"list\" prst-reference-id=\"443e738f-c17e-4ae9-88c5-8d13d0872209\" style=\"width: 54px;\">user</span>\",\n\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-8dd2985a-065f-4b4d-8be4-469af84e2e4c\" data-before=\"PRST_PROMPT\" prst-param-issuer=\"system\" prst-key=\"PRST_PROMPT\" prst-reference-id=\"PRST_PROMPT\" style=\"width: 122px;\">Say this is a test!</span>\"\n\t\t}\n\t],\n\t\"temperature\": <span class=\"request-param-hider proxy\" id=\"p-b7240cf6-692b-4944-9896-26c57fa13151\" data-before=\"temperature\" prst-param-issuer=\"proxy\" prst-key=\"temperature\" prst-param-type=\"string\" prst-reference-id=\"bb42eff2-f9f5-4807-825f-1145bda54624\" style=\"width: 122px;\">0.7</span>\n}",
                    "response": "{\n\t\"id\": \"<span class=\"request-param-hider custom\" id=\"p-3ed9c6e1-19a6-4de1-893c-ff9cda601eec\" data-before=\"chatcmpl_abc_123\" prst-param-issuer=\"custom\" prst-key=\"chatcmpl_abc_123\" prst-param-type=\"string\" style=\"width: 170px;\" prst-param-path=\"external_id\">chatcmpl-abc123</span>\",\n\t\"object\": \"chat.completion\",\n\t\"created\": 1677858242,\n\t\"model\": \"<span class=\"request-param-hider custom\" id=\"p-45aea821-4db7-4e7b-bb40-db9a4e26bec7\" data-before=\"actual_model\" prst-param-issuer=\"custom\" prst-key=\"actual_model\" prst-param-type=\"string\" style=\"width: 131px;\">gpt-3.5-turbo-0301</span>\",\n\t\"usage\": {\n\t\t\"prompt_tokens\": 13,\n\t\t\"completion_tokens\": 7,\n\t\t\"total_tokens\": <span class=\"request-param-hider custom\" id=\"p-e99ea348-0d0d-4056-bd8c-9dda1a2d6f95\" data-before=\"total_tokens\" prst-param-issuer=\"custom\" prst-key=\"total_tokens\" prst-param-type=\"string\" style=\"width: 131px;\">20</span>\n\t},\n\t\"choices\": [\n\t\t{\n\t\t\t\"message\": {\n\t\t\t\t\"role\": \"assistant\",\n\t\t\t\t\"content\": \"<span class=\"request-param-hider system\" id=\"p-017e6e3d-fa6f-491b-a17e-bc186af83148\" data-before=\"MODEL_RESPONSE\" prst-param-issuer=\"system\" prst-key=\"MODEL_RESPONSE\" prst-reference-id=\"MODEL_RESPONSE\" style=\"width: 150px;\">\\n\\nThis is a test!</span>\"\n\t\t\t},\n\t\t\t\"finish_reason\": \"stop\",\n\t\t\t\"index\": 0\n\t\t}\n\t]\n}",
                    "url": "https://api.openai.com/v1/chat/completions"
                }
            },
            "proxyParams": [{
                "id": "02110493-5471-4837-8153-45820db09f44",
                "name": "model",
                "value": "gpt-3.5-turbo",
                "type": "list",
                "options": "gpt-4,gpt-3.5-turbo"
            }, {
                "id": "443e738f-c17e-4ae9-88c5-8d13d0872209",
                "name": "role",
                "value": "user",
                "type": "list",
                "options": "assitant,user"
            }, {
                "id": "bb42eff2-f9f5-4807-825f-1145bda54624",
                "name": "temperature",
                "value": "0.7",
                "type": "string"
            }],
            "status_id": 1,
            "connector_id": 34,
        },
        "lts": "v1"
    }],
    "pagination": {
        "page": 1,
        "pageSize": 50,
        "total": 2,
        "pages": 1
    }
}


export const MOCKED_FEEDBACKS = {
    "items": [{
        "created_at": "2023-06-17T01:29:38.311Z",
        "updated_at": "2023-06-17T01:29:38.311Z",
        "id": "3fed9918-eac9-49eb-92e1-fc41fcd71898",
        "type": "stars",
        "source": "PRST Portal",
        "rating": 0.5,
        "sentimental_score": -0.08823529411764709,
        "comment": "Too bad for the AI",
        "user_id": 4,
        "api_id": null,
        "prompt_version_id": 9,
        "prompt_id": 47,
        "task_id": "332183ff-7e18-4d5d-a91b-c8b42c4048d2",
        "user": {
            "created_at": "2023-05-21T20:04:42.333Z",
            "updated_at": "2023-06-17T12:18:02.623Z",
            "id": 4,
            "email": "somebody@prst.ai",
            "status_id": 1,
            "last_login": "2023-06-17T12:18:02.609Z",
        },
        "api": null,
        "task": {
            "created_at": "2023-06-17T01:27:49.473Z",
            "updated_at": "2023-06-17T01:27:51.113Z",
            "id": "332183ff-7e18-4d5d-a91b-c8b42c4048d2",
            "start": "2023-06-17T01:27:49.467Z",
            "end": "2023-06-17T01:27:51.111Z",
            "result": {
                "result": "New rental building with great amenities in [location]. Test it out today!",
                "output": {
                    "external_id": "chatcmpl-7SF1CZA56Cn7l9XAeT3jREyjZs7YS",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 52
                },
                "original": {
                    "id": "chatcmpl-7SF1CZA56Cn7l9XAeT3jREyjZs7YS",
                    "object": "chat.completion",
                    "created": 1686965270,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 37,
                        "completion_tokens": 15,
                        "total_tokens": 52
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": "New rental building with great amenities in [location]. Test it out today!"
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "prompt": {
            "created_at": "2023-06-06T14:28:24.141Z",
            "updated_at": "2023-06-09T11:19:38.373Z",
            "id": 47,
            "name": "Building prompt",
            "status_id": 2,
            "owner_id": 4,
            "api_id": null,
            "latest": null,
            "lts": "v1"
        },
        "promptVersion": {
            "created_at": "2023-06-06T14:28:24.154Z",
            "updated_at": "2023-06-13T00:01:34.987Z",
            "id": 9,
            "v": "v1",
            "text": "Write an FacebookAd description <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-263\" key=\"263\" data-before=\"200\" style=\"content: &quot;World&quot;; width: 48px;\">200</span> character length maximum for rental building <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-264\" key=\"264\" data-before=\"building\" style=\"content: &quot;World&quot;; width: 102px;\">building</span> with these amenities <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-265\" key=\"265\" data-before=\"amenities\" style=\"content: &quot;World&quot;; width: 113px;\">amenities</span> located in location. Do not use weblinks.",
            "execution_config": {},
            "prompt_id": 47,
            "setting_id": null,
            "status_id": 1,
            "owner_id": 4,
            "api_id": null,
            "default_connector_id": 34,
            "default_connector_version_id": 21,
            "prompt": {
                "created_at": "2023-06-06T14:28:24.141Z",
                "updated_at": "2023-06-09T11:19:38.373Z",
                "id": 47,
                "name": "Building prompt",
                "status_id": 2,
                "owner_id": 4,
                "api_id": null,
                "latest": null,
                "lts": "v1"
            }
        }
    }, {
        "created_at": "2023-06-16T20:15:00.641Z",
        "updated_at": "2023-06-16T20:15:00.641Z",
        "id": "42386d83-ce07-409e-b66e-cc9f901a59d3",
        "type": "stars",
        "source": "PRST Portal",
        "rating": 4.5,
        "sentimental_score": 0.9117647058823528,
        "comment": "Fair enough ",
        "user_id": 4,
        "api_id": null,
        "prompt_version_id": 9,
        "prompt_id": 47,
        "task_id": "ca3fe556-0de4-4e84-b2af-8edbf5569020",
        "user": {
            "created_at": "2023-05-21T20:04:42.333Z",
            "updated_at": "2023-06-17T12:18:02.623Z",
            "id": 4,
            "email": "somebody@prst.ai",
            "status_id": 1,
            "last_login": "2023-06-17T12:18:02.609Z",
            "setting_id": 1,
        },
        "api": null,
        "task": {
            "created_at": "2023-06-16T19:58:38.501Z",
            "updated_at": "2023-06-16T19:58:41.323Z",
            "id": "ca3fe556-0de4-4e84-b2af-8edbf5569020",
            "start": "2023-06-16T19:58:38.495Z",
            "end": "2023-06-16T19:58:41.322Z",
            "result": {
                "result": "Live in luxury at Shipyard! Enjoy a refreshing swim in the pool, cozy up by the fireplace, and park your car in the garage. Conveniently located in a prime location.",
                "output": {
                    "external_id": "chatcmpl-7S9sd5YxPLyulfWIwzOBNOXD99O8D",
                    "actual_model": "gpt-3.5-turbo-0301",
                    "total_tokens": 79
                },
                "original": {
                    "id": "chatcmpl-7S9sd5YxPLyulfWIwzOBNOXD99O8D",
                    "object": "chat.completion",
                    "created": 1686945519,
                    "model": "gpt-3.5-turbo-0301",
                    "usage": {
                        "prompt_tokens": 42,
                        "completion_tokens": 37,
                        "total_tokens": 79
                    },
                    "choices": [{
                        "message": {
                            "role": "assistant",
                            "content": "Live in luxury at Shipyard! Enjoy a refreshing swim in the pool, cozy up by the fireplace, and park your car in the garage. Conveniently located in a prime location."
                        },
                        "finish_reason": "stop",
                        "index": 0
                    }]
                }
            },
            "error": null,
            "status_id": 2,
            "user_id": 4,
            "api_id": null,
            "prompt_version_id": 9,
            "connector_version_id": 21,
            "pipeline_id": null
        },
        "prompt": {
            "created_at": "2023-06-06T14:28:24.141Z",
            "updated_at": "2023-06-09T11:19:38.373Z",
            "id": 47,
            "name": "Awesome Prompt",
            "status_id": 2,
            "api_id": null,
            "latest": null,
            "lts": "v1"
        },
        "promptVersion": {
            "created_at": "2023-06-06T14:28:24.154Z",
            "updated_at": "2023-06-13T00:01:34.987Z",
            "id": 9,
            "v": "v1",
            "text": "Write an FacebookAd description <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-263\" key=\"263\" data-before=\"200\" style=\"content: &quot;World&quot;; width: 48px;\">200</span> character length maximum for rental building <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-264\" key=\"264\" data-before=\"building\" style=\"content: &quot;World&quot;; width: 102px;\">building</span> with these amenities <span class=\"param-hider\" aria-hidden=\"true\" id=\"p-265\" key=\"265\" data-before=\"amenities\" style=\"content: &quot;World&quot;; width: 113px;\">amenities</span> located in location. Do not use weblinks.",
            "execution_config": {},
            "prompt_id": 47,
            "setting_id": null,
            "status_id": 1,
            "api_id": null,
            "default_connector_id": 34,
            "default_connector_version_id": 21,
            "prompt": {
                "created_at": "2023-06-06T14:28:24.141Z",
                "updated_at": "2023-06-09T11:19:38.373Z",
                "id": 47,
                "name": "Awesome Prompt",
                "status_id": 2,
                "api_id": null,
                "latest": null,
                "lts": "v1"
            }
        }
    }],
    "pagination": {
        "page": 1,
        "pageSize": 10,
        "total": 2,
        "pages": 1
    }
}