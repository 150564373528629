var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"connector-item mx-auto",on:{"click":(e) => _vm.$emit('click', _vm.value, e)}},[_c('v-card-title',{staticClass:"connector-title primary-gradient"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.value.name))]),_c('div',{staticClass:"item-status",style:({
        backgroundColor:
          _vm.value.status.code === 'DRAFT'
            ? 'red'
            : _vm.value.status.code === 'ACTIVE'
            ? 'purple'
            : 'grey',
      })},[_c('span',[_vm._v(_vm._s(_vm.value.status.name))])])]),_c('v-card-text',{staticClass:"font-weight-bold pa-4 connector-text-preview"},[(
        _vm.value.latest &&
        _vm.value.latest.config &&
        _vm.value.latest.config.execute &&
        _vm.value.latest.config.execute.url
      )?_c('p',[_vm._v(" "+_vm._s(_vm.value.latest.config.execute.url)+" ")]):_vm._e()]),_c('v-card-actions',{staticClass:"card-actions-bottom"},[_c('v-list-item',{staticClass:"grow"},[_c('v-list-item-content'),_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('onRemove', _vm.value)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(" Clicking on this button will permanently delete the selected item from the system. Please note that this action cannot be undone, so proceed with caution. Consider making the item inactive or archived if you prefer a reversible action.")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }