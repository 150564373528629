<template>
  <v-app class="main-layout">
    <Navigation :user="user" @onSupport="supportDialog = true"></Navigation>

    <!-- <Header></Header> -->

    <v-main id="main-content" class="main-content main-background">
      <!-- Provides the application the proper gutter -->
      <!-- TODO: get back root validation -->
      <!-- v-if="user.isRoot" -->

      <v-slide-y-transition>
        <v-alert outlined type="warning" v-if="false" prominent border="left">
          We strongly advise
          <strong>against using the root user for regular operations</strong>.
          The root user should only be utilized for initial system setup,
          creating other user accounts, and managing system configurations.
        </v-alert>
      </v-slide-y-transition>

      <v-container fluid class="main-container">
        <!-- If using vue-router -->

        <v-slide-y-transition leave-absolute>
          <router-view></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <NewSupportTicketDialog
      :value="supportDialog"
      @onClose="supportDialog = false"
      @onCreate="onSupportTicketCreate"
    ></NewSupportTicketDialog>

    <!-- <Footer></Footer> -->
  </v-app>
</template>


<script>
import { mapState } from "vuex";
import { SupportAPIInstance } from "../components/api";
// import Header from "../components/wad/molecules/layout/Header.vue";
// import Footer from "../components/wad/molecules/layout/Footer.vue";
import Navigation from "../components/wad/molecules/layout/Navigation.vue";
import NewSupportTicketDialog from "./SupportTickets/components/NewSupportTicketDialog.vue";

export default {
  data: () => ({
    dark: true,
    signInCompleted: false,

    selectedPortal: null,
    showSplash: false,
    supportDialog: false,
  }),
  computed: {
    ...mapState("MainStore", ["user"]),
  },
  mounted() {
    const mainContent = document.getElementById("main-content");
    if (mainContent)
      mainContent.addEventListener("scroll", (e) => {

        var elements = document.querySelectorAll(".page-toolbar"); // Replace 'your-class' with your desired class name
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];

          if (e.target.scrollTop > 20) element.classList.add("minimized");
          else element.classList.remove("minimized");

          // Update the width of each element based on the scroll position or any other desired logic
        }
      });
  },
  components: {
    // Header,
    // Footer,
    Navigation,
    NewSupportTicketDialog,
  },
  methods: {
    async onSupportTicketCreate(ticket) {
      await SupportAPIInstance.create(ticket, {
        toast: {
          message: "Support Ticket Created, We will contact you soon.",
        },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.main-layout {
  height: 100%;

  .main-content {
    // max-width: calc(100% - 256px);
    // max-width: calc(100% - 56px);
    // background: linear-gradient(to right, #EBBF00, #686B6B);

    width: 100%;
    // height: calc(100% - 90px);
    // height: calc(100% - 64px);
    height: 100%;

    overflow: hidden;
    overflow-y: auto;

    .main-container {
      max-width: 100%;
      height: calc(100% - 60px);
      // padding: 5%;
      // height: 100%;
      // overflow-y: scroll;
    }
  }
}
</style>