
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" @click="onCreate" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Click to Create a New Support Ticket.</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for prompts by name'"
      ></HelpFormInput>
      <v-icon color="white">mdi-magnify</v-icon>
    </v-toolbar>

    <v-card-text class="page-content">
      <v-slide-y-transition leave-absolute>
        <v-slide-y-transition
          v-if="items && items.length"
          class="py-0 row"
          group
          tag="div"
        >
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            v-for="item in items"
            :key="item.id"
          >
            <SupportTicketItem
              :value="item"
              @onRemove="
                () => {
                  removeCandidate = item;
                  removeDialog = true;
                }
              "
            ></SupportTicketItem>
          </v-col>
        </v-slide-y-transition>
        <v-alert
          v-else
          icon="mdi-shield-lock-outline"
          prominent
          text
          type="info"
        >
          Currently, there are no Support Tickets, what is good we believe!
        </v-alert>
      </v-slide-y-transition>
      <div class="text-center mt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="getList"
        ></v-pagination>
      </div>
    </v-card-text>
    <NewSupportTicketDialog
      :value="supportDialog"
      @onClose="supportDialog = false"
      @onCreate="onSupportTicketCreate"
    ></NewSupportTicketDialog>
  </v-card>
</template>
        
<script>
import {
  SupportAPIInstance,
} from "../../components/api";
import SupportTicketItem from "./components/SupportTicketItem.vue";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import NewSupportTicketDialog from "./components/NewSupportTicketDialog.vue";

export default {
  data() {
    return {
      api: SupportAPIInstance,
      pagination: {
        page: 1,
        pageSize: 9,
        total: 0,
        pages: 0,
      },
      filter: {},
      items: [],
      supportDialog: false,
    };
  },
  components: {
    SupportTicketItem,
    HelpFormInput,
    NewSupportTicketDialog,
  },
  async created() {
    this.getList();
  },
  methods: {
    async onSupportTicketCreate(ticket) {
      await this.api.create(ticket, {
        toast: {
          message: "Support Ticket Created, We will contact you soon.",
        },
      });
      this.getList();
    },
    async getList() {
      const { items, pagination } = await this.api.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      this.supportDialog = true;
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "APICredentials Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
  },
};
</script>