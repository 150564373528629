<template>
  <v-card
    class="connector-item mx-auto"
    @click="(e) => $emit('click', value, e)"
  >
    <v-card-title class="connector-title primary-gradient">
      <span class="text-h6 font-weight-light">{{ value.name }}</span>
      <div
        class="item-status"
        :style="{
          backgroundColor:
            value.status.code === 'DRAFT'
              ? 'red'
              : value.status.code === 'ACTIVE'
              ? 'purple'
              : 'grey',
        }"
      >
        <span>{{ value.status.name }}</span>
      </div>
    </v-card-title>

    <v-card-text class="font-weight-bold pa-4 connector-text-preview">
      <p
        v-if="
          value.latest &&
          value.latest.config &&
          value.latest.config.execute &&
          value.latest.config.execute.url
        "
      >
        {{ value.latest.config.execute.url }}
      </p>
    </v-card-text>

    <v-card-actions class="card-actions-bottom">
      <v-list-item class="grow">
        <!-- <v-list-item-avatar color="grey darken-3">
              <v-img
                class="elevation-6"
                alt=""
                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
              ></v-img>
            </v-list-item-avatar> -->

        <v-list-item-content>
          <!-- <v-list-item-title>Evan You</v-list-item-title> -->
        </v-list-item-content>

        <v-row align="center" justify="end">
          <!-- <v-icon class="mr-1"> mdi-heart </v-icon> -->
          <!-- <span class="subheading mr-2">256</span> -->
          <!-- <span class="mr-1">·</span> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                @click.prevent.stop="$emit('onRemove', value)"
                icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>
              Clicking on this button will permanently delete the selected item
              from the system. Please note that this action cannot be undone, so
              proceed with caution. Consider making the item inactive or
              archived if you prefer a reversible action.</span
            >
          </v-tooltip>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>
    
    
    <script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      //
    };
  },

  methods: {},
};
</script>
  
  
  <style lang="scss" scoped>
.connector-item {
  min-height: 300px;

  .connector-text-preview {
    max-height: 148px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .connector-title {
    position: relative;
    overflow: hidden;
    .item-status {
      position: absolute;
      top: -10px;
      right: -60px;
      transform: rotate(45deg);
      width: 150px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      height: 62px;
      text-transform: capitalize;
      align-items: end;
      vertical-align: bottom;
    }
  }
}
</style>