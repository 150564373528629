<template>
  <v-tooltip v-bind="tooltipAttrs">
    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
      <v-menu content-class="selector-menu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-if="value"
            v-bind="{
              ...menuAttrs,
              ...attrs,
            }"
            v-on="{
              ...menuOn,
              ...on,
            }"
            @click="$emit('click', $event)"
            class="ma-2"
            :color="prompt.latest.v === value.v ? 'primary' : 'grey'"
            label
            text-color="white"
          >
            {{ prompt.latest.v === value.v ? "LTS: " : "" }}
            &nbsp; <strong>{{ value.v }} </strong>
          </v-chip>
        </template>
        <v-list>
          <v-list-item v-if="allowCreate" @click="$emit('onCreate')">
            <v-list-item-icon>
              <v-btn icon color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Path Version</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in pItems"
            :key="index"
            two-line
            @click="$emit('input', item)"
            :value="item.v === value.v"
          >
            <v-list-item-icon>
              <v-btn icon color="primary">
                <v-icon>mdi-label</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.v }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.created_at }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>{{ helpText }}</span>
  </v-tooltip>
</template>
          
          
  <script>
export default {
  props: {
    value: {},
    prompt: {
      type: Object,
      default: () => ({}),
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 400,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pItems: this.items,
    };
  },
  mounted() {},
  methods: {},
  watch: {
    items: {
      handler: function (val) {
        this.pItems = val;
      },
      deep: true,
    },
  },
};
</script>
          
          
  <style lang="scss" scoped>
</style>