<template>
  <v-card
    class="params-card pa-2 ma-0 transparent elevation-0"
    :class="{
      disabled: value.skip,
    }"
  >
    <v-card-text class="params-card-text">
      <v-tabs vertical class="param-tabs">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-bind="attrs" v-on="on" class="d-flex justify-start mt-4">
              <v-badge
                dot
                :value="
                  !!(
                    value.headers &&
                    value.headers.markup &&
                    value.headers.markup !== '{}'
                  )
                "
                color="primary"
                :content="
                  value.headers &&
                  value.headers.markup &&
                  value.headers.markup !== '{}'
                "
              >
                <v-icon left> mdi-format-list-group </v-icon>
                Headers
              </v-badge>
            </v-tab>
          </template>
          <span>
            Configure custom headers to be included in the API request. Specify
            the header name and its corresponding value to send additional
            information to the API server.
          </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-bind="attrs" v-on="on" class="d-flex justify-start">
              <v-badge
                dot
                :value="
                  !!(
                    value.params &&
                    value.params.markup &&
                    value.params.markup !== '{}'
                  )
                "
                color="primary"
                :content="
                  value.params &&
                  value.params.markup &&
                  value.params.markup !== '{}'
                "
              >
                <v-icon left> mdi-progress-question </v-icon>
                Query
              </v-badge>
            </v-tab>
          </template>
          <span>
            Define query parameters to be appended to the API URL. Specify the
            parameter name and its value to include extra data in the API
            request for filtering or pagination purposes.
          </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              :disabled="value.method === 'get' || value.method === 'delete'"
              v-bind="attrs"
              v-on="on"
              class="d-flex justify-start"
            >
              <v-badge
                dot
                :value="
                  !!(
                    value.body &&
                    value.body.markup &&
                    value.body.markup !== '{}'
                  )
                "
                color="primary"
                :content="
                  value.body && value.body.markup && value.body.markup !== '{}'
                "
              >
                <v-icon left> mdi-code-json </v-icon>
                Body
              </v-badge>
            </v-tab>
          </template>
          <span>
            Provide the request body content in JSON format. Define properties
            using dot notation (e.g., 'parent.nested') to structure the data.
            This payload will be sent along with the API request.
          </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-bind="attrs" v-on="on" class="d-flex justify-start">
              <v-badge
                dot
                :value="
                  !!(
                    value.response &&
                    value.response.markup &&
                    value.response.markup !== '{}'
                  )
                "
                color="primary"
                :content="
                  value.response &&
                  value.response.markup &&
                  value.response.markup !== '{}'
                "
              >
                <v-icon left> mdi-export </v-icon>
                Response
              </v-badge>
            </v-tab>
          </template>
          <span>
            Specify the desired data to be extracted from the API response. Use
            dot notation (e.g., 'parent.nested') to identify the properties or
            fields that should be retained and returned as part of the response
            data."
          </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab v-bind="attrs" v-on="on" class="d-flex justify-start">
              <v-icon left> mdi-eye </v-icon>
              <span class="ml-2"> Preview </span>
            </v-tab>
          </template>
          <span>
            Specify the desired data to be extracted from the API response. Use
            dot notation (e.g., 'parent.nested') to identify the properties or
            fields that should be retained and returned as part of the response
            data."
          </span>
        </v-tooltip>

        <v-tab-item class="px-2">
          <RequestJSONParamsEditor
            v-model="value.headers"
            @input="$emit('input', value)"
            :availableParams="availableParams"
          >
          </RequestJSONParamsEditor>
        </v-tab-item>
        <v-tab-item class="px-2">
          <RequestJSONParamsEditor
            v-model="value.params"
            @input="$emit('input', value)"
            :availableParams="availableParams"
          >
          </RequestJSONParamsEditor>
        </v-tab-item>
        <v-tab-item class="px-2">
          <RequestJSONParamsEditor
            v-model="value.body"
            @input="$emit('input', value)"
            :availableParams="availableParams"
          ></RequestJSONParamsEditor>
        </v-tab-item>
        <v-tab-item class="px-2">
          <RequestJSONParamsEditor
            v-model="value.response"
            @input="$emit('input', value)"
            :availableParams="availableParams"
            :allow-create="true"
          >
          </RequestJSONParamsEditor>
        </v-tab-item>
        <v-tab-item class="px-2">
          <ResponsePreview :value="value.response"></ResponsePreview>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import RequestJSONParamsEditor from "./RequestJSONParamsEditor.vue";
import ResponsePreview from "./ResponsePreview.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    availableParams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newHeaderItem: null,
      newBodyItem: null,
      responsePreview: false,
      methods: [
        {
          value: "post",
          name: "POST",
        },
        {
          value: "get",
          name: "GET",
        },
        {
          value: "put",
          name: "PUT",
        },
        {
          value: "delete",
          name: "DELETE",
        },
      ],

      urlRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "URL should be a valid link",
      ],
    };
  },
  components: {
    RequestJSONParamsEditor,
    ResponsePreview,
  },
  computed: {
    completedTasks() {
      return this.credentials.filter((credential) => credential.done).length;
    },
    progress() {
      return (this.completedTasks / this.credentials.length) * 100;
    },
    remainingTasks() {
      return this.credentials.length - this.completedTasks;
    },
  },

  methods: {
    onHeaderCreate() {
      if (!this.value.headers) this.value.headers = {};

      this.value.headers[this.newHeaderItem] = "";
      this.newHeaderItem = null;
      this.$emit("input", this.value);
    },
    onBodyCreate() {
      if (!this.value.body) this.value.body = {};
      this.value.body[this.newBodyItem] = "";
      this.newBodyItem = null;
      this.$emit("input", this.value);
    },
    toConfig(value) {
      try {
        const parsed = JSON.parse(value);
        if (!parsed) throw new Error("empty value");
        return parsed;
      } catch (error) {
        // return defaults
        return {
          method: "post",
          url: "",
          headers: {
            Authentication: "Bearer ",
          },
        };
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.test-card {
  &::after {
    transition: all 0.3s;
    z-index: -2;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
  }

  &.disabled {
    &::after {
      transition: all 0.3s;
      z-index: 2;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
.params-card {
  // min-height: 584px;
  border-radius: 4px;
  height: calc(100% - 80px);

  .params-card-text {
    height: 100%;

    .param-tabs {
      height: 100%;
      .param-tab {
      }
    }
  }

  &::after {
    border-radius: 4px;
    transition: all 0.3s;
    z-index: -2;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
  }
  &.disabled {
    &::after {
      transition: all 0.3s;
      z-index: 2;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
</style>