var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"prompt-terminal elevation-0 transparent"},[_c('v-card-text',{ref:"scrollContainer",staticClass:"terminal-content"},[_c('v-list',{attrs:{"three-line":""}},[_c('v-slide-y-transition',{staticClass:"py-0 px-4",attrs:{"group":"","tag":"div"}},[(_vm.logs && _vm.logs.length)?_vm._l((_vm.logs),function(record){return _c('v-list-item',{key:record.id,on:{"click":() => {
                _vm.dialog = true;
                _vm.activeRecord = record;
              }}},[_c('div',{staticClass:"indicator",style:({
                '--color': _vm.generateColorFromUUID(record.task_id),
              })}),_c('div',{staticClass:"status-small status"},[(
                  record.message === 'PROMPT_TASK_AUTH_COMPLETED' ||
                  record.message === 'PROMPT_TASK_COMPLETED'
                )?_c('div',{staticClass:"o-circle c-container__circle o-circle__sign--success"},[_c('div',{staticClass:"o-circle__sign"})]):(record.message === 'PROMPT_TASK_FAILED')?_c('div',{staticClass:"o-circle c-container__circle o-circle__sign--failure"},[_c('div',{staticClass:"o-circle__sign"})]):_vm._e()]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(record.displayHtml)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatTime(record.date)))])],1)],1)}):_c('v-alert',{key:"empty-alert",attrs:{"prominent":"","text":"","type":"info"}},[_vm._v(" No recent logs found for this connector. ")])],2),_c('div',{attrs:{"id":"bottom-item"}})],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{},[_vm._v(" Details ")]),_c('v-card-text',{staticClass:"json-container"},[(_vm.activeRecord)?_c('pre',{staticClass:"json-container-pre",domProps:{"innerHTML":_vm._s(_vm.prettifyJson(_vm.activeRecord.payload))}}):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" I accept ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }