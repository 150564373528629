import {
    API
} from '../Api.class';
import {
    AuthHelper
} from '../../helpers/auth.helper';

export class LogsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }

    async recent(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/logs/recent', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }


    async listen(cursor, params = {}) {

        const logsURL = 'api/v1/logs/';



        this.token = await AuthHelper.getToken();

        const queryParams = {
            token: this.token,
            payload: JSON.stringify(params)
        };

        if (cursor)
            queryParams.cursor = cursor;

        const queryString = new URLSearchParams(queryParams).toString();
        const eventSourceUrl = `${this.baseURL}/${logsURL}?${queryString}`;

        return new EventSource(eventSourceUrl);
    }
}