<template>
  <v-navigation-drawer app v-model="drawer" class="application-nav bg-black">
    <v-img
      :src="'/logo.png'"
      :lazy-src="'/logo.png'"
      :size="80"
      class="logo"
      contain
    ></v-img>
    {{ version }}

    <!-- <v-list class="first-list">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>Pollyspot</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </v-list> -->

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        color="primary"
        @click="redirectTo(item.url)"
      >
        <v-list-item-icon>
          <v-icon :color="isActive(item) ? 'primary' : 'grey'">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <v-spacer></v-spacer>
    <v-list nav dense>
      <v-list-item
        v-for="item in common"
        :key="item.title"
        link
        color="primary"
        @click="redirectTo(item.url)"
      >
        <v-list-item-icon>
          <v-icon :color="isActive(item) ? 'primary' : 'grey'">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- <v-divider></v-divider> -->

    <v-spacer></v-spacer>

    <template v-slot:append>
      <div class="pa-4 pb-0">
        <v-btn
          :color="user.ableToContactSupport ? 'primary' : ''"
          :disabled="!user.ableToContactSupport"
          block
          @click="$emit('onSupport')"
        >
          Support
        </v-btn>
      </div>
      <div class="pa-4">
        <v-btn block @click="() => $router.push('/sign-in')"> Logout </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>


<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({
        settings: {},
      }),
    },
  },
  data() {
    return {
      drawer: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          url: "/dashboard",
          check: /dashboard/gi,
        },
        {
          title: "Prompts",
          icon: "mdi-text-recognition",
          url: "/prompts",
          check: /prompts/gi,
        },
        // {
        //   title: "Prompts Library",
        //   icon: "mdi-poll",
        //   url: "/library",
        //   check: /library/gi,
        // },

        // {
        //   title: "Experiments",
        //   icon: "mdi-flask-outline",
        //   url: "/experiments",
        //   check: /experiments/gi,
        // },

        {
          title: "Executions",
          icon: "mdi-console",
          url: "/executions",
          check: /executions/gi,
        },
        {
          title: "Feedbacks",
          icon: "mdi-comment-quote-outline",
          url: "/feedbacks",
          check: /feedbacks/gi,
        },
        {
          title: "Knowledgebase",
          icon: "mdi-file-document-multiple-outline",
          url: "/knowledgebase",
          check: /knowledgebase/gi,
        },
        {
          title: "Connectors",
          icon: "mdi-api",
          url: "/connectors",
          check: /connectors/gi,
        },
        {
          title: "Pricing Rules",
          icon: "mdi-currency-usd",
          url: "/pricing-models",
          check: /pricing-models/gi,
        },
        // {
        //   title: "Hooks & Listeners",
        //   icon: "mdi-webhook",
        //   url: "/hooks",
        //   check: /hooks/gi,
        // },
      ],

      common: [
        // {
        //   title: "Users & Permissions",
        //   icon: "mdi-account-group",
        //   url: "/users",
        //   check: /users/gi,
        // },
        {
          title: "API Credentials",
          icon: "mdi-key-link",
          url: "/api-credentials",
          check: /api-credentials/gi,
        },
        {
          title: "Support Requests",
          icon: "mdi-face-agent",
          url: "/support-requests",
          check: /support-requests/gi,
        },
        // {
        //   title: "Store",
        //   icon: "mdi-cart",
        //   url: "/store",
        //   check: /store/gi,
        // },
      ],
      rail: true,
      mini: false,
    };
  },
  computed: {
    version() {
      console.log("process.env.npm_package_version: ", process.env);
      return process.env.npm_package_version;
    },
  },
  methods: {
    redirectTo(url) {
      this.$router.push(url);
    },
    isActive(item) {
      // return item && false;
      return String(this.$route.path).match(item.check);
    },
  },
};
</script>


<style lang="scss" scoped>
.application-nav {
  // margin-top: 64px;

  @media (max-width: 864px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 40px;
  }

  .first-list {
  }
  max-width: 300px;
}

.logo {
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>