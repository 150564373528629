<template>
  <div class="connector-menu">
    <v-tooltip v-bind="tooltipAttrs">
      <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
        <v-row v-bind="$attrs" class="connector-menu-display-col">
          <v-col class="connector-col">
            <v-menu content-class="selector-menu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="connector-chip"
                  v-bind="{
                    ...menuAttrs,
                    ...attrs,
                  }"
                  v-on="{
                    ...menuOn,
                    ...on,
                  }"
                  @click="$emit('click', $event)"
                  text-color="white"
                >
                  <v-icon left> mdi-label </v-icon>
                  {{ connector ? connector.name : "Select Connector" }}
                </v-chip>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in pConnectors"
                  :key="item.id"
                  two-line
                  @click="$emit('onConnectorChanged', item)"
                  class="mb-2"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="mt-2">
                      <v-chip
                        small
                        :color="
                          item.status.code === 'DRAFT'
                            ? 'pink'
                            : item.status.code === 'ACTIVE'
                            ? 'primary'
                            : 'grey'
                        "
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-label </v-icon>
                        {{ item.status.name }}
                      </v-chip>

                      {{ formatDate(item.created_at) }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-slide-x-reverse-transition>
            <v-col class="version-col" v-if="connector && connector.id">
              <v-menu content-class="selector-menu" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="version-chip"
                    v-bind="{
                      ...menuAttrs,
                      ...attrs,
                    }"
                    v-on="{
                      ...menuOn,
                      ...on,
                    }"
                    text-color="white"
                  >
                    <v-icon left> mdi-label </v-icon>
                    {{ version ? version.v : `LTS (${connector.lts})` }}
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in connector.versions"
                    :key="index"
                    two-line
                    @click="$emit('onConnectorVersionChanged', item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.v }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        formatDate(item.created_at)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-slide-x-reverse-transition>
        </v-row>
      </template>
      <span>{{ helpText }}</span>
    </v-tooltip>
  </div>
</template>
          
          
  <script>
import { ConnectorAPIInstance } from "../../../../api";
import { FormatterHelper } from "../../../../helpers/formatter.helper";
import { MOCKED_CONNECTORS } from "../../../../../constants/mocked.constants";

export default {
  props: {
    connector: {},
    version: {},
    mocked: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 400,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: ConnectorAPIInstance,
      pTimeout: undefined,
      pConnectors: this.items,
      formatDate: FormatterHelper.date,

      // Autoload
      pagination: {
        page: 1,
        pageSize: 50,
      },
    };
  },
  mounted() {
    if (!this.mocked) this.getConnectorsList();
    else this.pConnectors = MOCKED_CONNECTORS.items;
  },
  methods: {
    async getConnectorsList() {
      const { pagination, items } = await this.api.list(this.pagination);

      this.pagination = pagination;
      this.pConnectors = items;
    },
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
  },
  watch: {
    connector: {
      handler(newVal) {
        if (newVal && newVal.versions)
          this.$emit("onConnectorVersionChanged", newVal.versions[0]);
      },
      deep: true,
    },
  },
};
</script>
          
          
  <style lang="scss" scoped>
.connector-menu {
  padding: 16px;
  .connector-menu-display-col {
    display: inline-flex;
    flex-direction: row;
    width: fit-content;
    flex: initial;
    padding-left: 4px;
    padding-right: 4px;
    .connector-col {
      max-width: 220px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 fit-content;
      max-width: 100%;
      padding: 0px;

      .connector-chip {
        border-radius: 8px 0px 0px 8px;
        background-color: #00897b;
        i {
          color: #fff;
        }
      }
    }
    .version-col {
      display: flex;
      max-width: 220px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 fit-content;
      max-width: 100%;
      padding: 0px;
      .version-chip {
        border-radius: 0px 8px 8px 0px;
        background-color: #109c1d;
        border-left: 1px solid;
        i {
          color: #fff;
        }
      }
    }
  }
}
</style>