import moment from 'moment';

export class FormatterHelper {



    static price(format, currency = 'USD') {
        return new Intl.NumberFormat('en-IN', {
            maximumFractionDigits: 16,
            style: 'currency',
            currency
        }).format(format)
    }


    static priceCustom(format, n) {
        return Number(format).toFixed(n)
    }


    static date(string) {
        return moment(string).format('YYYY MMM Qo')
    }


    static dateWithTime(string) {
        return moment(string).format('MMM Qo, hh:mm:ss.SSS (a)')
    }


    static last8(str) {
        return `...${str.slice(-8)}`;
    }

    /**
     * 
     * @param {number} number 
     * @returns 
     */
    static timeDuration(number, measure = 's') {
        const pSeconds = Number(number);
        let divider = 1;
        if (measure === 's')
            divider = 1;

        if (measure === 'm')
            divider = 60;

        if (measure === 'h')
            divider = 3600;

        const d = Math.floor(pSeconds / ((3600 / divider) * 24));
        const h = Math.floor(pSeconds % ((3600 / divider) * 24) / (3600 / divider));
        const m = Math.floor(pSeconds % ((3600 / divider) / (60 / divider)));
        const s = Math.floor(pSeconds % (60 / divider));

        const dDisplay = d > 0 ? d + (d == 1 ? " d, " : " d, ") : "";
        const hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
        const sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";


        return dDisplay + hDisplay + mDisplay + sDisplay;
    }


    static cutString(string, numberOfChars = 100, direction = 'start') {
        if (!string)
            return '-';

        if (direction === 'start')

            return String(string).length < numberOfChars ? String(string) : String(string).slice(0, numberOfChars - 3) + '...';

        else
            return String(string).length < numberOfChars ? String(string) : '...' + String(string).slice(String(string).length - numberOfChars + 3, String(string).length);

    }


    static beautifyJSON(value) {
        if (typeof value === "string") {
            let parsingString = value;

            // TODO FIX when time is come

            const used = [];

            const parser = new DOMParser();
            const parsedDocument = parser.parseFromString(
                value,
                "text/html"
            );
            const spans = parsedDocument.querySelectorAll(
                "span.request-param-hider"
            );

            spans.forEach((span) => {
                const randKey = Math.random();

                used.push({
                    key: randKey,
                    span,
                });

                parsingString = parsingString.replace(
                    span.outerHTML,
                    randKey
                    // JSON.stringify(span.getAttribute("id")).replace(/"/gi, '\\"')
                );
            });

            // Add double quotes to property names
            const jsonString = parsingString.replace(
                /([{,]\s*)(\w+)(\s*:)/g,
                '$1"$2"$3'
            );

            // Replace single quotes with double quotes for string values
            const jsonLikeString = jsonString.replace(
                /'([^']+)'(:\s*[^',}\]]+)/g,
                '"$1"$2'
            );

            const parsed = JSON.parse(jsonLikeString);

            let prettified = JSON.stringify(parsed, null, "\t");

            used.forEach((item) => {
                prettified = prettified.replace(item.key, item.span.outerHTML);
            });

            return prettified;
        }
    }
}