
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" @click="onCreate" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>
          Create a New Connector: Simply click on this button to initiate the
          process of generating a fresh connector. This allows you to start a
          new writing session or explore different ideas with ease.</span
        >
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for Connectors by name'"
      ></HelpFormInput>
      <v-icon color="white">mdi-magnify</v-icon>
    </v-toolbar>

    <v-card-text class="page-content">
      <v-slide-y-transition leave-absolute>
        <v-slide-y-transition
          v-if="items && items.length"
          class="py-0 row"
          group
          tag="div"
        >
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            v-for="item in items"
            :key="item.id"
          >
            <ConnectorItem
              :value="item"
              @click="openDetails"
              @onRemove="
                () => {
                  removeCandidate = item;
                  removeDialog = true;
                }
              "
            ></ConnectorItem>
          </v-col>
        </v-slide-y-transition>
        <v-alert
          v-else
          icon="mdi-shield-lock-outline"
          prominent
          text
          type="info"
        >
          Currently, there are no connectors available. Please consider creating
          new connectors to get started. If you believe this is an error, kindly
          contact our support team for further assistance.
        </v-alert>
      </v-slide-y-transition>
      <div class="text-center mt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="getList"
        ></v-pagination>
      </div>
    </v-card-text>
    <ConnectorDeleteDialog
      :value="removeDialog"
      @onCancel="
        () => {
          removeCandidate = undefined;
          removeDialog = false;
        }
      "
      @onRemove="onRemove"
    ></ConnectorDeleteDialog>
  </v-card>
</template>
      
    <script>
import { ConnectorAPIInstance } from "../../components/api";
import ConnectorItem from "./components/ConnectorItem.vue";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import ConnectorDeleteDialog from "./components/ConnectorDeleteDialog.vue";

export default {
  data() {
    return {
      api: ConnectorAPIInstance,
      pagination: {
        page: 1,
        pageSize: 10,
      },
      filter: {},
      items: [],
      removeDialog: false,
      removeCandidate: undefined,
    };
  },
  components: {
    ConnectorItem,
    ConnectorDeleteDialog,
    HelpFormInput,
  },
  async created() {
    this.getList();
  },
  methods: {
    openDetails(value) {
      this.$router.push(`/connectors/${value.id}`);
    },
    async getList() {
      const { items, pagination } = await this.api.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      await this.api.create(
        {
          name: "New Connector",
        },
        {
          toast: {
            message: "New Connector Created",
          },
        }
      );
      this.getList();
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Connector Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
  },
};
</script>