var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function({ on: menuOn, attrs: menuAttrs }){return [_c('v-menu',{attrs:{"content-class":"selector-menu","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.value)?_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.value.code === 'DRAFT'
              ? 'pink'
              : _vm.value.code === 'ACTIVE'
              ? 'primary'
              : 'grey',"label":"","text-color":"white"},on:{"click":function($event){return _vm.$emit('click', $event)}}},'v-chip',{
            ...menuAttrs,
            ...attrs,
          },false),{
            ...menuOn,
            ...on,
          }),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(_vm.value.name)+" ")],1):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((_vm.pItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"two-line":""},on:{"click":function($event){return _vm.$emit('input', item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.description))])],1)],1)}),1)],1)]}}])},'v-tooltip',_vm.tooltipAttrs,false),[_c('span',[_vm._v(_vm._s(_vm.helpText))])])
}
var staticRenderFns = []

export { render, staticRenderFns }