import {
    API
} from '../Api.class';
import {
    PromptParamsAPI
} from './PromptParams.api';


export class PromptVersionsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Params = new PromptParamsAPI(baseUrl);
    }


    async list(promptId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${promptId}/versions`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(promptId, id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${promptId}/versions/${id}`, {
            meta
        });

    }


    async create(promptId, body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/prompts/${promptId}/versions`, body, {
            meta
        });
    }

    async update(promptId, promptVersion, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/prompts/${promptId}/versions/${promptVersion.id}`, promptVersion, {
            meta
        });
    }


    async delete(promptId, id, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/prompts/${promptId}/versions/${id}`, {
            meta
        });
    }

}