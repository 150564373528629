

<template>
  <div
    class="sign-in-form-container"
  >
    <v-img class="logo" src="/logo.png"> </v-img>
    <v-fade-transition>
      <SignInFormVue
        v-if="!signInCompleted"
        @onSubmit="signIn"
        @onGoogle="signInGoogle"
        :dark="false"
        name="PRST.AI"
        class="pa-4 mt-2"
      ></SignInFormVue>
    </v-fade-transition>
  </div>
</template>



<script>
import SignInFormVue from "../components/wad/molecules/signIn/SignInForm.vue";

export default {
  data: () => ({
    dark: true,
    signInCompleted: false,

    selectedPortal: null,
    showSplash: false,
  }),
  mounted() {},
  components: {
    SignInFormVue,
  },
  methods: {
    async signIn(credentials) {
      credentials;
      try {
        await this.$store.dispatch("MainStore/logIn", credentials);
      } catch (error) {
        console.log(error)
        this.$router.push("/sign-in");
      }
    },

    async signInGoogle(credentials) {
      try {
        await this.$store.dispatch("MainStore/googleAuth", credentials);
      } catch (error) {
        console.log("Error: ", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.sign-in-form-container {
  // background: linear-gradient(to bottom, #000, transparent);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  max-width: 450px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  border: 2px solid #00695c;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background-size: cover;

  .logo {
    margin-top: 40px;
    width: 200px;
    height: 180px;
    margin: 0 auto;
    margin-top: 80px;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  @media (max-width: 864px) {
    width: 100%;
  }
}
</style>



