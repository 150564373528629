<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    @input="onInputChanged($event)"
    :label="label"
    @keyup.enter="$emit('onEnter')"
    @contextmenu="(e) => $emit('contextmenu', e)"
    color="primary"
    class="simple-input"
  >
    <template v-if="icon" v-slot:prepend>
      <v-icon color="primary"> {{ icon }}</v-icon>
    </template>
  </v-text-field>
</template>
  
  
  <script>
export default {
  props: {
    textarea: {
      default: false,
      type: Boolean,
    },
    html: {
      default: false,
      type: Boolean,
    },
    value: {},
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 0,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchTimeout: null,
    };
  },
  methods: {
    onInputChanged(event) {
      if (this.timeout && this.timeout !== 0) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.$emit("input", event);
        }, this.timeout);
      } else {
        this.$emit("input", event);
      }
    },
    proxyKeydown(e) {
      console.log("PROXY");

      this.$emit(
        "keydown",
        new KeyboardEvent("keydown", {
          keyCode: 40,
        }),
        e
      );
    },
  },
};
</script>
  
  
<style lang="scss">
.simple-input {
  font-size: inherit;
  border: none;

  .v-input__slot {
    &:before {
      border: none !important;
    }
  }
}
</style>