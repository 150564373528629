
<template>
  <v-card class="elevation-0 transparent page-card pricingRule-details">
    <v-card-title class="page-card-title">
      <!-- <v-btn
              icon
              class="mr-2"
              color="primary"
              @click="() => (newPollDialog = !newPollDialog)"
              ><v-icon>mdi-plus</v-icon>
            </v-btn> -->
      <h2 class="page-title">
        <SimpleInput
          :icon="'mdi-pencil'"
          hide-details
          v-model="pricingRule.name"
          @input="updatePricingModel"
        ></SimpleInput>
      </h2>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="pricingRule-setup-content">
      <!-- Pipeline -->
      <v-row class="pricingRule-setup-content-row">
        <v-col cols="12" class="pricingRule-setup-content-col">
          <ul class="pricing-model-header-container">
            <li
              class="stepper__item"
              :class="{
                complete: tab > 0,
                active: tab === 1,
              }"
              @click="tab = 1"
            >
              <!-- Input -->
              <!-- Full Details  -->
              <v-card class="pricing-model-header-card transparent elevation-0"
                ><v-card-title>
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 1,
                    }"
                    >Connector</span
                  >
                  <transition name="slide">
                    <v-alert
                      v-if="
                        pricingRule &&
                        pricingRule.id &&
                        !(
                          pricingRule.connectorVersion &&
                          pricingRule.connectorVersion.config &&
                          pricingRule.connectorVersion.config.execute &&
                          pricingRule.connectorVersion.config.execute
                            .response &&
                          pricingRule.connectorVersion.config.execute
                            .response !== '{}'
                        )
                      "
                      dense
                      border="left"
                      type="warning"
                      class="ma-0 pa-0 config-alert"
                    >
                      Please ensure that selected connector has defined
                      <strong>Execute API</strong>.
                    </v-alert>
                  </transition>
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text
                    class="pricing-model-config-content"
                    v-if="tab === 1"
                  >
                    <SelectorConnectors
                      autoload
                      return-object
                      v-model="connector"
                    ></SelectorConnectors>
                    <v-slide-y-transition>
                      <SelectorConnectorVersion
                        v-if="connector"
                        autoload
                        return-object
                        :connector-id="connector.id"
                        v-model="pricingRule.connectorVersion"
                        @input="updatePricingModel"
                      ></SelectorConnectorVersion>
                    </v-slide-y-transition>

                    <v-slide-y-transition>
                      <SelectorDefault
                        v-model="pricingRule.connector_config"
                        @input="setMarkup"
                        :help-text="'Please Select an API where to apply Pricing Rule'"
                        v-if="
                          pricingRule &&
                          pricingRule.connectorVersion &&
                          pricingRule.connectorVersion.config &&
                          pricingRule.connectorVersion.config.execute &&
                          pricingRule.connectorVersion.config.execute
                            .response &&
                          pricingRule.connectorVersion.config.execute
                            .response !== '{}'
                        "
                        :items="[
                          ...(pricingRule.connectorVersion.config.auth &&
                          !pricingRule.connectorVersion.config.auth.skip
                            ? [
                                {
                                  value: 'auth',
                                  name: 'Auth API Request',
                                  description:
                                    'Apply Pricing Rule for Execute API. <br> If you want to define different rules for Auth and Execute APIs please create 2 Pricing Rule',
                                },
                              ]
                            : []),
                          {
                            value: 'execute',
                            name: 'Execute API Request',
                            description:
                              'Apply Pricing Rule for Execute API. <br> If you want to define different rules for Auth and Execute APIs please create 2 Pricing Rule',
                          },
                        ]"
                      ></SelectorDefault>
                    </v-slide-y-transition>
                  </v-card-text>
                </transition>
                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 1"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn @click.prevent.stop="tab = 2" color="primary"
                        >Next
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
            <li
              class="stepper__item"
              :class="{
                complete: tab > 1,
                active: tab === 2,
              }"
              @click="tab = 2"
            >
              <!-- Full Details  -->
              <v-card class="pricing-model-header-card transparent elevation-0">
                <v-card-title class="pr-10">
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 2,
                    }"
                    >Configurations</span
                  >
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text
                    v-if="tab === 2"
                    class="pricing-model-config-content"
                  >
                    <RequestJSONParamsEditor
                      :availableParams="availableParams"
                      v-model="pricingRule.markup"
                      @input="updatePricingModel"
                    ></RequestJSONParamsEditor>
                  </v-card-text>
                </transition>

                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 2"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn @click.prevent.stop="tab = 3" color="primary"
                        >Next
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
            <li
              class="stepper__item"
              :class="{
                complete: tab > 2,
                active: tab === 3,
              }"
              @click="tab = 3"
            >
              <!-- Full Details  -->
              <v-card class="pricing-model-header-card transparent elevation-0"
                ><v-card-title class="pr-8">
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 3,
                    }"
                    >Pricing Settings
                  </span>

                  <v-spacer></v-spacer>

                  <transition name="slide">
                    <!-- <v-alert
                        v-if="!requiredParamsUsed && tab === 3"
                        dense
                        border="left"
                        type="warning"
                        class="ma-0 pa-0 config-alert"
                      >
                        Ensure <strong>PRST_PROMPT</strong> and other parameters
                        <br />
                        are assigned.
                      </v-alert> -->
                  </transition>
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text
                    v-if="tab === 3"
                    class="pricing-model-config-content pt-4"
                  >
                    <SelectorDefault
                      v-model="pricingRule.currency"
                      @input="updatePricingModel"
                      :help-text="'Please Select a Currency To Display'"
                      :items="[
                        {
                          value: 'usd',
                          name: 'USD',
                        },
                        {
                          value: 'eur',
                          name: 'EUR',
                        },
                        {
                          value: 'gpb',
                          name: 'GBP',
                        },
                        {
                          value: 'custom',
                          name: 'Custom',
                        },
                      ]"
                    ></SelectorDefault>
                    <v-row>
                      <v-col
                        class="justify-center d-flex flex-column align-center"
                      >
                        <NumberInput
                          v-model="pricingRule.price_per_unit"
                          @input="updatePricingModel"
                        ></NumberInput>
                        ({{ pricingRule.currency }})
                      </v-col>
                      <v-col class="justify-center d-flex align-center">
                        <span>Per</span>
                      </v-col>
                      <v-col
                        class="justify-center d-flex flex-column align-center"
                      >
                        <NumberInput
                          v-model="pricingRule.unit"
                          @input="updatePricingModel"
                        ></NumberInput>
                        (Tokens)
                      </v-col>
                    </v-row>
                  </v-card-text>
                </transition>
                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 3"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn @click="onDone" color="primary">Done </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
        
  <script>
import { PricingModelAPIInstance } from "../../components/api";
import SimpleInput from "../../components/wad/atoms/common/inputs/SimpleInput.vue";
import { mapState } from "vuex";
import SelectorConnectors from "../../components/wad/atoms/common/autocompletes/SelectorConnectors.vue";
import SelectorConnectorVersion from "../../components/wad/atoms/common/autocompletes/SelectorConnectorVersion.vue";
import SelectorDefault from "../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";
import RequestJSONParamsEditor from "../Connectors_v2/components/RequestJSONParamsEditor.vue";
import NumberInput from "../../components/wad/atoms/common/NumberInput.vue";

export default {
  data() {
    return {
      api: PricingModelAPIInstance,
      tab: 1,
      pricingRuleId: undefined,
      connector: undefined,
      pricingRule: {},
    };
  },
  components: {
    SimpleInput,
    SelectorConnectors,
    SelectorConnectorVersion,
    SelectorDefault,
    RequestJSONParamsEditor,
    NumberInput,
  },
  mounted() {},
  computed: {
    ...mapState("MainStore", ["user"]),

    availableParams() {
      return [
        {
          id: "TOKENS_VALUE",
          name: "TOKENS_VALUE",
          value: "Will be automatically parse by System",
          issuer: "system",
        },
      ];
    },
  },
  created() {
    const { id } = this.$route.params;

    if (!id) this.$router.push("/pricing-models");
    this.pricingRuleId = id;
    this.getFromApi();
  },
  methods: {
    setMarkup(val) {
      if (this.pricingRule.connectorVersion.config[val]) {
        const regex = /<span\b[^>]*>(.*?)<\/span>/gi;
        const clean = this.pricingRule.connectorVersion.config[
          val
        ].response.replace(regex, "$1");
        this.pricingRule.markup = clean;
      } else this.pricingRule.markup = null;
      this.updatePricingModel();
    },

    onDone() {
      this.$router.push("/pricing-models");
    },
    async getFromApi() {
      this.pricingRule = await this.api.get(this.pricingRuleId);

      this.connector =
        this.pricingRule.connectorVersion &&
        this.pricingRule.connectorVersion.connector
          ? this.pricingRule.connectorVersion.connector
          : undefined;
    },
    async updatePricingModel() {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);

      this.updateTimeout = setTimeout(async () => {
        await this.api.update(this.pricingRule, {
          // toast: {
          //   message: "Changes applied",
          // },
        });
      }, 500);
    },
  },
};
</script>
    
    
  <style lang="scss"  scoped>
.pricingRule-details {
  height: 100%;
  .pricingRule-setup-content {
    height: calc(100% - 20px);

    .pricingRule-setup-content-row {
      height: 100%;

      .pricingRule-setup-content-col {
        height: 100%;
      }
    }
  }
}

.pricing-model-header-container {
  height: 100%;
  display: flex;
  padding: 0;
  width: 100%;
  list-style: none;
  position: relative;
  margin: 0px;
  border-radius: 4px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
    width: calc(100% - 20px);
    background: #e7e7e7;
  }
  min-height: 70vh;

  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   content: "";
  //   width: calc(100% - 20px);
  //   background: #e7e7e7;
  // }
}

.stepper__item {
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.6s;
  overflow: hidden;
  margin: 0 0 0 -19px;
  min-width: 60px;
  @media (max-width: 600px) {
    min-width: 10%;
  }
  width: 10%;
  flex-grow: 1;
  background: repeating-linear-gradient(
    -65deg,
    #fff,
    #fff 20px,
    #fcfcfc 20px,
    #fcfcfc 40px
  );

  clip-path: polygon(
    0 0,
    calc(100% - 21px) 0,
    100% 35px,
    calc(100% - 21px) 70px,
    calc(100% - 21px) 100%,
    0 100%,
    0 70px,
    21px 35px
  );
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 21px) 0,
    100% 35px,
    calc(100% - 21px) 70px,
    calc(100% - 21px) 100%,
    0 100%,
    0 70px,
    21px 35px
  );

  .pricing-model-header-card {
    color: inherit;
    transition: all 0.1s;
    height: 100%;
    padding-right: 24px;
    padding-left: 14px;

    .tab-title {
      &.vertical {
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
    }

    .config-alert {
      position: absolute;
      right: 26px;
      top: 6px;
      width: 400px;
    }

    .pricing-model-config-content {
      overflow-y: auto;
      padding-left: 0px;
      padding-right: 12px;
      height: calc(100% - 110px);

      .pricingRule-http-config {
        // height: calc(100% - 160px);
      }
    }
  }

  &:before {
    border-radius: 4px;
    transition: all 0.6s linear;
    z-index: -1;
    content: "";
    background: linear-gradient(-65deg, #00897b, #109c1d);
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 70px;

    clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 70px,
      21px 35px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 70px,
      21px 35px
    );
  }

  &.active {
    width: 75%;
  }

  &.complete {
    // background: repeating-linear-gradient(-65deg, #00897b, #109c1d);
    color: white !important;

    &:before {
      left: 0;
    }
    // background: repeating-linear-gradient(
    //   -65deg,
    //   #fcfcfc,
    //   #fcfcfc 20px,
    //   #f4faf7 20px,
    //   #f4faf7 40px
    // );
  }

  &:first-child {
    margin: 0 0 0 0px;

    clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 0px,
      0px 0px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 0px,
      0px 0px
    );

    &:before {
      clip-path: polygon(
        0 0,
        calc(100% - 21px) 0,
        100% 35px,
        calc(100% - 21px) 70px,
        calc(100% - 21px) 100%,
        0 100%,
        0 0px,
        0px 0px
      );
      -webkit-clip-path: polygon(
        0 0,
        calc(100% - 21px) 0,
        100% 35px,
        calc(100% - 21px) 70px,
        calc(100% - 21px) 100%,
        0 100%,
        0 0px,
        0px 0px
      );
    }
  }

  &:last-child {
    clip-path: polygon(
      0 0,
      100% 0,
      100% 35px,
      100% 70px,
      100% 100%,
      0 100%,
      0 70px,
      21px 35px
    );
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      100% 35px,
      100% 70px,
      100% 100%,
      0 100%,
      0 70px,
      21px 35px
    );

    &:before {
      clip-path: polygon(
        0 0,
        100% 0,
        100% 35px,
        100% 70px,
        100% 100%,
        0 100%,
        0 70px,
        21px 35px
      );
      -webkit-clip-path: polygon(
        0 0,
        100% 0,
        100% 35px,
        100% 70px,
        100% 100%,
        0 100%,
        0 70px,
        21px 35px
      );
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.6s ease;
  transition-delay: 0.5s;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  display: none;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(35px);
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(200%, 0);
}
</style>