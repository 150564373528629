

<template>
  <div
    class="sign-up-form-container bg-grey-gradient pt-0 mt-0"
    :class="{ 'loading-finish': selectedPortal }"
  >
    <v-img class="logo" v-if="dark" src="/static/images/logos/logo.png">
    </v-img>
    <v-img class="logo" v-else src="/static/images/logos/logo-light.png">
    </v-img>
    <div class="portal-loading-finish"></div>
    <v-fade-transition>
      <SignUpComponent
        ref="signUpComponent"
        @onSignUpCompleted="onSignUpCompleted"
        @onUserUpdate="onUserUpdate"
        v-model="user"
        name="Sign UP"
        class="sign-up-form pa-10 pt-0 mt-0 pb-0"
      ></SignUpComponent>
    </v-fade-transition>
  </div>
</template>



<script>
import { AuthAPIInstance } from "../components/api";
import SignUpComponent from "../components/wad/molecules/signIn/SignUpComponent.vue";

export default {
  name: "SignUp",
  data: () => ({
    dark: true,

    selectedPortal: null,
    showSplash: false,
    user: { profile: {} },
    invite: "",
  }),
  async created() {
    const { invite } = this.$route.query;

    // redirect to sign in
    if (!invite) this.$router.push("/desktop");

    // this.user = await this.$store.dispatch('MainStore/getUser');

    this.invite = invite;

    try {
      const { user } = await AuthAPIInstance.validateInvite(invite);
      this.user = user;
    } catch (error) {
      console.log("ERROR: ", error);
      this.$router.push("/desktop");
    }
  },
  components: {
    SignUpComponent,
  },
  methods: {
    async onSignUpCompleted(form) {
      console.log("onSignUpCompleted form: ", form);
      this.user = await this.$store.dispatch("MainStore/signUpInvite", {
        invite: this.invite,
        ...form,
      });

      this.$refs.signUpComponent.next();
    },
    async onUserUpdate(form) {
      console.log("onUserUpdate form: ", form);
      this.user = await this.$store.dispatch("MainStore/updateUser", form);
      const finish = this.$refs.signUpComponent.next();

      console.log("finish: ", finish);

      if (finish) {
        this.$store.dispatch("MainStore/showSplash");
        setTimeout(() => {
          this.$router.push("/desktop");
        }, 4500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-finish {
  width: 100% !important;
}

.portal-loading-finish {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
}

.portal-loading-finish.open-portal {
  animation: circle 3s;
  display: block;
}

@keyframes circle {
  0% {
    clip-path: circle(10%);
  }
  50% {
    clip-path: circle(45%);
  }
  100% {
    clip-path: circle(100%);
  }
}

.sign-up-form-container {
  // background: linear-gradient(to bottom, #000, transparent);
  backdrop-filter: blur(15px);
  position: fixed;
  max-height: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;

  .sign-up-form {
    width: 50vw;
    margin: 0 auto;
    overflow: auto;
    max-height: calc(100% - 200px);
  }

  @media (max-width: 1264px) {
    .sign-up-form {
      width: 100vw;
      margin: 0 auto;
      padding: 0 !important;
    }
  }

  .logo {
    margin-top: 25px;
    width: 300px;
    margin: 0 auto;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  .portal-list {
    transition: all 0.5s;
  }
  .portal-list-enter, .portal-list-leave-to
/* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    // transform: scale(0);
    opacity: 0;
    transform: translateX(330px);
  }
  .portal-list-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .portal-list-leave-active {
    /*position: absolute;*/
  }

  .portal-list-move {
    opacity: 1;
    transition: all 0.5s;
  }

  .portal-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px !important;
    .portal-selection-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 864px) {
    width: 100%;
  }
}

.sign-up-completed {
  width: 100%;

  .logo {
    width: 700px;
  }
}
</style>



