
<template>
  <v-card
    class="elevation-0 transparent page-card prompt-details"
    @mousemove="configConsoleMouseMove"
    @mouseup="configConsoleMouseUp"
    @drag="configConsoleMouseMove"
    @touchmove="configConsoleMouseMove"
  >
    <v-card-text class="prompt-content">
      <v-toolbar dense class="mb-8 prompt-debug-toolbar">
        <!-- <v-divider vertical></v-divider> -->
        <v-row class="main-config-row">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="makeParam" text>
                <v-icon>mdi-text-recognition</v-icon>
              </v-btn>
            </template>
            <span>
              Assign Parameter: Clicking this button will replace the selected
              text in the prompt with a new parameter. Easily assign specific
              values or variables to enhance your prompt customization.</span
            >
          </v-tooltip>

          <!-- <PlayMenu @onPlay="onPlay" :loading="debug"></PlayMenu> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="reloadText" text>
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span
              >Clear Parameters: Clicking this button will remove all assigned
              parameters from the prompt. Clear the parameter values and start
              fresh, ensuring a clean slate for your prompt generation.
            </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="removeText" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>
              Clear All: By clicking this button, you can remove the prompt
              markup and all associated parameters. This action allows you to
              reset the prompt completely and start over with a clean template.
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                @click="
                  () => {
                    if (tab !== 'tab-7') tab = 'tab-7';
                    else {
                      onPlay();
                    }
                  }
                "
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                text
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
            <span>
              Play: Run your prompt with the parameters you have defined by
              clicking this button. Experience the power of your customized
              prompt as it generates the desired output based on your
              specifications.</span
            >
          </v-tooltip>
          <v-spacer></v-spacer>

          <v-divider vertical class="mr-7"></v-divider>

          <MenuConnector
            mocked
            class="connector-selector"
            :helpText="'Pick the desired connector to use'"
            :connector="promptVersion.defaultConnector"
            :version="promptVersion.defaultConnectorVersion"
            @onConnectorChanged="
              (conn) => {
                promptVersion.defaultConnector = conn;
                promptVersion.defaultConnectorVersion = undefined;
                promptVersion.default_connector_version_id = null;
                updatePrompt();
              }
            "
            @onConnectorVersionChanged="
              (ver) => {
                promptVersion.defaultConnectorVersion = ver;
                updatePrompt();
              }
            "
          ></MenuConnector>
        </v-row>
        <v-slide-x-transition>
          <v-row
            v-if="
              promptVersion.defaultConnectorVersion &&
              promptVersion.defaultConnectorVersion.proxyParams
            "
            class="connector-proxy-params-row"
          >
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="(param, i) in promptVersion.defaultConnectorVersion
                  .proxyParams"
                :key="i"
              >
                <!-- v-slot="{ active, toggle }" -->

                <div class="mx-2">
                  <ParamPicker
                    v-model="
                      promptVersion.defaultConnectorVersion.proxyParams[i]
                    "
                    :help-text="'Pick a Proxy Parameter: Select a proxy parameter from the dropdown list or input your own to customize your prompt generation.'"
                  ></ParamPicker>
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-slide-x-transition>
        <!-- </v-btn-toggle> -->
      </v-toolbar>

      <v-fade-transition leave-absolute hide-on-leave>
        <PromptPrefill
          v-show="tab === 'tab-7'"
          class="param-prefill"
          ref="paramsPrefill"
          :value="promptVersion"
        ></PromptPrefill>
      </v-fade-transition>
      <v-fade-transition leave-absolute hide-on-leave>
        <HelpFormInput
          v-if="tab !== 'tab-7'"
          v-model="promptVersion.text"
          @contextmenu="show"
          :id="componentId"
          :html="true"
          :rows="4"
          @click="onParamClicked"
          @input="updatePrompt"
          class="main-editor"
        ></HelpFormInput>
      </v-fade-transition>

      <!-- DEBUG CONSOLE -->
      <v-card class="config-console-card" :style="configConsoleStyle">
        <div
          class="config-console primary-gradient"
          @mousedown="configConsoleMouseDown"
          @dragstart="configConsoleMouseDown"
          @touchstart="configConsoleMouseDown"
        ></div>
        <v-tabs v-model="tab" icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-1">
                <v-icon>mdi-text-recognition</v-icon>
              </v-tab>
            </template>
            <span
              >Prompt Parameters: Explore a list of parameters related to the
              prompt and customize your generation experience
            </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-2">
                <v-icon>mdi-comment-quote-outline</v-icon>
              </v-tab>
            </template>
            <span>
              Feedbacks: Discover how others perceive and evaluate the quality
              of your prompts. Gain valuable insights and perspectives from the
              community regarding your generated results
            </span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-3">
                <v-icon>mdi-cog-play-outline</v-icon>
              </v-tab>
            </template>
            <span
              >Processing Configurations: Configure retry settings, execution
              timeouts, and other processing options for prompt generation.
            </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-4">
                <v-icon>mdi-brain</v-icon>
              </v-tab>
            </template>
            <span
              >Validation Models: View and manage a collection of models trained
              by you to validate the quality of generated prompts.
            </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-6">
                <v-icon>mdi-export</v-icon>
              </v-tab>
            </template>
            <span
              >API Hooks and Listeners: Define endpoints and listeners to
              receive and handle generated prompt results via API.
            </span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-7">
                <v-icon>mdi-console</v-icon>
              </v-tab>
            </template>
            <span
              >Debug Console: Test your prompt, experiment with different
              inputs, and examine the generated results in real-time.</span
            >
          </v-tooltip>
        </v-tabs>

        <v-tabs-items v-model="tab" id="console-scroll-container">
          <v-tab-item :key="1" :value="'tab-1'">
            <PromptParams
              @mouseenter="paramMouseenter"
              @mouseleave="paramMouseleave"
              @input="updatePrompt"
              @onNameChanged="onParamNameChanged"
              @onRemove="removeParam"
              v-model="promptVersion.parameters"
              :selected="selectedId"
            ></PromptParams>
          </v-tab-item>
          <v-tab-item :key="2" :value="'tab-2'">
            <Feedbacks
              mocked
              ref="feedbacks"
              v-if="promptVersion.id"
              :promptVersionId="promptVersion.id"
              nested
              :stats="false"
            ></Feedbacks>
          </v-tab-item>
          <v-tab-item :key="3" :value="'tab-3'">
            <PromptExecutionConfig
              v-model="promptVersion.execution_config"
            ></PromptExecutionConfig>
          </v-tab-item>
          <v-tab-item :key="4" :value="'tab-4'"> Tab4 </v-tab-item>
          <v-tab-item :key="5" :value="'tab-5'"> Tab5 </v-tab-item>
          <v-tab-item :key="6" :value="'tab-6'"> Tab6 </v-tab-item>
          <v-tab-item :key="7" :value="'tab-7'">
            <PromptDebugConsole
              mocked
              ref="console"
              :prompt="promptVersion"
              :connector="debugConfig.connector"
              :promptId="promptVersion.prompt.id"
              :version="debugConfig.version"
            ></PromptDebugConsole>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card-text>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="makeParam" :disabled="spanClicked">
          <v-list-item-icon
            ><v-icon color="primary"
              >mdi-text-recognition</v-icon
            ></v-list-item-icon
          >
          <v-list-item-title>Make Param</v-list-item-title>
        </v-list-item>
        <v-list-item @click="() => removeParam()" :disabled="!spanClicked">
          <v-list-item-icon
            ><v-icon color="error">mdi-delete</v-icon></v-list-item-icon
          >
          <v-list-item-title>Remove Param</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-model="paramMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :close-on-content-click="false"
    >
      <v-card>
        <v-card-text>
          <ParamForm
            v-model="selected"
            @mouseenter="paramMouseenter"
            @mouseleave="paramMouseleave"
            @input="updatePrompt"
            @onNameChanged="onParamNameChanged"
            @onRemove="removeParam"
          ></ParamForm>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-card>
</template>
      
    <script>
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import PromptDebugConsole from "./components/PromptDebugConsole.vue";
import PromptParams from "./components/PromptParams.vue";
import { v4 as uuidv4 } from "uuid";
import PromptPrefill from "./components/PromptPrefill.vue";
import _ from "lodash";
import MenuConnector from "../../components/wad/atoms/common/menus/MenuConnector.vue";
import ParamForm from "./components/forms/ParamForm.vue";
import ParamPicker from "../../components/wad/atoms/common/pickers/ParamPicker.vue";
import Feedbacks from "../Feedbacks/Feedbacks.vue";
import PromptExecutionConfig from "./components/PromptExecutionConfig.vue";
import { MOCKED_PROMPT } from "./constants/mocks.constants";

export default {
  data() {
    return {
      showMenu: false,
      componentId: uuidv4(),
      promptId: undefined,
      x: 0,
      y: 0,
      spanClicked: false,
      tab: null,
      promptVersion: { ...MOCKED_PROMPT },
      parameters: [],
      selectedId: null,
      selected: null,
      debugConfig: {},
      debug: false,
      configConsoleStyle: {
        top: "300px",
        height: `calc(100% - 318px)`,
      },
      configConsoleMove: false,
      selectedVersion: "v1",
      paramMenu: false,
    };
  },
  components: {
    HelpFormInput,
    PromptDebugConsole,
    PromptParams,
    // PlayMenu,
    PromptPrefill,
    // PlayForm,
    MenuConnector,
    ParamForm,
    ParamPicker,
    Feedbacks,
    PromptExecutionConfig,
  },
  mounted() {},
  created() {
    this.generateHello();
  },
  methods: {
    async generateHello() {
      const helloText =
        "Take control of your AI prompts like never before with our powerful and intuitive Prompt Management Tool. ";

      for (let i = 0; i < helloText.length; i++) {
        const char = helloText[i];
        if (this.hideHello) return;

        await new Promise((resolve) => {
          setTimeout(() => {
            this.promptVersion.text += char;
            resolve();
          }, this.getRandomNumber(1, 10));
        });
      }
      if (this.hideHello) return;

      await this.simulateParam(24, 31);
      if (this.hideHello) return;

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, this.getRandomNumber(200, 400));
      });
      if (this.hideHello) return;

      await this.simulateParam(37, 40);
    },
    async simulateParam(startOffset, endOffset) {
      const element = document.getElementById(this.componentId);
      if (this.hideHello) return;

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, this.getRandomNumber(200, 400));
      });
      if (this.hideHello) return;

      const range = document.createRange();

      let acc = 0;
      if (this.hideHello) return;

      for (const child of element.childNodes) {
        if (this.hideHello) return;

        if (
          (child.innerText ? child.innerText.length : child.length) + acc >=
          startOffset
        ) {
          range.setStart(child, startOffset);
          range.setEnd(child, endOffset);
          if (this.hideHello) return;

          const selection = window.getSelection();
          if (this.hideHello) return;

          selection.removeAllRanges();
          selection.addRange(range);
          if (this.hideHello) return;

          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, this.getRandomNumber(200, 400));
          });
          if (this.hideHello) return;

          break;
        } else {
          if (this.hideHello) return;

          acc += child.innerText ? child.innerText.length : child.length;
        }
      }

      if (this.hideHello) return;

      this.makeParam();
    },
    getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    configConsoleMouseDown() {
      this.configConsoleMove = true;
    },
    configConsoleMouseUp() {
      this.configConsoleMove = false;
    },
    configConsoleMouseMove(e) {
      if (!this.configConsoleMove) return;

      const touch = e.touches && e.touches[0]; // Get the first touch
      const movementY = touch && touch.movementY; // Access the movementY value

      const newVal =
        parseInt(this.configConsoleStyle.top) +
        (movementY ? movementY : e.movementY);
      this.configConsoleStyle.top = newVal + "px";

      this.configConsoleStyle.height = `calc(100% - ${newVal + 18}px)`;
    },
    reloadText() {
      const regex = /<span\b[^>]*>(.*?)<\/span>/gi;
      this.promptVersion.text = this.promptVersion.text.replace(regex, "$1");
    },
    removeText() {
      this.promptVersion.text = "";
    },
    paramMouseenter(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      if (!paramSpan.classList.contains("hovered"))
        paramSpan.classList.add("hovered");
    },
    paramMouseleave(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      if (paramSpan.classList.contains("hovered"))
        paramSpan.classList.remove("hovered");
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.shiftKey && event.key === "P") {
        this.makeParam();
      }
    },
    onParamNameChanged(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      paramSpan.setAttribute("data-before", param.name);

      const tempElement = document.createElement("span");
      tempElement.setAttribute("class", "param-hider");
      tempElement.style.visibility = "hidden";
      tempElement.style.position = "absolute";
      tempElement.style.top = 0;
      tempElement.style.left = 0;
      tempElement.style.fontSize = "18px";
      tempElement.style.zIndex = "9";

      tempElement.textContent = param.name;

      // Append the temporary element to the DOM
      document.body.appendChild(tempElement);

      // Get the width of the temporary element
      const textWidth = tempElement.offsetWidth;

      // Remove the temporary element from the DOM
      document.body.removeChild(tempElement);

      // Set the calculated width to the target element
      paramSpan.style.width = `${textWidth}px`;

      const container = document.getElementById(this.componentId);

      this.promptVersion.text = container.innerHTML;

      this.updatePrompt();
    },
    show(e) {
      e.preventDefault();

      if (e.target.tagName.toLowerCase() === "span") {
        this.spanClicked = true;
        const [id] = e.target.getAttribute("id").split("p-").reverse();

        this.selectedId = id;
      } else this.spanClicked = false;

      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async makeParam() {
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const container = document.getElementById(this.componentId);

        if (range.toString()) {
          const name = _.snakeCase(range.toString());

          const newParam = {
            id: uuidv4(),
            name: name,
            default_value: range.toString(),
            type: "string",
          };

          this.promptVersion.parameters.push(newParam);

          const newSpan = document.createElement("span");
          newSpan.setAttribute("class", "param-hider");
          newSpan.setAttribute("aria-hidden", "true");
          newSpan.setAttribute("id", `p-${newParam.id}`);
          newSpan.setAttribute("key", newParam.id);
          newSpan.setAttribute("data-before", newParam.name);

          const node = newSpan.getRootNode();

          range.surroundContents(node);

          const afterRendering = document.querySelector(`#p-${newParam.id}`);

          afterRendering.style.width = afterRendering.scrollWidth + "px";

          const htmlAfterSelection = container.innerHTML;

          this.promptVersion.text = htmlAfterSelection;
          this.updatePrompt();
        }
      }
    },
    removeParam(val) {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(
        this.promptVersion.text,
        "text/html"
      );

      const spans = parsedDocument.querySelectorAll(
        `span#p-${val ? val.id : this.selectedId}`
      );

      spans.forEach((span) => {
        const textNode = document.createTextNode(span.textContent);
        span.parentNode.replaceChild(textNode, span);
      });

      this.promptVersion.text = parsedDocument.documentElement.outerHTML;

      this.updatePrompt();
    },
    async onPlay() {
      this.tab = "tab-7";

      this.debug = true;

      // const terminal = await this.waitUntilReady("console");
      this.debug = false;
    },
    waitUntilReady(ref) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.$refs[ref]) {
            clearInterval(interval);
            resolve(this.$refs[ref]);
          }
        }, 100);
      });
    },

    onParamClicked(event) {
      if (event.target.tagName.toLowerCase() === "span") {
        // this.spanClicked = true;
        const [id] = event.target.getAttribute("id").split("p-").reverse();
        this.selectedId = id;

        this.x = event.clientX;
        this.y = event.clientY;

        this.paramMenu = true;
      }
      // else this.spanClicked = false;

      // const target = event.target;
      // if (target) {
      //   const [id] = target.getAttribute("id").split("p-").reverse();
      //   this.selectedId = id;
      // }

      // if (!target.classList.contains("hovered"))
      //   target.classList.add("hovered");
    },
    toParams(text) {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(text, "text/html");
      const spans = parsedDocument.querySelectorAll("span.param-hider");

      const prepared = [...spans].map((span) => {
        const [id] = span.getAttribute("id").split("p-").reverse();

        span.addEventListener("contextmenu", this.show);

        const param = this.promptVersion.parameters.find((p) => p.id === id);

        if (!param)
          return {
            name: `New Param`,
            id: id,
          };
        else return param;
      });

      this.promptVersion.parameters = prepared;
    },

    async updatePrompt() {},
  },
  watch: {
    "promptVersion.text": {
      handler(newVal) {
        this.toParams(newVal);
      },
    },
    selectedId(newVal) {
      this.selected = this.promptVersion.parameters.find((el) => {
        return String(el.id) === String(newVal);
      });
    },
    tab(newVal) {
      if (newVal === "tab-2" && this.$refs.feedbacks) {
        this.$refs.feedbacks.getList();
      }
    },
  },
};
</script>
  
  
<style lang="scss" scoped>
.prompt-content {
  height: calc(100%);
  position: relative;

  @media (max-width: 600px) {
    padding: 4px;
  }
  .prompt-debug-toolbar {
    // height: fit-content!important;
    .main-config-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      justify-content: flex-end;

      @media (max-width: 600px) {
        .connector-selector {
          margin-top: 14px;
        }

        hr {
          display: none;
        }
      }
    }

    .connector-proxy-params-row {
      border-top: 1px solid #e0e0e0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      // justify-content: flex-end;
    }
  }
}

.prompt-details {
  height: 100%;

  .page-card-title {
    justify-content: flex-end;
  }
}
.main-editor {
  line-height: 30px;
  height: 138px;
  overflow-y: auto;

  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.param-prefill {
  padding: 2px;
  line-height: 30px;
  height: 138px;
  overflow-y: auto;
  margin-top: -10px;
  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.config-console {
  width: 100%;
  height: 8px;
  cursor: n-resize;
}
.config-console-card {
  position: absolute;
  top: 300px;
  width: calc(100% - 30px);
  z-index: 3;

  @media (max-width: 600px) {
    top: 444px !important;
    width: calc(100% - 8px);
  }
}

.prefill-col {
  display: flex;
  transition: all 0.3s;
}
.play-params-col {
  display: flex;
  min-width: 340px;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 fit-content;
  transition: all 0.3s;

  .play-params-card {
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    height: 260px;
    overflow: hidden;
    .play-form {
      overflow-y: auto;
      height: 210px;
    }
  }
}
</style>