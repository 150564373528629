var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-0 transparent page-card connector-details"},[_c('v-card-title',{staticClass:"page-card-title"},[_c('h2',{staticClass:"page-title"},[_c('SimpleInput',{attrs:{"icon":'mdi-pencil',"hide-details":""},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.connector.name),callback:function ($$v) {_vm.$set(_vm.connectorVersion.connector, "name", $$v)},expression:"connectorVersion.connector.name"}})],1),_c('v-spacer'),(_vm.connectorVersion.connector.status)?_c('MenuConnectorStatuses',{attrs:{"helpText":'Pick the right status for your connector.',"autoload":""},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.connector.status),callback:function ($$v) {_vm.$set(_vm.connectorVersion.connector, "status", $$v)},expression:"connectorVersion.connector.status"}}):_vm._e(),(_vm.connectorVersion.status)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.selectedVersion === _vm.connectorVersion.v ? 'primary' : 'grey',"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.selectedVersion === _vm.connectorVersion.v ? "LTS: " : "")+"   "),_c('strong',[_vm._v(_vm._s(_vm.connectorVersion.v))])]):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"connector-setup-content"},[_c('v-row',{staticClass:"connector-setup-content-row"},[_c('v-col',{staticClass:"connector-setup-content-col",attrs:{"cols":"12"}},[_c('ul',{staticClass:"pipeline-header-container"},[_c('li',{staticClass:"stepper__item",class:{
              complete: _vm.tab > 0,
              active: _vm.tab === 1,
            },on:{"click":function($event){_vm.tab = 1}}},[_c('v-card',{staticClass:"pipeline-header-card transparent elevation-0"},[_c('v-card-title',[_c('span',{staticClass:"tab-title",class:{
                    vertical: _vm.tab !== 1,
                  }},[_vm._v("Input")])]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.tab === 1)?_c('v-card-text',{staticClass:"pipeline-config-content"},[_c('v-card-title',[_vm._v("Credentials")]),_c('RequestParamsEditor',{attrs:{"addLabel":"New Credential"},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.decryptedCredentials),callback:function ($$v) {_vm.$set(_vm.connectorVersion, "decryptedCredentials", $$v)},expression:"connectorVersion.decryptedCredentials"}}),_c('v-card-title',[_vm._v("Proxy Parameters")]),_c('RequestParamsEditor',{attrs:{"addLabel":"New Proxy Parameter"},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.proxyParams),callback:function ($$v) {_vm.$set(_vm.connectorVersion, "proxyParams", $$v)},expression:"connectorVersion.proxyParams"}})],1):_vm._e()],1),_c('v-fade-transition',[(_vm.tab === 1)?_c('v-card-actions',{staticClass:"card-actions-bottom pa-8"},[_c('v-row',{staticClass:"mr-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.tab = 2}}},[_vm._v("Next ")])],1)],1):_vm._e()],1)],1)],1),_c('li',{staticClass:"stepper__item",class:{
              complete: _vm.tab > 1,
              active: _vm.tab === 2,
            },on:{"click":function($event){_vm.tab = 2}}},[_c('v-card',{staticClass:"pipeline-header-card transparent elevation-0"},[_c('v-card-title',{staticClass:"pr-10"},[_c('span',{staticClass:"tab-title",class:{
                    vertical: _vm.tab !== 2,
                  }},[_vm._v("Auth")]),_c('v-spacer'),(_vm.tab === 2)?_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"dark":"","label":"Skip","color":"white","value":true,"hide-details":""},on:{"change":function($event){return _vm.$emit('input', _vm.connectorVersion.config.auth.skip)}},model:{value:(_vm.connectorVersion.config.auth.skip),callback:function ($$v) {_vm.$set(_vm.connectorVersion.config.auth, "skip", $$v)},expression:"connectorVersion.config.auth.skip"}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.tab === 2)?_c('v-card-text',{staticClass:"pipeline-config-content"},[_c('ConnectorHTTPUrlConfig',{on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.config.auth),callback:function ($$v) {_vm.$set(_vm.connectorVersion.config, "auth", $$v)},expression:"connectorVersion.config.auth"}}),_c('ConnectorHTTPAPIConfigVue',{staticClass:"connector-http-config",attrs:{"availableParams":_vm.availableParams},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.config.auth),callback:function ($$v) {_vm.$set(_vm.connectorVersion.config, "auth", $$v)},expression:"connectorVersion.config.auth"}})],1):_vm._e()],1),_c('v-fade-transition',[(_vm.tab === 2)?_c('v-card-actions',{staticClass:"card-actions-bottom pa-8"},[_c('v-row',{staticClass:"mr-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.tab = 3}}},[_vm._v("Next ")])],1)],1):_vm._e()],1)],1)],1),_c('li',{staticClass:"stepper__item",class:{
              complete: _vm.tab > 2,
              active: _vm.tab === 3,
            },on:{"click":function($event){_vm.tab = 3}}},[_c('v-card',{staticClass:"pipeline-header-card transparent elevation-0"},[_c('v-card-title',{staticClass:"pr-8"},[_c('span',{staticClass:"tab-title",class:{
                    vertical: _vm.tab !== 3,
                  }},[_vm._v("Execute ")]),_c('v-spacer'),_c('transition',{attrs:{"name":"slide"}},[(!_vm.requiredParamsUsed && _vm.tab === 3)?_c('v-alert',{staticClass:"ma-0 pa-0 config-alert",attrs:{"dense":"","border":"left","type":"warning"}},[_vm._v(" Ensure "),_c('strong',[_vm._v("PRST_PROMPT")]),_vm._v(" and other parameters "),_c('br'),_vm._v(" are assigned. ")]):_vm._e()],1)],1),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.tab === 3)?_c('v-card-text',{staticClass:"pipeline-config-content"},[_c('ConnectorHTTPUrlConfig',{on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.config.execute),callback:function ($$v) {_vm.$set(_vm.connectorVersion.config, "execute", $$v)},expression:"connectorVersion.config.execute"}}),_c('ConnectorHTTPAPIConfigVue',{staticClass:"connector-http-config",attrs:{"availableParams":_vm.availableParams},on:{"input":_vm.updateConnector},model:{value:(_vm.connectorVersion.config.execute),callback:function ($$v) {_vm.$set(_vm.connectorVersion.config, "execute", $$v)},expression:"connectorVersion.config.execute"}})],1):_vm._e()],1),_c('v-fade-transition',[(_vm.tab === 3)?_c('v-card-actions',{staticClass:"card-actions-bottom pa-8"},[_c('v-row',{staticClass:"mr-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.requiredParamsUsed,"color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onAPITest.apply(null, arguments)}}},[_vm._v("Test Connection ")])],1)],1):_vm._e()],1)],1)],1),_c('li',{staticClass:"stepper__item",class:{
              complete: _vm.tab > 3,
              active: _vm.tab === 4,
            },on:{"click":function($event){_vm.tab = 4}}},[_c('v-card',{staticClass:"pipeline-header-card transparent elevation-0"},[_c('v-card-title',{staticClass:"pr-8"},[_c('span',{staticClass:"tab-title",class:{
                    vertical: _vm.tab !== 4,
                  }},[_vm._v("Test ")])]),_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 4),expression:"tab === 4"}],staticClass:"pipeline-config-content",attrs:{"id":"console-scroll-container"}},[(_vm.user && _vm.user.id)?_c('ConnectorTerminal',{attrs:{"connectorId":_vm.connectorId,"userId":_vm.user.id}}):_vm._e()],1)],1),_c('v-fade-transition',[(_vm.tab === 4)?_c('v-card-actions',{staticClass:"card-actions-bottom pa-8"},[_c('v-row',{staticClass:"mr-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.requiredParamsUsed,"color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onAPITest.apply(null, arguments)}}},[_vm._v("Test Again ")])],1)],1):_vm._e()],1)],1)],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }