import {
    API
} from '../Api.class';


export class ConnectorAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/connectors', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/connectors/statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async versions(connectorId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/connectors/${connectorId}/versions`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/connectors/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/connectors', body, {
            meta
        });
    }

    async update(connectorVersion, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/connectors/${connectorVersion.connector.id}`, connectorVersion, {
            meta
        });
    }


    async delete(connector, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/connectors/${connector.id}`, {
            meta
        });
    }

    async test(connectorId, type, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/connectors/${connectorId}/test${type? '/'+type:''}`, {}, {
            meta
        });
    }
}