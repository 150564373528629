<template>
  <v-timeline id="history" class="message-history messages" dense>
    <v-slide-x-reverse-transition group hide-on-leave>
      <v-timeline-item
        class="comment-item"
        v-for="message in value"
        :id="`message-${message.id}`"
        :key="message.id"
        :class="{
          'not-me': !message.isMe,
          me: message.isMe,
        }"
        hide-dot
        fill-dot
      >
        <!-- <v-hover v-slot="{ hover }"> -->
        <v-tooltip bottom content-class="avatar-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on">
              <img
                v-if="message.isBot"
                src="/logo.png"
                :lazy-src="'/mim-bot.png'"
              />
              <v-icon v-else :size="40" color="primary"> mdi-account </v-icon>
            </v-avatar>
          </template>
          <v-avatar :size="180">
            <img
              v-if="message.isBot"
              src="/mim-bot.png"
              :lazy-src="'/mim-bot.png'"
            />
            <img
              v-else
              src="https://i.pravatar.cc/64"
              :lazy-src="'https://i.pravatar.cc/64'"
            />
          </v-avatar>
        </v-tooltip>

        <!-- </v-hover> -->

        <v-fade-transition hide-on-leave>
          <div v-if="message.loading" class="loading"><span></span></div>

          <v-card v-else class="elevation-2 message new">
            <v-card-text class="">{{
              message.type === "password"
                ? hidePassword(message.message)
                : message.message
            }}</v-card-text>
          </v-card>
        </v-fade-transition>
      </v-timeline-item>
    </v-slide-x-reverse-transition>
  </v-timeline>
</template>
  
  
  
  <script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    events: [],
    nonce: 0,
    message: {
      message: "",
      files: [],
    },
  }),

  methods: {
    comment() {
      this.message.time = new Date().toISOString();

      this.$emit("onMessage", this.message);

      this.message = {
        message: "",
        files: [],
      };
    },
    clearMessage() {
      this.message.message = "";
    },
    addFile() {},
    hidePassword(pass) {
      return new Array(String(pass).length).fill("*").join("");
    },
  },
  //   watch: {
  //     value: {
  //       handler(val) {
  //         val

  //         // setTimeout(() => {
  //         //     var messageBody = document.querySelector("#history");
  //         // messageBody.scrollTop =
  //         //   messageBody.scrollHeight - messageBody.clientHeight;
  //         // }, 200);

  //       },
  //       deep: true,
  //     },
  //   },
};
</script>
  
  <style lang="scss" scoped>
.message-history {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;

  //   -webkit-mask-image: -webkit-gradient(
  //     linear,
  //      top,
  //      bottom,
  //     from(rgba(0, 0, 0, 1)),
  //     to(rgba(0, 0, 0, 0.3))
  //   );

  .comment-item {
    &.not-me {
      flex-direction: row !important;

      .v-timeline-item__body {
        display: flex;
        flex-direction: row-reverse !important;
      }
      .message {
        margin-left: 16px;

        &::before {
          transform: rotate(0deg);
          left: -10px;
        }
      }
    }
  }

  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 1)
  );

  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
  }
}
</style>