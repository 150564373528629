import {
    API
} from '../Api.class';
import {
    KnowledgeRecordAPI
} from './KnowledgeRecords.api';

export class KnowledgeAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Records = new KnowledgeRecordAPI(baseUrl);
    }


    async get(meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/knowledgebase/config`, {
            meta
        });
    }


    async test(body, meta) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/knowledgebase/test`, body, {
            meta
        });
    }


    async update(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.put(`api/v1/knowledgebase/config/${body.id}`, body, {
            meta
        });
    }
}