<template>
  <v-card class="prompt-terminal elevation-0 transparent">
    <v-card-text class="terminal-content" ref="scrollContainer">
      <v-list three-line>
        <v-slide-y-transition class="py-0 px-4" group tag="div">
          <template v-if="logs && logs.length">
            <v-list-item
              v-for="record in logs"
              :key="record.id"
              @click="
                () => {
                  dialog = true;
                  activeRecord = record;
                }
              "
            >
              <div
                class="indicator"
                :style="{
                  '--color': generateColorFromUUID(record.task_id),
                }"
              ></div>

              <div class="status-small status">
                <div
                  v-if="
                    record.message === 'PROMPT_TASK_AUTH_COMPLETED' ||
                    record.message === 'PROMPT_TASK_COMPLETED'
                  "
                  class="o-circle c-container__circle o-circle__sign--success"
                >
                  <div class="o-circle__sign"></div>
                </div>
                <div
                  v-else-if="record.message === 'PROMPT_TASK_FAILED'"
                  class="o-circle c-container__circle o-circle__sign--failure"
                >
                  <div class="o-circle__sign"></div>
                </div>
                <!-- </v-fade-transition> -->
              </div>

              <v-list-item-content>
                <v-list-item-title
                  v-html="record.displayHtml"
                ></v-list-item-title>
                <v-list-item-subtitle>{{
                  formatTime(record.date)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-alert key="empty-alert" v-else prominent text type="info">
            No recent logs found for this connector.
          </v-alert>
        </v-slide-y-transition>
        <div id="bottom-item"></div>
      </v-list>
    </v-card-text>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class=""> Details </v-card-title>

        <v-card-text class="json-container">
          <pre
            v-if="activeRecord"
            class="json-container-pre"
            v-html="prettifyJson(activeRecord.payload)"
          ></pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> I accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
  
  
  <script>
import { LogsAPIInstance } from "../../../components/api";
import _ from "lodash";
import { prettyPrintJson } from "pretty-print-json";
import goTo from "vuetify/lib/services/goto";
import { FormatterHelper } from '../../../components/helpers/formatter.helper';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    connectorId: {
      type: String,
    },
    taskIds: {
      type: Array,
      default: () => [],
    },
    userId: {},
  },
  components: {},
  data() {
    return {
      listener: undefined,
      logs: [],
      scrollTimeout: undefined,
      dialog: false,
      activeRecord: undefined,
      formatTime: FormatterHelper.dateWithTime,

    };
  },
  computed: {},
  async created() {
    await this.getRecent();
    this.listen();
  },
  beforeDestroy() {
    this.listener.close();
  },
  mounted() {
    try {
      this.scrollToBottom();
    } catch (error) {
      console.warn("unable to scroll");
    }
  },
  methods: {
    generateColorFromUUID(uuid) {
      // Remove any hyphens from the UUID
      const uuidWithoutHyphens = uuid.replace(/-/g, "");

      // Take the first 6 characters from the UUID
      const hexColor = uuidWithoutHyphens.substr(0, 6);

      // Prepend a '#' to the hex color
      const color = `#${hexColor}`;

      return color;
    },
    async getRecent() {
      const { items } = await LogsAPIInstance.recent(
        {},
        {
          connectorId: this.connectorId,
          userId: this.userId,
        }
      );

      this.logs = items;
      this.scrollToBottom();
    },

    async listen() {
      const maxId = _.maxBy(this.logs, "id");
      this.listener = await LogsAPIInstance.listen(
        maxId ? maxId.id : undefined,
        {
          connectorId: this.connectorId,
          userId: this.userId,
        }
      );

      this.listener.onmessage = (event) => {
        try {
          const parsed = JSON.parse(event.data);

          const existed = this.logs.find((lg) => lg.id === parsed.id);
          if (!existed) {
            this.logs.push(parsed);

            this.logs = this.logs.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            );
          }

          this.scrollToBottom();
        } catch (error) {
          console.warn("Unable to parse received data, ignore it...", error);
        }
      };

      this.listener.onerror = (error) => {
        console.error("EventSource error:", error);
        this.listener.close();

        setTimeout(() => {
          this.listen();
        }, 1000);
      };
    },
    prettifyJson(payload) {
      return prettyPrintJson.toHtml(payload);
    },
    scrollToBottom() {
      const container = this.$refs.scrollContainer;

      console.log("SCROLL??? ", container.scrollHeight);
      //   container.scrollTop = container.scrollHeight;

      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        return goTo("#bottom-item", {
          duration: 800,
          offset: 0,
          easing: "easeInOutCubic",
          container: "#console-scroll-container",
        });
      }, 200);
    },
  },

  watch: {
    // taskIds: {
    //   handler() {
    //     this.listener.close();
    //     this.listen();
    //   },
    //   deep: true,
    // },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.prompt-terminal {
  .terminal-content {
    overflow-y: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .indicator {
      width: 4px;
      height: 100%;
      background-color: var(--color, blue);
      position: absolute;
      left: 0;
      top: 0;
    }

    .status {
      width: 40px;
      height: 100%;
      margin-right: 20px;
    }

    .record {
      width: 100%;
    }

    .payload {
      word-break: break-all;
    }
  }
}

.json-container {
  max-width: 800px;
  text-overflow: ellipsis;
  overflow-x: auto;
  max-height: 60vh;
}
</style>
  
  
  