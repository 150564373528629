<template>
  <v-tooltip v-bind="tooltipAttrs">
    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
      <v-menu content-class="selector-menu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-if="displayValue"
            v-bind="{
              ...menuAttrs,
              ...attrs,
            }"
            v-on="{
              ...menuOn,
              ...on,
            }"
            @click="$emit('click', $event)"
            class="ma-2"
            color="secondary"
            label
          >
            <v-icon
              class="mr-2"
              :color="
                displayValue.iconColor ? displayValue.iconColor : 'primary'
              "
              >{{ displayValue.icon }}</v-icon
            >
            <span class="selection">
              {{ displayValue.name }}
            </span>
          </v-chip>
        </template>
        <v-list class="statuses-list">
          <v-list-item
            v-for="(item, index) in allowedTypes"
            :key="index"
            two-line
            @click="$emit('input', item.value)"
          >
            <v-list-item-icon>
              <v-icon :color="item.iconColor ? item.iconColor : 'primary'">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>{{ helpText }}</span>
  </v-tooltip>
</template>
          
          
  <script>
export default {
  props: {
    value: {},
    types: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 400,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
  },
  computed: {
    displayValue() {
      const item = this.pItems.find((v) => v.value === this.value);

      return item || {};
    },
    allowedTypes() {
      return this.types && this.types.length
        ? this.pItems.filter((it) => this.types.includes(it.value))
        : this.pItems;
    },
  },
  data() {
    return {
      pItems: [
        {
          icon: "mdi-code-string",
          value: "string",
          name: "String",
        },
        {
          icon: "mdi-numeric",
          value: "number",
          name: "Number",
        },
        {
          icon: "mdi-code-array",
          value: "array",
          name: "Array",
        },
        {
          icon: "mdi-ampersand",
          value: "boolean",
          name: "Boolean",
        },
        {
          icon: "mdi-code-json",
          value: "json",
          name: "JSON",
        },
        {
          icon: "mdi-format-list-checkbox",
          value: "list",
          name: "List",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
          
          
  <style lang="scss" scoped>
.selection {
  font-size: 16px;
  margin-left: 4px;
  color: black;
}

.statuses-list{
    max-height: 300px;
    min-width: 200px;
    overflow-y: auto;
}
</style>