<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col class="param-col">
        <!-- General -->
        <HelpFormInput
          v-model="form.name"
          :rules="nameRules"
          label="Param Name"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="
            () => {
              $emit('input', value);
              $emit('onNameChanged', value);
            }
          "
        ></HelpFormInput>
      </v-col>
      <v-col class="param-col">
        <HelpFormInput
          v-model="form.default_value"
          label="Default Value"
          :helpText="'Specify the initial value that the property should have if no explicit value is provided. This value serves as a fallback or a baseline for the property, ensuring consistent behavior in cases where a specific value is not assigned. Choose a default value that aligns with the expected data type and intended functionality of the property to achieve desired outcomes.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
      <v-col class="type-col">
        <MenuTypesPicker
          :types="['string', 'number']"
          v-model="form.type"
          @input="$emit('input', value)"
          :helpText="'Specify the data type of the parameter, indicating whether it should be treated as a string or a number. Choosing the correct parameter type is essential for ensuring proper data handling and validation. If the parameter expects textual information, select \'string\'; if it requires numerical values, select \'number\'. This selection helps maintain data integrity and facilitates accurate processing within the system.'"
        >
        </MenuTypesPicker>

        <v-btn @click="$emit('onRemove', value)" color="error" icon>
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
        
        
  <script>
import HelpFormInput from "../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import MenuTypesPicker from "../../../../components/wad/atoms/common/menus/MenuTypesPicker.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      types: [
        {
          value: "STRING",
          name: "String",
        },
        {
          value: "NUMBER",
          name: "Number",
        },
      ],

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      termRules: [(v) => !!v || "Link to your Terms & Conditions is required"],
      questionRules: [
        (v) => !!v || "The poll should provide a transparent question",
      ],
      videoRules: [
        (v) =>
          !!v && !v.match(/(youtube|vimeo|youtu\.be)/gi)
            ? "Sorry, but we can't recognize video provider. You can use attachments in this case."
            : true,
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {},
  components: {
    HelpFormInput,
    MenuTypesPicker,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
    <style lang="scss" scoped>
.param-col {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 100%;
}
.type-col {
  display: flex;
  flex-direction: row;
  flex: 0 0 fit-content;
  max-width: 100%;
  align-items: center;
}
</style>