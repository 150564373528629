<template>
  <v-card class="prompt-item mx-auto" @click="(e) => $emit('click', value, e)">
    <v-card-title class="prompt-title primary-gradient">
      <span class="text-h6 font-weight-light">{{ value.name }}</span>
      <div
        class="item-status"
        :style="{
          backgroundColor:
            value.status.code === 'DRAFT'
              ? 'red'
              : value.status.code === 'ACTIVE'
              ? 'purple'
              : 'grey',
        }"
      >
        <span>{{ value.status.name }}</span>
      </div>
    </v-card-title>

    <v-card-text class="font-weight-bold pa-4 prompt-text-preview">
      <v-list-item class="full-width">
        <v-list-item-icon>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-face-agent</v-icon>
          </v-btn>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class=""> Support Category </v-list-item-title>
          <v-list-item-subtitle>{{
            value.category && value.category.name
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-actions class="card-actions-bottom">
      <v-spacer></v-spacer>
      <span class="mr-1">·</span>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            @click.prevent.stop="$emit('onRemove', value)"
            small
            outlined
            ><v-icon>mdi-check</v-icon>
            Resolved
          </v-btn>
        </template>
        <span>
          Clicking on this button will change request status to Resolved.</span
        >
      </v-tooltip> -->
    </v-card-actions>
  </v-card>
</template>
      
      
<script>
import moment from "moment";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      //
    };
  },

  methods: {
    fromNow(date) {
      return moment.isDate(date) ? moment(date).fromNow() : "never";
    },
    async copyToClipBoard() {
      await navigator.clipboard.writeText(this.value);

      this.$toast.open({
        type: "success",
        message: "Copied to clipboard",
        position: "top-right",
      });
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.prompt-item {
  min-height: 240px;

  .prompt-text-preview {
    max-height: 196px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .prompt-title {
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    .item-status {
      position: absolute;
      top: -10px;
      right: -60px;
      transform: rotate(45deg);
      width: 150px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      height: 62px;
      text-transform: capitalize;
      align-items: end;
      vertical-align: bottom;
    }
  }

  .active-version {
    max-width: 100px;
  }
}
</style>