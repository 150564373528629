<template>
    <v-dialog v-model="value" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirmation: Remove Prompt
        </v-card-title>
  
        <v-card-text>
          Deleting a prompt is an irreversible action. Once deleted, the prompt
          cannot be recovered. If you wish to retain the prompt for future
          reference, we recommend changing its status to 'Archived' instead. This
          will ensure the prompt is no longer active but can still be accessed if
          needed.
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('onCancel')"> Cancel </v-btn>
          <v-btn color="error" text @click="$emit('onRemove')"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    components: {},
    data() {
      return {};
    },
    watch: {},
  };
  </script>