<template>
  <v-tooltip v-bind="tooltipAttrs">
    <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-if="value"
            v-bind="{
              ...menuAttrs,
              ...attrs,
            }"
            v-on="{
              ...menuOn,
              ...on,
            }"
            @click="$emit('click', $event)"
            class="display-chip"
            label
          >
            <v-icon
              class="mr-2"
              v-if="value.icon"
              :color="value.iconColor ? value.iconColor : 'primary'"
              >{{ value.icon }}</v-icon
            >
            <span class="selection">
              {{ value.name }}
            </span>
            <span class="value">
              {{ value.value || value.default_value }}
            </span>
          </v-chip>
        </template>
        <v-card>
          <v-card-text>
            <v-list class="statuses-list" v-if="value.type === 'list'">
              <v-list-item
                v-for="(item, index) in value.options
                  .split(',')
                  .map((el) => el.trim())"
                :key="index"
                @click="value.value = item"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <HelpFormInput
              v-else
              v-model="value.value"
              label="Value"
              :value="value.default_value"
              :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
              required
              @input="
                () => {
                  $emit('input', value);
                  $emit('onNameChanged', value);
                }
              "
            ></HelpFormInput>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <span>{{ helpText }}</span>
  </v-tooltip>
</template>
            
            
<script>
import HelpFormInput from "../inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    types: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },

    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
  },
  computed: {},
  components: {
    HelpFormInput,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
            
            
    <style lang="scss" scoped>
.display-chip {
  background-color: transparent !important;
  padding: 0px;
  .v-chip__content {
    width: 100%;
  }
  .selection {
    font-size: 16px;
    margin-left: 4px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 fit-content;
    max-width: 100%;
    position: relative;
    overflow: visible;
    padding: 8px;
    height: 100%;
    text-align: center;
    line-height: 100%;
    margin: 0 auto;
    vertical-align: middle;
    align-items: center;
    font-size: 14px;

    &::after {
      content: "";
      top: -100%;
      right: 0;
      border-right: 1px solid #109c1d !important;
      height: 300%;
      position: absolute;
    }
  }
  .value {
    font-size: 16px;
    margin-left: 4px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 fit-content;
    max-width: 100%;
    padding: 8px;
    height: 100%;
    text-align: center;
    line-height: 100%;
    margin: 0 auto;
    vertical-align: middle;
    align-items: center;
    font-size: 14px;
    background: rgba($color: #000000, $alpha: 0.05);
  }
}

.statuses-list {
  max-height: 300px;
  min-width: 200px;
  overflow-y: auto;
}
</style>