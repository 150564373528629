<template>
  <v-card color="transparent elevation-0 pt-0 mt-0" :dark="dark">
    <h2 class="sign-in-name pt-0 mt-0">SIGN UP</h2>
    <v-card-text class="pt-0 mt-0">
      <v-stepper v-model="step" class="transparent elevation-0">
        <v-stepper-header class="transparent elevation-0 sign-up-header">
          <v-stepper-step :complete="step > 1" step="1">
            Registration Completion
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            Personal Information
          </v-stepper-step>

          <v-stepper-step v-if="form.customer" :complete="step > 3" step="3">
            Company Information
          </v-stepper-step>

          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items class="transparent">
          <v-stepper-content step="1">
            <SignUpFormVue
              class="pa-4"
              ref="generalForm"
              v-model="form"
            ></SignUpFormVue>
          </v-stepper-content>

          <v-stepper-content step="2">
            <CustomerSignUpForm
              v-if="form.customer"
              ref="customerForm"
              class="customer-form pa-4"
              v-model="form"
            ></CustomerSignUpForm>
            <EmployeeSignUpForm
              v-if="form.employee"
              ref="employeeForm"
              class="pa-4"
              v-model="form"
            ></EmployeeSignUpForm>
          </v-stepper-content>

          <v-stepper-content step="3" v-if="form.customer">
            <CustomerCompanySignUpVue
              ref="customerCompanyForm"
              class="customer-company-form pa-4"
              v-model="form"
            ></CustomerCompanySignUpVue>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!valid || pLoading"
        color="primary"
        class="mr-4"
        @click="validate"
        :loading="pLoading"
      >
        <span
          v-if="(form.customer && step == 3) || (form.employee && step == 2)"
        >
          Sign In
        </span>
        <span v-if="(form.customer && step < 3) || (form.employee && step < 2)">
          Next
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
  
  
<script>
import CustomerCompanySignUpVue from "./CustomerCompanySignUp.vue";
import CustomerSignUpForm from "./CustomerSignUpForm.vue";
import EmployeeSignUpForm from "./EmployeeSignUpForm.vue";

import SignUpFormVue from "./SignUpForm.vue";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    value: {},
  },
  components: {
    CustomerSignUpForm,
    SignUpFormVue,
    CustomerCompanySignUpVue,
    EmployeeSignUpForm,
  },
  data() {
    return {
      pLoading: this.loading,
      form: this.value
        ? this.value
        : {
            password: "",
            repeatPassword: "",
            email: "",
            profile: {},
            company: {},
            employee: {},
          },

      valid: true,
      step: 1,
    };
  },

  methods: {
    validate() {
      switch (true) {
        case this.$refs.generalForm.validate() && this.step === 1:
          this.$emit("onSignUpCompleted", this.form);

          break;

        case this.form.employee &&
          this.$refs.employeeForm.validate() &&
          this.step === 2:
          this.$emit("onUserUpdate", this.form);

          break;

        case this.form.customer &&
          this.$refs.customerForm.validate() &&
          this.step === 2:
          this.$emit("onUserUpdate", this.form);
          break;

        case this.form.customer &&
          this.$refs.customerCompanyForm.validate() &&
          this.step === 3:
          this.$emit("onUserUpdate", this.form);

          break;

        default:
          break;
      }
    },
    next() {
      this.step++;

      console.log("step", this.step);
      return (
        (this.form.customer && this.step > 3) ||
        (this.form.employee && this.step > 2)
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    loading(newVal) {
      this.pLoading = newVal;
    },
    value: {
      handler(newVal) {
        console.log("newVal: ", newVal);

        this.form = newVal;
      },
      deep: true,
    },
  },
};
</script>
  
  
  
  
<style lang="scss" scoped>
.sign-in-name {
  display: flex;
  justify-content: center;

  font-weight: 300;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}

.customer-form {
  //   overflow: scroll;
}

.sign-up-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}
</style>