<template>
  <div class="prompt-prefill" v-html="input"></div>
</template>
  
  
  <script>
// import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    input() {
      const parser = new DOMParser();

      // Parse the input text as an HTML document
      const doc = parser.parseFromString(this.value.text, "text/html");

      // Get all <span> elements
      const spanElements = doc.querySelectorAll("span");

      // Replace each <span> element with an <input> element
      spanElements.forEach((span) => {
        const div = document.createElement("div");

        const [id] = span.getAttribute("id").split("p-").reverse();

        const targetParam = this.value.parameters.find(
          (p) => String(p.id) === String(id)
        );

        div.setAttribute("class", "prefill-input-group field");

        const input = document.createElement("input");
        input.setAttribute("class", "form__field");
        input.setAttribute("placeholder", targetParam.name);
        input.setAttribute("name", targetParam.name);
        input.setAttribute("type", "text");
        input.setAttribute("id", span.getAttribute("id"));

        const label = document.createElement("label");
        label.setAttribute("for", span.getAttribute("id"));
        label.setAttribute("class", "form__label");
        const textNode = document.createTextNode(targetParam.name);
        label.appendChild(textNode);

        div.appendChild(input);
        div.appendChild(label);

        span.parentNode.replaceChild(div, span);
      });

      // Get the modified HTML content
      const modifiedHTML = doc.documentElement.outerHTML;

      return modifiedHTML;
    },
  },
  methods: {
    getValues() {
      const parser = new DOMParser();

      // Parse the input text as an HTML document
      const doc = parser.parseFromString(this.input, "text/html");

      // Get all <input> elements
      const inputsElements = doc.querySelectorAll("input");

      const values = [];

      inputsElements.forEach((input) => {
        const [id] = input.getAttribute("id").split("p-").reverse();

        const realInput = document.querySelector(
          `input#${input.getAttribute("id")}`
        );

        values.push({
          id,
          name: input.getAttribute("name"),
          value: realInput.value,
        });
      });

      return values;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.prompt-prefill {
  font-size: 18px;
  font-family: "SourceCodePro";
}
</style>