export class ParamIterable {


    constructor(element, index) {
        this.element = element;
        this.i = index;
    }


    get index() {
        return this.i
    }

    get id() {

        const attribute = this.element.getAttribute("id");

        if (attribute) {
            const [stringId] = attribute.split("p-").reverse();

            return isNaN(Number(stringId)) ? stringId : parseInt(stringId);
        } else
            return this.element.getAttribute("id");
    }

    get key() {
        return this.element.getAttribute('prst-key') || ''
    }

    get type() {
        return this.element.getAttribute('prst-param-type') || ''
    }

    get path() {
        return this.element.getAttribute('prst-param-path') || ''
    }

    get issuer() {
        return this.element.getAttribute('prst-param-issuer') || ''
    }

    get referenceId() {
        return this.element.getAttribute('prst-reference-id') || ''
    }

    get replacement() {
        return this.element.outerHTML;
    }
}




export class ParamsIterator {

    constructor(html, selector) {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(
            html,
            "text/html"
        );
        const spans = parsedDocument.querySelectorAll(
            selector ? selector : "span"
        );

        this.data = [...spans].map((element, index) => new ParamIterable(element, index));
        this.index = 0;
    }


    get length() {
        return this.data.length;
    }

    // Implement the `next()` method of the iterator protocol
    next() {
        if (this.index < this.data.length) {
            return {
                value: this.data[this.index++],
                done: false
            };
        } else {
            return {
                value: undefined,
                done: true
            };
        }
    }

    // Custom method to get the current value
    current() {
        if (this.index >= 0 && this.index < this.data.length) {
            return this.data[this.index];
        }
        return undefined;
    }

    [Symbol.iterator]() {
        return this;
    }
}