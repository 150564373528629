<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row class="pa-4">
      <v-col cols="8" class="switches-row">
        <v-switch
          class="mx-2"
          v-model="form.stars"
          @change="
            (val) => {
              if (val) {
                form.stars = true;
              } else {
                form.like = true;
              }
              $emit('input', form);
            }
          "
          :label="form.stars ? 'Stars Rating' : 'Like/Dislike'"
          color="primary"
          :true-value="true"
          :false-value="false"
          hide-details
        ></v-switch>
        <v-switch
          class="mx-2"
          v-model="form.allowActions"
          :disabled="form.allowComment"
          @change="
            (val) => {
              //   if (form.allowComment) {
              //     form.allowActions = true;
              //   } else {
              //     form.allowActions = val;
              //   }
              $emit('input', form);
            }
          "
          :label="'Display Actions'"
          color="primary"
          :true-value="true"
          :false-value="false"
          hide-details
        ></v-switch>

        <v-switch
          class="mx-2"
          v-model="form.allowComment"
          @change="
            (val) => {
              if (val) {
                form.allowComment = true;
                form.allowActions = true;
              } else {
                form.allowComment = false;
              }
              $emit('input', form);
            }
          "
          :label="'Allow Comment'"
          color="primary"
          :true-value="true"
          :false-value="false"
          hide-details
        ></v-switch>
      </v-col>
      <v-col cols="4" class="colors-row">
        <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="form.color"
              dark
              v-bind="attrs"
              v-on="on"
              class="color-picket-btn ma-4"
              fab
              small
            >
            </v-btn>
          </template>

          <v-card>
            <v-color-picker
              @input="
                (e) => {
                  form.color = e.hex;
                  $emit('input', value);
                }
              "
              :mode="'hexa'"
            ></v-color-picker>
          </v-card>
        </v-menu>
        <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="color-picket-btn ma-4"
              :color="form.starsColor"
              dark
              v-bind="attrs"
              v-on="on"
              fab
              small
            >
            </v-btn>
          </template>

          <v-card>
            <v-color-picker
              @input="
                (e) => {
                  form.starsColor = e.hex;
                  $emit('input', value);
                }
              "
              :mode="'hexa'"
            ></v-color-picker>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <v-slider
      v-if="form.stars"
      v-model="form.size"
      :label="`${form.size}px`"
      min="4"
      max="100"
    ></v-slider>

    <v-row>
      <v-col>
        <HelpFormInput
          v-model="form.titleText"
          label="Title Text"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
      <v-col>
        <HelpFormInput
          v-model="form.contentText"
          label="Content Text"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
    </v-row>

    <v-row v-if="form.stars && form.allowActions">
      <v-col>
        <HelpFormInput
          v-model="form.dismissText"
          label="Dismiss Text"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
      <v-col>
        <HelpFormInput
          v-model="form.saveText"
          label="SaveText"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
    </v-row>

    <v-row v-if="form.allowComment">
      <v-col>
        <HelpFormInput
          v-model="form.commentLabel"
          label="Comment Label"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
      <v-col>
        <HelpFormInput
          v-model="form.commentHelpText"
          label="Comment Help Text"
          :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
          required
          @input="$emit('input', value)"
        ></HelpFormInput>
      </v-col>
    </v-row>
  </v-form>
</template>
              
  <script>
import HelpFormInput from "../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

// import MenuTypesPicker from "../../../../components/wad/atoms/common/menus/MenuTypesPicker.vue";
// import SelectorDefault from "../../../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      types: [
        {
          value: "STRING",
          name: "String",
        },
        {
          value: "NUMBER",
          name: "Number",
        },
      ],

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      categoryRules: [(v) => !!v || "Category is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {},
  components: {
    HelpFormInput,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onColorChanged(e) {
      console.log("onColorChanged: ", e);
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
              
              
  <style lang="scss" scoped>
.param-col {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.type-col {
  display: flex;
  flex-direction: row;
  flex: 0 0 fit-content;
  max-width: 100%;
  align-items: center;
}

.switches-row {
  display: flex;
  flex-direction: row;
}

.colors-row {
  display: flex;
  flex-direction: row;
}
</style>