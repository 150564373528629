<template>
  <v-list-item class="" @click="$emit('click', value)">
    <div class="status-small status">
      <div
        v-if="value.status.code === 'COMPLETED'"
        class="o-circle c-container__circle o-circle__sign--success"
      >
        <div class="o-circle__sign"></div>
      </div>
      <div
        v-else-if="value.status.code === 'FAILED'"
        class="o-circle c-container__circle o-circle__sign--failure"
      >
        <div class="o-circle__sign"></div>
      </div>
      <!-- </v-fade-transition> -->
    </div>

    <v-list-item-content class="ml-4">
      <v-list-item-title>
        <div class="small log-record-prompt-result-text" v-if="value.result">
          {{
            value.result && value.result.result
              ? value.result.result
              : value.result
          }}
        </div>
        <div class="small log-record-prompt-result-text" v-if="value.error">
          {{
            value.error && value.error.error && value.error.error.message
              ? value.error.error.message
              : value.error && value.error.message
              ? value.error.message
              : "-"
          }}
        </div>
      </v-list-item-title>
      <v-list-item-subtitle class="ml-4">
        Completed:
        {{ fromNow(value.end) }}
        <v-icon>mdi-account</v-icon>

        by

        <span v-if="value.user">
          {{ value.user && value.user.email }}
        </span>
        <span v-else-if="value.api">
          API : prst-key-{{ last8(value.api.key) }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
  
  
<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      formatDuration: FormatterHelper.timeDuration,
      last8: FormatterHelper.last8,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
</style>
    
    
    