
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col lg="3" md="3" sm="6" xs="12">
          <v-card class="mx-auto dashboard-card">
            <v-list-item dark class="primary-gradient dashboard-card-title" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Total Generations
                </v-list-item-title>
                <v-list-item-subtitle>Pats month</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text class="dashboard-card-content">
              <v-row align="center">
                <v-col class="text-h4"> {{ generationsCount }} </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text> Full Report </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" md="3" sm="6" xs="12">
          <v-card class="mx-auto dashboard-card">
            <v-list-item dark class="primary-gradient dashboard-card-title" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Monthly Spent
                </v-list-item-title>
                <v-list-item-subtitle>Past 30 days</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text class="dashboard-card-content">
              <PieChartVue class="pa-2" v-if="monthlySpent" :value="monthlySpent" :height="120"></PieChartVue>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text> Full Report </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" md="3" sm="6" xs="12">
          <v-card class="mx-auto dashboard-card">
            <v-list-item dark class="primary-gradient dashboard-card-title" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5"> Errors </v-list-item-title>
                <v-list-item-subtitle>Last 14 days</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text class="dashboard-card-content">
              <v-row align="center">
                <v-col
                  class="text-h4 primary--text align-center d-flex justify-center"
                  v-if="errorsCount === 0"
                >
                  {{ errorsCount || 0 }}
                </v-col>
                <v-col
                  class="text-h4 warning--text mx-auto align-center d-flex justify-center"
                  v-if="errorsCount > 0 && errorsCount <= 10"
                >
                  {{ errorsCount || 0 }}
                </v-col>
                <v-col
                  class="text-h4 error--text mx-auto align-center d-flex justify-center"
                  v-if="errorsCount > 10"
                >
                  {{ errorsCount || 0 }}
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text @click="$router.push('/executions')">
                Full Report
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" md="3" sm="6" xs="12">
          <v-card class="mx-auto dashboard-card">
            <v-list-item dark class="primary-gradient dashboard-card-title" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Sentiment Pulse
                </v-list-item-title>
                <v-list-item-subtitle>Last 14 days</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text class="align-center d-flex dashboard-card-content">
              <v-icon
                v-if="sentimentalScore >= 0.75"
                color="success"
                class="mx-auto"
                :size="120"
                >mdi-emoticon-outline</v-icon
              >
              <v-icon
                v-else-if="sentimentalScore >= 0.4 && sentimentalScore < 0.75"
                color="yellow darken-3"
                class="mx-auto"
                :size="120"
                >mdi-emoticon-neutral-outline</v-icon
              >
              <v-icon
                v-else-if="sentimentalScore < 0.4"
                color="error"
                class="mx-auto"
                :size="120"
                >mdi-emoticon-dead-outline</v-icon
              >
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text @click="$router.push('/feedbacks')">
                Full Report
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="8" xl="8" md="6">
          <!-- Generations by Day -->
          <v-card>
            <PerDayChart
              v-if="promptByDay && Object.keys(promptByDay).length"
              :value="promptByDay"
            ></PerDayChart>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="6">
          <!-- RECENT Runs -->
          <RecentGenerations :value="recentExecutions"></RecentGenerations>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
<script>
import { AnalyticsAPIInstance } from "../../components/api";
import PerDayChart from "../../components/wad/molecules/dashboard/PerDayChart.vue";
import PieChartVue from "../../components/wad/molecules/dashboard/PieChart.vue";
import RecentGenerations from "./components/RecentGenerations.vue";

export default {
  data() {
    return {
      recentExecutions: [],
      promptByDay: {},
      sentimentalScore: 0.75,
      errorsCount: 0,
      generationsCount: 0,
      monthlySpent: undefined,
    };
  },
  components: {
    PerDayChart,
    PieChartVue,
    RecentGenerations,
  },
  created() {
    this.getRecentExecutions();
    this.getPromptByDay();
    this.getAvgSentimentalScore();
    this.getRecentErrorsCount();
    this.totalGenerationsRecent();
    this.getMonthlySpent();
  },
  methods: {
    async getAvgSentimentalScore() {
      const { avg_sentimental_score } =
        await AnalyticsAPIInstance.recentSentimentalScore();

      this.sentimentalScore = avg_sentimental_score;
    },

    async getMonthlySpent() {
      const monthlySpent = await AnalyticsAPIInstance.monthlySpent();

      this.monthlySpent = monthlySpent;
    },

    async totalGenerationsRecent() {
      const { total_generations_count } =
        await AnalyticsAPIInstance.totalGenerationsRecent();

      this.generationsCount = total_generations_count;
    },
    async getRecentErrorsCount() {
      const { recent_errors_count } =
        await AnalyticsAPIInstance.recentErrorsCount();

      this.errorsCount = recent_errors_count;
    },
    async getRecentExecutions() {
      const { items } = await AnalyticsAPIInstance.recentExecutions();
      this.recentExecutions = items;
    },

    async getPromptByDay() {
      this.promptByDay = await AnalyticsAPIInstance.promptByDay();
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .dashboard-card-title {
    flex-grow: 0;
    max-height: 82px;
  }

  .dashboard-card-content {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>