<template>
    <v-tooltip v-bind="tooltipAttrs">
      <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
        <v-menu content-class="selector-menu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="value"
              v-bind="{
                ...menuAttrs,
                ...attrs,
              }"
              v-on="{
                ...menuOn,
                ...on,
              }"
              @click="$emit('click', $event)"
              class="ma-2"
              :color="
                value.code === 'DRAFT'
                  ? 'pink'
                  : value.code === 'ACTIVE'
                  ? 'primary'
                  : 'grey'
              "
              label
              text-color="white"
            >
              <v-icon left> mdi-label </v-icon>
              {{ value.name }}
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in pItems"
              :key="index"
              two-line
              @click="$emit('input', item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.description
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <span>{{ helpText }}</span>
    </v-tooltip>
  </template>
          
          
  <script>
  import { PromptAPIInstance } from "../../../../api";
  
  export default {
    props: {
      value: {},
      items: {
        type: Array,
        default: () => [],
      },
      helpText: {
        type: String,
        default: "",
      },
      timeout: {
        type: Number,
        default: 400,
      },
      tooltipAttrs: {
        type: Object,
        default: () => ({
          bottom: true,
        }),
      },
      autoload: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        api: PromptAPIInstance,
        pTimeout: undefined,
        pItems: this.items,
  
        // Autoload
        pagination: {
          page: 1,
          pageSize: 50,
        },
      };
    },
    mounted() {
      if (this.autoload) this.getList();
    },
    methods: {
      async getList() {
        const { pagination, items } = await this.api.statuses(this.pagination);
  
        this.pagination = pagination;
        this.pItems = items;
      },
      remove(item) {
        const index = this.friends.indexOf(item.name);
        if (index >= 0) this.friends.splice(index, 1);
      },
    },
  };
  </script>
          
          
  <style lang="scss" scoped>
  </style>