
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" @click="onCreate" icon>
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>
          Search for knowledge records: Simply type in the search bar to find
          the
        </span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for records by content'"
      ></HelpFormInput>
    </v-toolbar>

    <v-card-text class="page-content">
      <v-row class="my-2">
        <v-col cols="12">
          <v-card class="">
            <v-card-title class="pa-0">
              <v-toolbar dense class="mb-8 prompt-debug-toolbar">
                <v-row class="main-config-row">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        @click="tab = 'tab-1'"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        text
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Add Materials: Navigate to Config tab where your are able
                      to add more context for your knowledge base.</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        @click="tab = 'tab-2'"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        text
                      >
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Test: Ask your ready-made bot for anything related to your
                      knowledge base.</span
                    >
                  </v-tooltip>

                  <v-spacer></v-spacer>
                  <v-divider vertical class="mr-7"></v-divider>

                  <MenuPrompt
                    class="connector-selector"
                    :helpText="'Pick the desired connector to use'"
                    :prompt="knowledgeBaseConfig.defaultPrompt"
                    :version="knowledgeBaseConfig.defaultPromptVersion"
                    @onPromptChanged="
                      (pr) => {
                        knowledgeBaseConfig.defaultPrompt = pr;
                        knowledgeBaseConfig.defaultPromptVersion = undefined;
                        knowledgeBaseConfig.default_prompt_version_id = null;
                        updateConfig();
                      }
                    "
                    @onPromptVersionChanged="
                      (ver) => {
                        knowledgeBaseConfig.defaultPromptVersion = ver;
                        updateConfig();
                      }
                    "
                  ></MenuPrompt>

                  <v-divider vertical class="mr-7"></v-divider>

                  <MenuConnector
                    class="connector-selector"
                    :helpText="'Pick the desired connector to use'"
                    :connector="knowledgeBaseConfig.defaultConnector"
                    :version="knowledgeBaseConfig.defaultConnectorVersion"
                    @onConnectorChanged="
                      (conn) => {
                        knowledgeBaseConfig.defaultConnector = conn;
                        knowledgeBaseConfig.defaultConnectorVersion = undefined;
                        knowledgeBaseConfig.default_connector_version_id = null;
                        updateConfig();
                      }
                    "
                    @onConnectorVersionChanged="
                      (ver) => {
                        knowledgeBaseConfig.defaultConnectorVersion = ver;
                        updateConfig();
                      }
                    "
                  ></MenuConnector>
                </v-row>
                <v-slide-x-transition>
                  <v-row
                    v-if="
                      knowledgeBaseConfig.defaultConnectorVersion &&
                      knowledgeBaseConfig.defaultConnectorVersion.proxyParams
                    "
                    class="connector-proxy-params-row"
                  >
                    <v-slide-group show-arrows>
                      <v-slide-item
                        v-for="(param, i) in knowledgeBaseConfig
                          .defaultConnectorVersion.proxyParams"
                        :key="i"
                      >
                        <!-- v-slot="{ active, toggle }" -->

                        <div class="mx-2">
                          <ParamPicker
                            v-model="
                              knowledgeBaseConfig.defaultConnectorVersion
                                .proxyParams[i]
                            "
                            :help-text="'Pick Param Values'"
                          ></ParamPicker>
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-row>
                </v-slide-x-transition>
                <!-- </v-btn-toggle> -->
              </v-toolbar>
            </v-card-title>

            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-1'">
                <v-card-text>
                  <HelpFormInput
                    v-model="newRecord"
                    class="pa-"
                    :label="'New Knowledge Record'"
                    textarea
                    :rows="4"
                    counter
                  ></HelpFormInput>
                </v-card-text>
                <v-card-actions>
                  <v-spacer> </v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="onCreate"
                    :loading="api.Records.loading"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <TestChatBot></TestChatBot>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-slide-y-transition leave-absolute>
        <v-slide-y-transition
          v-if="items && items.length"
          class="py-0 row"
          group
          tag="div"
        >
          <v-col cols="12" v-for="item in items" :key="item.id">
            <KnowledgeRecord
              :value="item"
              @click="openDetails"
              @onRemove="
                () => {
                  removeCandidate = item;
                  removeDialog = true;
                }
              "
            ></KnowledgeRecord>
          </v-col>
        </v-slide-y-transition>
        <v-alert
          v-else
          icon="mdi-shield-lock-outline"
          prominent
          text
          type="info"
        >
          Currently, there are no prompts available. Please consider creating
          new prompts to get started. If you believe this is an error, kindly
          contact our support team for further assistance.
        </v-alert>
      </v-slide-y-transition>
      <div class="text-center mt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="getList"
        ></v-pagination>
      </div>
    </v-card-text>
    <KnowledgeRecordDeleteDialog
      :value="removeDialog"
      @onCancel="
        () => {
          removeCandidate = undefined;
          removeDialog = false;
        }
      "
      @onRemove="onRemove"
    ></KnowledgeRecordDeleteDialog>
  </v-card>
</template>
      
    <script>
import { KnowledgeAPIInstance } from "../../components/api";
import KnowledgeRecordDeleteDialog from "./components/KnowledgeRecordDeleteDialog.vue";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import KnowledgeRecord from "./components/KnowledgeRecord.vue";
import ParamPicker from "../../components/wad/atoms/common/pickers/ParamPicker.vue";
import MenuConnector from "../../components/wad/atoms/common/menus/MenuConnector.vue";
import TestChatBot from "./components/TestChatBot.vue";
import MenuPrompt from "../../components/wad/atoms/common/menus/MenuPrompt.vue";

export default {
  data() {
    return {
      api: KnowledgeAPIInstance,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
        pages: 0,
      },
      filter: {},
      items: [],
      tab: "tab-1",
      newRecord: "",
      removeDialog: false,
      removeCandidate: undefined,
      knowledgeBaseConfig: {},
      updateTimeout: undefined,
    };
  },
  components: {
    KnowledgeRecord,
    KnowledgeRecordDeleteDialog,
    HelpFormInput,
    ParamPicker,
    MenuConnector,
    MenuPrompt,
    TestChatBot,
  },
  async created() {
    this.getList();
    this.getConfig();
  },
  methods: {
    openDetails(value) {
      this.$router.push(`/prompts/${value.id}`);
    },
    async getConfig() {
      this.knowledgeBaseConfig = await this.api.get();
    },
    async getList() {
      const { items, pagination } = await this.api.Records.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      await this.api.Records.create(
        {
          text: this.newRecord,
        },
        {
          toast: {
            message: "New Prompt Created",
          },
        }
      );
      this.getList();
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Prompt Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },

    async updateConfig() {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);

      this.updateTimeout = setTimeout(async () => {
        await this.api.update(this.knowledgeBaseConfig, {
          // toast: {
          //   message: "Changes applied",
          // },
        });
      }, 200);
    },
  },
};
</script>