var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',_vm._b({attrs:{"value":_vm.value,"items":_vm.pServices,"item-value":"id","item-text":"name","clearable":"","cache-items":"","search-input":_vm.search,"chips":"","color":_vm.$attrs.dark || _vm.$attrs.dark === '' ? 'white' : 'accent'},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"18","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help ")])]}}])},'v-tooltip',_vm.tooltipAttrs,false),[_c('span',[_vm._v(_vm._s(_vm.helpText))])])]},proxy:true},{key:"selection",fn:function(data){return [(data.item)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":data.item.status.code === 'DRAFT'
          ? 'pink'
          : data.item.status.code === 'ACTIVE'
          ? 'primary'
          : 'grey',"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(data.item.v)+" ")],1):_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.v)+" ")]),_c('v-list-item-subtitle',[(data.item)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":data.item.status.code === 'DRAFT'
              ? 'pink'
              : data.item.status.code === 'ACTIVE'
              ? 'primary'
              : 'grey',"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(data.item.status.name)+" ")],1):_vm._e()],1)],1)]}}])},'v-autocomplete',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }