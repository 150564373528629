import {
    CommonAPI
} from "./common/Common.api";
import {
    UserAPI
} from "./users/User.api";
import {
    AuthAPI
} from "./Auth.api";
import {
    PollAPI
} from "./poll/Poll.api";
import {
    AudienceAPI
} from "./Audience.api";
import {
    PromptAPI
} from "./prompts/Prompt.api";
import {
    ConnectorAPI
} from "./connectors/Connector.api";
import {
    LogsAPI
} from "./logs/Logs.api";
import {
    AnalyticsAPI
} from "./Analytics.api";
import {
    PricingModelAPI
} from './pricing-models/PricingModel.api'
import {
    SupportAPI
} from "./Support.api";
import {
    ExecuteTasksAPI
} from "./ExecuteTasks.api";
import {
    FeedbackAPI
} from "./feedback/Feedback.api";
import {
    KnowledgeAPI
} from "./knowledgebase/Knowledge.api";
import { APICredentialsAPI } from "./api-credentials/APICredentials.api";



export const UserAPIInstance = new UserAPI(process.env.VUE_APP_API)
export const CommonAPIInstance = new CommonAPI(process.env.VUE_APP_API)
export const AuthAPIInstance = new AuthAPI(process.env.VUE_APP_API)
export const PollAPIInstance = new PollAPI(process.env.VUE_APP_API)
export const AudienceAPIInstance = new AudienceAPI(process.env.VUE_APP_API)
export const PromptAPIInstance = new PromptAPI(process.env.VUE_APP_API)
export const ConnectorAPIInstance = new ConnectorAPI(process.env.VUE_APP_API)
export const LogsAPIInstance = new LogsAPI(process.env.VUE_APP_API)
export const AnalyticsAPIInstance = new AnalyticsAPI(process.env.VUE_APP_API)
export const PricingModelAPIInstance = new PricingModelAPI(process.env.VUE_APP_API)
export const SupportAPIInstance = new SupportAPI(process.env.VUE_APP_API)
export const ExecuteTasksAPIInstance = new ExecuteTasksAPI(process.env.VUE_APP_API)
export const FeedbackAPIInstance = new FeedbackAPI(process.env.VUE_APP_API)
export const KnowledgeAPIInstance = new KnowledgeAPI(process.env.VUE_APP_API)
export const APICredentialsAPIInstance = new APICredentialsAPI(process.env.VUE_APP_API)