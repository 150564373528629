<template>
  <v-card
    class="elevation-0 transparent"
    :class="{
      'page-card': !nested,
    }"
  >
    <v-toolbar v-if="!nested" class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on"  icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>
          Search for feedbacks: Simply type in the search bar to find the
          feedbacks.
        </span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for feedbacks by comment'"
      ></HelpFormInput>
    </v-toolbar>

    <v-card-text
      :class="{
        'page-content': !nested,
      }"
    >
      <v-row v-if="stats">
        <v-col>
          <v-card class="mx-auto">
            <v-list-item dark class="primary-gradient" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Feedback Embedded
                </v-list-item-title>
                <!-- <v-list-item-subtitle
                  >Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle
                > -->
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <FeedbackConfig></FeedbackConfig>
            </v-card-text>

            <v-divider></v-divider>

            <!-- <v-card-actions>
              <v-btn text> Full Report </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto">
            <v-list-item dark class="primary-gradient" two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Feedback Filling
                </v-list-item-title>
                <v-list-item-subtitle>Last 14 days</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text class="align-center d-flex">
              <v-icon
                v-if="sentimentalScore >= 0.75"
                color="success"
                class="mx-auto"
                :size="80"
                >mdi-emoticon-outline</v-icon
              >
              <v-icon
                v-else-if="sentimentalScore >= 0.4 && sentimentalScore < 0.75"
                color="yellow darken-3"
                class="mx-auto"
                :size="80"
                >mdi-emoticon-neutral-outline</v-icon
              >
              <v-icon
                v-else-if="sentimentalScore < 0.4"
                color="error"
                class="mx-auto"
                :size="80"
                >mdi-emoticon-dead-outline</v-icon
              >
            </v-card-text>

            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>

      <v-list class="content log-list transparent" three-line>
        <v-slide-y-transition class="py-0 px-0" group tag="div" leave-absolute>
          <template v-if="items && items.length">
            <FeedbackItem
              v-for="task in items"
              :key="task.id"
              :value="task"
              class="log-item"
            ></FeedbackItem>
          </template>
          <v-alert key="empty-alert" v-else prominent text type="info">
            No recent feedbacks found...
          </v-alert>
        </v-slide-y-transition>
      </v-list>
      <div class="text-center mt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="getList"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>
        
      <script>
import {
  FeedbackAPIInstance,
  AnalyticsAPIInstance,
} from "../../components/api";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import { MOCKED_FEEDBACKS } from "../../constants/mocked.constants";
import FeedbackItem from "./components/FeedbackItem.vue";
import FeedbackConfig from "../Prompts/components/FeedbackConfig.vue";

export default {
  props: {
    promptVersionId: {},
    stats: {
      type: Boolean,
      default: true,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    mocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: FeedbackAPIInstance,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
        pages: 0,
      },
      filter: {
        promptVersionId: this.promptVersionId
          ? this.promptVersionId
          : undefined,
      },
      items: [],
      removeDialog: false,
      removeCandidate: undefined,
      sentimentalScore: 0.75,
    };
  },
  components: {
    FeedbackItem,
    HelpFormInput,
    FeedbackConfig,
  },
  async created() {
    this.getList();
    if (this.stats) this.getAvgSentimentalScore();
  },
  methods: {
    async getAvgSentimentalScore() {
      const { avg_sentimental_score } =
        await AnalyticsAPIInstance.recentSentimentalScore();

      this.sentimentalScore = avg_sentimental_score;
    },
    openDetails(value) {
      this.$router.push(`/prompts/${value.id}`);
    },
    async getList() {
      if (!this.mocked) {
        const { items, pagination } = await this.api.list(
          this.pagination,
          this.filter
        );
        this.items = items;
        this.pagination = pagination;
        return items;
      } else {
        const { items, pagination } = MOCKED_FEEDBACKS;
        this.items = items;
        this.pagination = pagination;
        return items;
      }
    },
    async onCreate() {
      await this.api.create(
        {
          name: "New Prompt",
        },
        {
          toast: {
            message: "New Prompt Created",
          },
        }
      );
      this.getList();
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Prompt Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.content {
  // margin-left: 8px;
  padding: 0; // margin-right: 8px;

  .log-item {
    margin: 0;
    padding: 10px;
  }
}
</style>
  
  