import {
    API
} from '../Api.class';

export class FeedbackAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/feedbacks', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/feedbacks/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/feedbacks', body, {
            meta
        });
    }


    async publicCreate(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/public/feedbacks', body, {
            meta
        });
    }


    async delete(item, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/feedbacks/${item.id}`, {
            meta
        });
    }
}