import {
    API
} from '../Api.class';

export class PollAnalyticsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }

    async clicksByDay(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/clicks/day`, {
            meta
        });

    }


    async answers(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/answers`, {
            meta
        });

    }

    async answersByDay(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/answers/day`, {
            meta
        });

    }

    async answersByDevice(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/answers/device`, {
            meta
        });

    }

    async answersByBrowser(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/answers/browser`, {
            meta
        });

    }

    async avgFraudScore(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/avg-fraud-score`, {
            meta
        });

    }


    async devices(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/devices`, {
            meta
        });

    }


    async browsers(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/browsers`, {
            meta
        });

    }

    async os(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}/analytics/os`, {
            meta
        });
    }

}