var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-0 transparent page-card"},[_c('v-toolbar',{staticClass:"page-toolbar primary-gradient"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":""},on:{"click":_vm.onCreate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Create a New Pricing Rule: Simply click on this button to initiate the process of generating a fresh Pricing Rule. This allows you to start a new writing session or explore different ideas with ease.")])]),_c('v-spacer'),_c('HelpFormInput',{staticClass:"mr-4",attrs:{"dark":"","hide-details":"","label":'Search',"color":"white","timeout":300,"help-text":'Search for Pricing Rules by name'},on:{"input":_vm.getList},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1),_c('v-card-text',{staticClass:"page-content"},[_c('v-card',{staticClass:"transparent elevation-0 recent-card"},[_c('v-card-title',{staticClass:"primary-gradient mb-2"},[_vm._v(" Recent Rules ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-slide-y-transition',{attrs:{"leave-absolute":""}},[(_vm.items && _vm.items.length)?_c('v-slide-y-transition',{staticClass:"pa-0 row recent-rules",attrs:{"group":"","tag":"div"}},_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"4","lg":"3","md":"4","sm":"4"}},[_c('PricingModelItem',{attrs:{"value":item},on:{"click":_vm.openDetails,"input":_vm.updatePricingModel,"onRemove":() => {
                    _vm.removeCandidate = item;
                    _vm.removeDialog = true;
                  }}})],1)}),1):_c('v-alert',{attrs:{"icon":"mdi-shield-lock-outline","prominent":"","text":"","type":"info"}},[_vm._v(" Currently, there are no Pricing Rules available. Please consider creating new Pricing Rules to get started. If you believe this is an error, kindly contact our support team for further assistance. ")])],1),_c('div',{staticClass:"text-center mt-4"},[_c('v-pagination',{attrs:{"length":_vm.pagination.pages,"total-visible":7},on:{"input":_vm.getList},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)],1),_c('PricingModelDeleteDialog',{attrs:{"value":_vm.removeDialog},on:{"onCancel":() => {
        _vm.removeCandidate = undefined;
        _vm.removeDialog = false;
      },"onRemove":_vm.onRemove}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }