

<template>
  <div class="reset-password-form-container pt-0 mt-0">
    <v-img class="logo" src="/logo.png"> </v-img>
    <v-fade-transition>
      <v-card class="transparent elevation-0">
        <h2 class="form-name">RESET PASSWORD</h2>
        <v-card-text>
          <ResetPasswordForm ref="form" v-model="form"></ResetPasswordForm>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onPasswordReset">RESET PASSWORD</v-btn>
        </v-card-actions>
      </v-card>
    </v-fade-transition>
  </div>
</template>
  
  
  
  <script>
import { AuthAPIInstance } from "../../components/api";
import ResetPasswordForm from "./components/forms/ResetPasswordForm.vue";

export default {
  name: "SignUp",
  data: () => ({
    dark: true,
    user: { profile: {} },
    token: "",
    form: {
      old_password: "",
      new_password: "",
      repeat_password: "",
    },
  }),
  async created() {
    const { token } = this.$route.query;

    // redirect to sign in
    if (!token) this.$router.push("/dashboard");

    // this.user = await this.$store.dispatch('MainStore/getUser');

    this.token = token;

    try {
      const { user } = await AuthAPIInstance.validateResetPassword(token);
      this.user = user;
    } catch (error) {
      console.log("ERROR: ", error);
      this.$router.push("/dashboard");
    }
  },
  components: {
    ResetPasswordForm,
  },
  methods: {
    async onPasswordReset(form) {
      console.log("onPasswordReset form: ", form);
      const valid = this.$refs.form.validate();

      if (valid)
        await this.$store.dispatch("MainStore/resetPassword", {
          token: this.token,
          ...this.form,
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.reset-password-form-container {
  // background: linear-gradient(to bottom, #000, transparent);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  max-width: 450px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  border: 2px solid #00695c;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background-size: cover;

  .logo {
    margin-top: 40px;
    width: 200px;
    height: 180px;
    margin: 0 auto;
    margin-top: 80px;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  @media (max-width: 864px) {
    width: 100%;
  }

  .form-name {
    display: flex;
    justify-content: center;

    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
}
</style>
  
  
  
  