import {
    API
} from '../Api.class';
import {
    PollAnalyticsAPI
} from './PollAnalyitcs.api';

export class PollAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)


        this.Analytics = new PollAnalyticsAPI(baseUrl);
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/polls', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/polls/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/polls', body, {
            meta
        });
    }

    async getLink(poll, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/polls/${poll.id}/link`, {}, {
            meta
        });
    }

    async getPublicPoll(short_token, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/public/poll`, {
            short_token
        }, {
            meta
        });
    }

    async saveResult(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/public/poll/answer`, body, {
            meta
        });
    }


    async update(poll, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/polls/${poll.id}`, poll, {
            meta
        });
    }

    async delete(poll, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/polls/${poll.id}`, {
            meta
        });
    }
}