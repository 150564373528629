<template>
  <div
    :id="id"
    ref="counter"
    v-bind="$attrs"
    @input="update"
    @focus="focus"
    @blur="blur"
    v-html="valueText"
    @keyup.ctrl.delete="$emit('delete-row')"
    @keyup.enter="$emit('onEnter')"
    @contextmenu="(e) => $emit('contextmenu', e)"
    @click.stop.prevent="(e) => $emit('click', e)"
    class="html-input"
    contenteditable
    :style="{
      height: rows * 32 + 'px',
    }"
  ></div>
</template>


<script>
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    id: {
      default: uuidv4(),
    },
    value: {},
    rows: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      focusIn: false,
      valueText: this.value,
    };
  },
  mounted() {
    const element = document.getElementById(this.id);

    element.addEventListener("paste", (event) => {
      event.preventDefault();

      // Get the plain text from the clipboard
      const plainText = event.clipboardData.getData("text/plain");

      console.log('plainText: ', plainText)

      // Insert the plain text into the contenteditable element
      document.execCommand("insertHTML", false, plainText);
    });
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.srcElement.innerHTML);
    },
    update(e) {
      this.localValue = e.target.innerHTML;
    },
    focus() {
      this.focusIn = true;
    },
    blur() {
      this.focusIn = false;
    },
  },
  watch: {
    localValue(newVal) {
      if (!this.focusIn) {
        this.valueText = newVal;
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.html-input {
  -webkit-appearance: textarea;
  background-color: white;
  border-bottom: 1px solid;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  -webkit-box-orient: vertical;
  resize: auto;
  cursor: auto;
  padding: 2px;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0em;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: -webkit-auto;
  font-size: 18px;
  font-family: "SourceCodePro";

  width: 100%;
  height: 100%;

  &:hover {
    border-bottom: 1px solid;
  }

  &:focus-visible {
    border: none;

    border-bottom: 1px solid;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}
</style>