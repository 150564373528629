<template>
  <v-row class="connector-http-selector">
    <v-col cols="3" class="pa-0 ma-0">
      <SelectorDefault
        outlined
        :items="methods"
        :disabled="value.skip"
        v-model="value.method"
        label="Method"
        @input="$emit('input', value)"
        class="method"
        hide-details
      ></SelectorDefault>
    </v-col>
    <v-col cols="9" class="pa-0 ma-0">
      <HelpFormInput
        class="url"
        outlined
        :disabled="value.skip"
        :label="'API URL'"
        :rules="urlRules"
        v-model="value.url"
        :helpText="'Enter the URL of the remote HTTP API endpoint. Make sure to include the necessary protocol (e.g., https://) and any required path or query parameters.'"
        @input="$emit('input', value)"
        hide-details
      ></HelpFormInput>
    </v-col>
  </v-row>
</template>
    
<script>
import SelectorDefault from "../../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    availableParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      methods: [
        {
          value: "post",
          name: "POST",
        },
        {
          value: "get",
          name: "GET",
        },
        {
          value: "put",
          name: "PUT",
        },
        {
          value: "delete",
          name: "DELETE",
        },
      ],

      urlRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "URL should be a valid link",
      ],
    };
  },
  components: {
    HelpFormInput,
    SelectorDefault,
  },
  computed: {},

  methods: {},
};
</script>
    
<style lang="scss" >
.connector-http-selector {
  padding: 12px;
  padding-top: 36px;
  position: relative;

  .method {
    fieldset {
      border-radius: 8px 0px 0px 8px;
      border-right: 0px !important;
    }
  }
  .url {
    height: 100%;
    .v-input__control {
      height: 100%;
      .v-input__slot {
        height: 100%;
      }
    }

    fieldset {
      border-radius: 0px 8px 8px 0px;
    }
  }
}
</style>