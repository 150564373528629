<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">New Support Request</span>
        </v-card-title>
        <v-card-text>
          <NewSupportTicketForm
            ref="form"
            v-model="ticket"
          ></NewSupportTicketForm>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('onClose')">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="onCreate"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import NewSupportTicketForm from "./forms/NewSupportTicketForm.vue";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ticket: {},
    };
  },
  components: {
    NewSupportTicketForm,
  },
  methods: {
    onCreate() {
      const valid = this.$refs.form.validate();

      if (valid) this.$emit("onCreate", this.ticket);
    },
  },
};
</script>