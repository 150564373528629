<template>
  <v-card class="elevation-0 transparent">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Default Value</th>
            <th class="text-left">Type</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="param in value"
            :key="param.id"
            @mouseenter="$emit('mouseenter', param)"
            @mouseleave="$emit('mouseleave', param)"
          >
            <td>
              <HelpFormInput
                v-model="param.name"
                :helpText="'Specify the name of the property as it should appear in the JSON object when sending API requests. Ensure the name is accurately defined and follows the required format for seamless integration and compatibility with the API endpoints. Use descriptive and meaningful names to enhance data understanding and facilitate smooth data processing.'"
                required
                @input="
                  () => {
                    $emit('input', value);
                    $emit('onNameChanged', param);
                  }
                "
              ></HelpFormInput>
            </td>
            <td>
              <HelpFormInput
                v-model="param.default_value"
                :helpText="'Specify the initial value that the property should have if no explicit value is provided. This value serves as a fallback or a baseline for the property, ensuring consistent behavior in cases where a specific value is not assigned. Choose a default value that aligns with the expected data type and intended functionality of the property to achieve desired outcomes.'"
                required
                @input="$emit('input', value)"
              ></HelpFormInput>
            </td>
            <td>
              <MenuTypesPicker
                :types="['string', 'number']"
                v-model="param.type"
                @input="$emit('input', value)"
                :helpText="'Specify the data type of the parameter, indicating whether it should be treated as a string or a number. Choosing the correct parameter type is essential for ensuring proper data handling and validation. If the parameter expects textual information, select \'string\'; if it requires numerical values, select \'number\'. This selection helps maintain data integrity and facilitates accurate processing within the system.'"
              >
              </MenuTypesPicker>
            </td>
            <td>
              <v-btn @click="$emit('onRemove', param)" color="error" icon>
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- <v-tabs v-model="tab" vertical>
      <v-slide-y-transition class="py-0" group tag="div">
        <v-tab
          class="parameter-tabs-headers"
          v-for="param in value"
          :key="param.id"
         
          link
          :href="`#tab_${param.id}`"
        >
          {{ cutString(param.name, 12) }}
        </v-tab>
      </v-slide-y-transition>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(param, i) in value"
          :key="param.id"
          @mouseenter="$emit('mouseenter', param)"
          @mouseleave="$emit('mouseleave', param)"
          :value="`tab_${param.id}`"
        >
          <v-card flat>
            <v-card-text>
              <ParamForm
                @input="$emit('input', value)"
                @onNameChanged="$emit('onNameChanged', param)"
                @onRemove="$emit('onRemove', param)"
                v-if="param"
                v-model="value[i]"
              ></ParamForm>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs> -->
  </v-card>
</template>
  
  
<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import HelpFormInput from "../../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import MenuTypesPicker from "../../../components/wad/atoms/common/menus/MenuTypesPicker.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: null,
    },
  },
  components: {
    HelpFormInput,
    MenuTypesPicker,
  },
  data() {
    return {
      showMenu: false,
      x: 0,
      y: 0,
      tab: null,
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3],
      cutString: FormatterHelper.cutString,
    };
  },
  watch: {
    selected(newVal) {
      console.log("newVal: ", newVal);

      this.tab = `tab_${newVal}`;
    },
  },
};
</script>


<style lang="scss" scoped>
.parameter-tabs-headers {
  width: 150px;
}
</style>
  
  
  