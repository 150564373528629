import {
    API
} from './Api.class';

export class AudienceAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async ages(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/audience/categories/ages', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }


    async genders(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/audience/categories/genders', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async parentInterests(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/audience/categories/interests', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async interests(id, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/audience/categories/interests/${id}/children`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async sources(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/audience/categories/sources', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }


}