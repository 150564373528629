import {
    API
} from '../Api.class';


export class CommonAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async timezones(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/common/timezones', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async countries(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/common/countries', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }
}