<template>
    <v-autocomplete
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event)"
      :items="pServices"
      item-value="id"
      item-text="name"
      clearable
      cache-items
      :search-input.sync="search"
      chips
      color="primary"
    >
      <template v-slot:append-outer>
        <v-tooltip v-bind="tooltipAttrs">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
              mdi-help
            </v-icon>
          </template>
          <span>{{ helpText }}</span>
        </v-tooltip>
      </template>
  
      <template v-slot:selection="data">
        <v-chip color="secondary" label v-bind="data.attrs">
          <v-icon :color="data.item.color ? data.item.color : 'primary'">{{
            data.item.icon
          }}</v-icon>
          <span class="selection">
            {{ data.item.name }}
          </span>
        </v-chip>
      </template>
  
      <template v-slot:item="data">
        <v-list-item-icon v-if="data.item.icon">
          <v-icon
            :color="data.item.color ? data.item.color : 'primary'"
            >{{ data.item.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle
          class="description"
            v-if="data.item.description"
            v-html="data.item.description"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </template>
      
      
      <script>
  import { SupportAPIInstance } from "../../../../api";
  
  export default {
    props: {
      value: {},
      services: {
        type: Array,
        default: () => [],
      },
      helpText: {
        type: String,
        default: "",
      },
      timeout: {
        type: Number,
        default: 400,
      },
      tooltipAttrs: {
        type: Object,
        default: () => ({
          bottom: true,
        }),
      },
      autoload: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        api: SupportAPIInstance,
        search: "",
        pTimeout: undefined,
        pServices: this.services,
  
        // Autoload
        pagination: {
          page: 1,
          pageSize: 50,
        },
      };
    },
    mounted() {
      if (this.autoload) this.getList();
    },
    methods: {
      async getList(search) {
        const { pagination, items } = await this.api.categories(this.pagination, {
          search: search ? search : this.search,
        });
  
        this.pagination = pagination;
        this.pServices = items;
      },
      remove(item) {
        const index = this.friends.indexOf(item.name);
        if (index >= 0) this.friends.splice(index, 1);
      },
    },
    watch: {
      search(newVal) {
        if (this.pTimeout) clearTimeout(this.pTimeout);
  
        this.pTimeout = setTimeout(() => {
          if (this.autoload) this.getList(newVal);
          this.$emit("onSearch", newVal);
        }, this.timeout);
      },
    },
  };
  </script>
      
      
<style lang="scss" scoped>
  .v-input__control {
    div {
      background: transparent !important;
    }
  }
  .v-input__slot {
    background: transparent;
  }
  
  .selection {
    font-size: 22px;
    margin-top: 4px;
    margin-left: 4px;
    color: black;
  }
  .description{
    max-width: 400px;
    white-space: break-spaces;
  }
  </style>