
<template>
  <v-card class="elevation-0 transparent page-card">
    <v-toolbar class="page-toolbar primary-gradient">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" @click="onCreate" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>
          Create a New Pricing Rule: Simply click on this button to initiate the
          process of generating a fresh Pricing Rule. This allows you to start a
          new writing session or explore different ideas with ease.</span
        >
      </v-tooltip>

      <v-spacer></v-spacer>

      <HelpFormInput
        dark
        hide-details
        class="mr-4"
        :label="'Search'"
        color="white"
        :timeout="300"
        v-model="filter.search"
        @input="getList"
        :help-text="'Search for Pricing Rules by name'"
      ></HelpFormInput>
      <v-icon color="white">mdi-magnify</v-icon>
    </v-toolbar>

    <v-card-text class="page-content">
      <v-card class="transparent elevation-0 recent-card">
        <v-card-title class="primary-gradient mb-2">
          Recent Rules
        </v-card-title>
        <v-card-text class="pa-0">
          <v-slide-y-transition leave-absolute>
            <v-slide-y-transition
              v-if="items && items.length"
              class="pa-0 row recent-rules"
              group
              tag="div"
            >
              <v-col
                cols="4"
                lg="3"
                md="4"
                sm="4"
                v-for="item in items"
                :key="item.id"
              >
                <PricingModelItem
                  :value="item"
                  @click="openDetails"
                  @input="updatePricingModel"
                  @onRemove="
                    () => {
                      removeCandidate = item;
                      removeDialog = true;
                    }
                  "
                ></PricingModelItem>
              </v-col>
            </v-slide-y-transition>
            <v-alert
              v-else
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
            >
              Currently, there are no Pricing Rules available. Please consider
              creating new Pricing Rules to get started. If you believe this is
              an error, kindly contact our support team for further assistance.
            </v-alert>
          </v-slide-y-transition>
          <div class="text-center mt-4">
            <v-pagination
              v-model="pagination.page"
              :length="pagination.pages"
              :total-visible="7"
              @input="getList"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>

    <PricingModelDeleteDialog
      :value="removeDialog"
      @onCancel="
        () => {
          removeCandidate = undefined;
          removeDialog = false;
        }
      "
      @onRemove="onRemove"
    ></PricingModelDeleteDialog>
  </v-card>
</template>
      
    <script>
import { PricingModelAPIInstance } from "../../components/api";
import PricingModelItem from "./components/PricingModelItem.vue";
import PricingModelDeleteDialog from "./components/PricingModelDeleteDialog.vue";
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  data() {
    return {
      api: PricingModelAPIInstance,
      pagination: {
        page: 1,
        pageSize: 10,
      },
      filter: {},
      items: [],
      removeDialog: false,
      removeCandidate: undefined,
    };
  },
  components: {
    PricingModelItem,
    PricingModelDeleteDialog,
    HelpFormInput,
  },
  async created() {
    this.getList();
  },
  methods: {
    openDetails(value) {
      this.$router.push(`/pricing-models/${value.id}`);
    },
    async getList() {
      const { items, pagination } = await this.api.list(
        this.pagination,
        this.filter
      );
      this.items = items;
      this.pagination = pagination;

      return items;
    },
    async onCreate() {
      await this.api.create(
        {
          name: "New Pricing Rule",
        },
        {
          toast: {
            message: "Pricing Rule Created",
          },
        }
      );
      this.getList();
    },
    async onRemove() {
      await this.api.delete(this.removeCandidate, {
        toast: {
          message: "Pricing Rule Removed",
        },
      });
      this.removeCandidate = undefined;
      this.removeDialog = false;

      this.getList();
    },
    async updatePricingModel(model) {
      await this.api.update(model, {
        // toast: {
        //   message: "Changes applied",
        // },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.recent-card {
  overflow: hidden;
  padding-bottom: 20px;
  .recent-rules {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    flex-direction: row;
  }
}
</style>