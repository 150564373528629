<template>
  <div id="per-day-chart" class="per-day-chart" :style="{
      height: height,
    }"></div>
</template>



<script>
import * as echarts from "echarts";
import _ from "lodash";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "per-day-chart",
    },
    height: {
      type: String,
      default: '50vh',
    },
  },
  data() {
    return {
      colors: ["#FF0087", "#80FFA5", "#00DDFF", "#37A2FF", "#FFBF00"],
      colorsGradient: ["#87009d", "#01bfec", "#4d77ff", "#7415db", "#e03e4c"],
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    const myChart = echarts.init(chartDom);
    myChart.setOption(this.getOptions(this.value));
  },
  methods: {
    getOptions(data = {}) {
      const uniqueNames = _.uniqBy(
        Object.values(data).reduce((acc, curr) => [...acc, ...curr], []),
        "name"
      )
        .map((el) => el.name)
        .filter((el) => el);

      console.log(uniqueNames);

      return {
        color: this.colors,
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: uniqueNames,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: Object.keys(data),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],

        series: uniqueNames.map((name, i) => ({
          name,
          type: "line",
          stack: "Total",
          smooth: true,
          lineStyle: {
            width: 0,
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: this.getColor({ dataIndex: i }),
          },
          emphasis: {
            focus: "series",
          },
          data: Object.values(data).map((arr) => {
            const properRecord = arr.find((el) => el.name === name);
            if (properRecord) console.log("properRecord: ", properRecord);

            return properRecord && properRecord.count
              ? parseInt(properRecord.count)
              : 0;
          }),
        })),
      };
    },
    getColor(color) {
      console.log("color: ", color);

      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: this.colors[color.dataIndex],
        },
        {
          offset: 1,
          color: this.colorsGradient[color.dataIndex],
        },
      ]);
    },
  },

  watch: {
    value: {
      handler(newVal) {
        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.getOptions(newVal));
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.per-day-chart {
  height: 100%;
  width: 100% !important;
}
</style>