import Vue from "vue"
import {
    PollAPIInstance
} from "../components/api"


export const PollStore = {
    namespaced: true,
    state() {
        return {
            polls: [],
            pagination: {},
            filter: {},
            poll: {
                style: {},
                campaign: {},
                audience: {},
                settings: {},
                attachments: [],
                tokens: []
            },
            publicPoll: {
                style: {},
                campaign: {},
                audience: {},
                settings: {},
                attachments: [],
                tokens: []
            },
            publicToken: {

            },
            analytics: {
                avgFraudScore: {
                    score: 0
                },
                answers: {},
                answersByDay: {},
                devices: {},
                browsers: {},
                os: {},
                clicksByDay: [],
                answersByBrowser: {},
                answersByDevice: {}
            }
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setPolls(state, {
            items,
            pagination
        }) {
            state.polls = items
            state.pagination = pagination
        },
        setPublicPoll(state, poll) {
            state.publicPoll = poll
        },
        setPublicToken(state, token) {
            state.publicToken = token;
        },
        setPoll(state, poll) {
            state.poll = poll
        },
        setAnalytics(state, {
            clicksByDay,
            answers,
            answersByDay,
            answersByBrowser,
            answersByDevice,
            avgFraudScore,
            browsers,
            os,
            devices
        }) {

            Vue.set(state, 'analytics', {
                clicksByDay,
                answers,
                answersByDay,
                answersByBrowser,
                answersByDevice,
                avgFraudScore,
                browsers,
                os,
                devices
            })
        }
    },
    getters: {
        availableApps(state) {
            return state.user && state.user.availableApps ? state.user.availableApps : []
        },
        availableViews(state) {
            return state.user && state.user.availableViews ? state.user.availableViews : []
        }
    },
    actions: {
        async getList({
            commit,
            // dispatch,
        }, {
            pagination,
            filter
        } = {}) {
            const resp = await PollAPIInstance.list(pagination, filter, {

            });

            await commit('setPolls', resp);
        },


        async get({
            commit,
            // dispatch,
        }, {
            id
        } = {}) {
            const resp = await PollAPIInstance.get(id, {

            });

            await commit('setPoll', resp);
        },

        async create(
            props,
            //     {
            //     // commit,
            //     // dispatch,
            // },
            newPoll) {
            await PollAPIInstance.create(newPoll, {
                toast: {
                    message: 'Poll created'
                }
            });

        },

        async getPersonalPollLink(props, {
            poll
        }) {
            const {
                url
            } = await PollAPIInstance.getLink(poll, {
                toast: {
                    message: 'Poll Link Copied'
                }
            });

            await navigator.clipboard.writeText(url);
        },


        async getPublicPoll({
            commit
        }, {
            short_token
        }) {
            const {
                poll,
                token
            } = await PollAPIInstance.getPublicPoll(short_token);


            console.log('poll: ', poll)

            commit('setPublicPoll', poll);
            commit('setPublicToken', token);

        },


        async saveResult(props, {
            answers,
            token
        } = {}) {
            await PollAPIInstance.saveResult({
                answers,
                token,
                referrer: document.referrer
            }, {
                toast: {
                    message: 'Thanks! Your answer saved'
                }
            });
        },


        async analytics({
            commit
        }, {
            id
        } = {}) {
            const [
                clicksByDay,
                answers,
                answersByDay,
                answersByBrowser,
                answersByDevice,
                avgFraudScore,
                browsers,
                os,
                devices
            ] = await Promise.all([
                PollAPIInstance.Analytics.clicksByDay(id),
                PollAPIInstance.Analytics.answers(id),
                PollAPIInstance.Analytics.answersByDay(id),
                PollAPIInstance.Analytics.answersByBrowser(id),
                PollAPIInstance.Analytics.answersByDevice(id),
                PollAPIInstance.Analytics.avgFraudScore(id),
                PollAPIInstance.Analytics.browsers(id),
                PollAPIInstance.Analytics.os(id),
                PollAPIInstance.Analytics.devices(id),

            ]);

            commit('setAnalytics', {
                clicksByDay,
                answers,
                answersByDay,
                answersByBrowser,
                answersByDevice,
                avgFraudScore,
                browsers,
                os,
                devices
            })
        }
    }
}