<template>
  <v-card
    class="pricingModel-item mx-auto"
    @click="(e) => $emit('click', value, e)"
  >
    <v-card-title class="pricingModel-title primary-gradient">
      <span class="text-h6 font-weight-light">{{ value.name }}</span>
      <!-- <div
          class="item-status"
          :style="{
            backgroundColor:
              value.status.code === 'DRAFT'
                ? 'red'
                : value.status.code === 'ACTIVE'
                ? 'purple'
                : 'grey',
          }"
        >
          <span>{{ value.status.name }}</span>
        </div> -->
    </v-card-title>

    <v-card-text class="font-weight-bold pa-4 pricingModel-text-preview">
      <!-- <div v-html="value.latest.text"></div> -->
      <v-row align="center">
        <v-col class="text-h4"> {{ getFormatted() }}</v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="card-actions-bottom">
      <v-list-item class="grow">
        <!-- <v-list-item-avatar color="grey darken-3">
              <v-img
                class="elevation-6"
                alt=""
                src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
              ></v-img>
            </v-list-item-avatar> -->

        <!-- <v-list-item-content class="active-version"> </v-list-item-content> -->

        <v-row align="center" justify="end">
          <v-btn
            icon
            @click.prevent.stop="
              () => {
                value.star = !value.star;
                $emit('input', value);
              }
            "
            :color="value.star ? 'primary' : ''"
          >
            <v-icon> mdi-star </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="error"
                @click.prevent.stop="$emit('onRemove', value)"
                icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>
              Clicking on this button will permanently delete the selected item
              from the system. Please note that this action cannot be undone, so
              proceed with caution. Consider making the item inactive or
              archived if you prefer a reversible action.</span
            >
          </v-tooltip>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>
    
    
<script>
import { AnalyticsAPIInstance } from "../../../components/api";
import { FormatterHelper } from "../../../components/helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      recentSpent: undefined,
      formatPrice: FormatterHelper.price,
      //
    };
  },
  created() {
    this.getSpent();
  },
  methods: {
    async getSpent() {
      const recentSpent = await AnalyticsAPIInstance.monthlySpent({
        modelId: this.value.id,
        period: "14days",
      });

      this.recentSpent = recentSpent;
    },
    getFormatted() {
      if (!this.recentSpent) return "";

      const [currency] = Object.keys(this.recentSpent);
      const [price] = Object.values(this.recentSpent);

      return this.formatPrice(price, currency);
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.pricingModel-item {
  min-height: 220px;

  .pricingModel-text-preview {
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .pricingModel-title {
    position: relative;
    overflow: hidden;
    .item-status {
      position: absolute;
      top: -10px;
      right: -60px;
      transform: rotate(45deg);
      width: 150px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      height: 62px;
      text-transform: capitalize;
      align-items: end;
      vertical-align: bottom;
    }
  }

  .active-version {
    max-width: 100px;
  }
}
</style>