<template>
  <v-card
    class="prompt-terminal elevation-0 transparent"
    id="console-scroll-container"
  >
    <v-card-text class="terminal-content" ref="scrollContainer">
      <v-list three-line>
        <v-slide-y-transition class="py-0 px-4 item-container" group tag="div">
          <template v-if="logs && logs.length">
            <DebugConsoleItem
              v-for="log in logs"
              :key="log.id"
              :value="log"
              :feedback="false"
              @click="
                (rec) => {
                  activeRecord = rec;
                  dialog = true;
                }
              "
            ></DebugConsoleItem>
          </template>
          <v-alert key="empty-alert" v-else prominent text type="info">
            No recent logs found for this prompt.
          </v-alert>
        </v-slide-y-transition>
        <div id="bottom-item"></div>
      </v-list>
    </v-card-text>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class=""> Details </v-card-title>

        <v-card-text class="json-container">
          <pre
            v-if="activeRecord"
            class="json-container-pre"
            v-html="prettifyJson(activeRecord.payload)"
          ></pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
    
    
    <script>
import { LogsAPIInstance } from "../../../components/api";
import _ from "lodash";
import { prettyPrintJson } from "pretty-print-json";
import goTo from "vuetify/lib/services/goto";
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import DebugConsoleItem from "./DebugConsoleItem.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({ logs: [] }),
    },
  },
  components: {
    DebugConsoleItem,
  },
  data() {
    return {
      listener: undefined,
      logs: [
        ...(this.value.logs.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        ) || []),
      ],
      scrollTimeout: undefined,
      dialog: false,
      activeRecord: undefined,
      formatTime: FormatterHelper.dateWithTime,
    };
  },
  computed: {},
  async created() {
    this.listen();
  },
  beforeDestroy() {
    this.listener.close();
  },
  mounted() {
    try {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    } catch (error) {
      console.warn("unable to scroll");
    }
  },
  methods: {
    async listen() {
      const maxId = _.maxBy(this.logs, "id");
      this.listener = await LogsAPIInstance.listen(
        maxId ? maxId.id : undefined,
        {
          taskIds: [this.value.id],
        }
      );

      this.listener.onmessage = (event) => {
        try {
          const parsed = JSON.parse(event.data);

          const existed = this.logs.find((lg) => lg.id === parsed.id);
          if (!existed) {
            this.logs.push(parsed);

            this.logs = this.logs.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            );
          }

          this.scrollToBottom();
        } catch (error) {
          console.warn("Unable to parse received data, ignore it...", error);
        }
      };

      this.listener.onerror = (error) => {
        console.error("EventSource error:", error);
        this.listener.close();

        setTimeout(() => {
          this.listen();
        }, 1000);
      };
    },
    prettifyJson(payload) {
      return prettyPrintJson.toHtml(payload);
    },
    scrollToBottom() {
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        return goTo("#bottom-item", {
          duration: 800,
          offset: 0,
          easing: "easeInOutCubic",
          container: "#console-scroll-container",
        });
      }, 200);
    },
  },

  watch: {
    value: {
      handler() {
        this.listener.close();
        this.listen();
      },
      deep: true,
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.prompt-terminal {
  overflow: auto;
  height: calc(100% - 20px);
  .terminal-content {
    overflow-y: auto;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 600px) {
      padding: 0px !important;
    }

    .item-container {
      @media (max-width: 600px) {
        padding: 2px !important;
      }
    }
  }
}
</style>
    
    
    