import {
    UserAPIInstance,
    AuthAPIInstance
} from "../components/api"
import {
    AuthHelper
} from "../components/helpers/auth.helper"
import router from "../router"

// Create a new store instance.
export const MainStore = {
    namespaced: true,
    state() {
        return {
            user: {
                profile: {}
            },
            token: undefined,
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setUser(state, user) {
            state.user = user
        },
        resetUser(state) {
            state.user = {
                profile: {}
            }
        },
        setToken(state, token) {
            state.token = token;
        },
        resetToken(state) {
            state.token = undefined;
        },
        setAuth(state, {
            user,
            token
        }) {
            AuthHelper.setToken(token);
            AuthHelper.setUser(user);
            state.token = token;
            state.user = user


        },
    },
    getters: {
        availableApps(state) {
            return state.user && state.user.availableApps ? state.user.availableApps : []
        },
        availableViews(state) {
            return state.user && state.user.availableViews ? state.user.availableViews : []
        }
    },
    actions: {
        async getUser({
            commit,
            dispatch
        }) {
            let user = await AuthHelper.getUser()

            //just get updated info
            if (user)
                user = await dispatch('getUserFromAPI', {
                    userId: user.id
                })

            commit('setUser', user)

            return user
        },

        async updateUser({
            dispatch
        }, updateUser) {
            //just get updated info
            await UserAPIInstance.update(updateUser, {
                toast: {
                    message: 'Your preferences updated.'
                }
            })

            const user = await dispatch('getUserFromAPI', {});

            return user;
        },

        async getToken({
            commit
        }) {

            const token = await AuthHelper.getToken()

            commit('setToken', token)

            return token
        },

        async getUserFromAPI({
            state,
            commit
        }, {
            userId
        } = {}) {
            const user = await UserAPIInstance.get(userId ? userId : state.user.id);

            AuthHelper.setUser(user);
            commit('setUser', user);

            return user;
        },


        async uploadUserAvatar({
            state,
            dispatch
        }, {
            formData
        }) {
            await UserAPIInstance.ProfileAPI.uploadAvatar(state.user, formData, {
                toast: {
                    message: 'Avatar Updated.'
                },

            });

            dispatch('getUserFromAPI');
        },



        async logIn({
            commit
        }, credentials) {
            await AuthHelper.setToken();
            await AuthHelper.setUser();

            const {
                token,
                user,
                action
            } = await AuthAPIInstance.signIn(credentials, {
                toast: {
                    message: 'Success. Sign In completed.'
                }
            });

            if (action === 'RESET_PASSWORD')
                return router.push(`/reset-password?token=${token}`, );

            commit('setAuth', {
                user,
                token
            });

            return router.push(`/dashboard`);

        },


        async resetPassword({
            commit
        }, data) {
            const {
                token,
                user
            } = await AuthAPIInstance.resetPassword(data, {
                toast: {
                    message: 'Success. Password reset completed.'
                }
            });

            commit('setAuth', {
                user,
                token
            });

            return router.push(`/dashboard`);
        },

        async logOut({
            commit
        }) {
            await AuthHelper.setToken();
            await AuthHelper.setUser();

            commit('resetUser');
            commit('resetToken');

        },


        async googleAuth({
            dispatch
        }) {

            const {
                url
            } = await AuthAPIInstance.getGoogleAuthUrl();

            dispatch
            console.log('URL: ', url)
            window.location = url

        },


        async googleSignIn({
            commit
        }, {
            code
        }) {

            const resp = await AuthAPIInstance.signInGoogle(code, {
                toast: {
                    message: 'Success. Sign In completed.'
                }
            });

            commit('setAuth', {
                user: resp.user,
                token: resp.token
            });

        }
    }
}