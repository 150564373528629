
<template>
  <v-card
    class="elevation-0 transparent page-card prompt-details"
    @mousemove="configConsoleMouseMove"
    @mouseup="configConsoleMouseUp"
    @drag="configConsoleMouseMove"
    @touchmove="configConsoleMouseMove"
  >
    <v-card-title class="page-card-title">
      <!-- <v-btn
            icon
            class="mr-2"
            color="primary"
            @click="() => (newPollDialog = !newPollDialog)"
            ><v-icon>mdi-plus</v-icon>
          </v-btn> -->
      <h2 class="page-title">
        <SimpleInput
          :icon="'mdi-pencil'"
          hide-details
          v-model="selectedVersion.prompt.name"
          @input="updatePrompt"
        ></SimpleInput>
      </h2>
      <v-spacer></v-spacer>

      <MenuPromptStatuses
        v-if="selectedVersion.prompt.status"
        v-model="selectedVersion.prompt.status"
        @input="updatePrompt"
        :helpText="'Pick the right status for your prompt.'"
        autoload
      ></MenuPromptStatuses>

      <MenuPromptVersion
        allow-create
        :items="prompt.versions"
        :prompt="prompt"
        :value="selectedVersion"
        :help-text="'Pick the desired prompt version to use'"
        @input="onVersionSelected"
        @onCreate="onVersionCreate"
      ></MenuPromptVersion>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="prompt-content">
      <v-toolbar dense class="mb-8 prompt-debug-toolbar">
        <!-- <v-divider vertical></v-divider> -->
        <v-row class="main-config-row">
          <!-- <v-btn-toggle
          v-model="toggle_multiple"
          color="primary"
          dense
          group
          multiple
        > -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="makeParam" text>
                <v-icon>mdi-text-recognition</v-icon>
              </v-btn>
            </template>
            <span>
              Mark a Parameter: By highlighting your desired text and clicking
              on this button, you can effortlessly transform your selected text
              into a parameter. This enables easy identification and further
              manipulation of specific elements within your content.</span
            >
          </v-tooltip>

          <!-- <PlayMenu @onPlay="onPlay" :loading="debug"></PlayMenu> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="reloadText" text>
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span
              >Reset to Default: Clears all parameters and reverts to the
              initial state, restoring clear text.</span
            >
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="removeText" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>
              Clear All: Removes all text and associated parameters, resetting
              the field to its original state.
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                @click="
                  () => {
                    if (tab !== 'tab-7') tab = 'tab-7';
                    else {
                      onPlay();
                    }
                  }
                "
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                text
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
            <span>
              Mark a Parameter: By highlighting your desired text and clicking
              on this button, you can effortlessly transform your selected text
              into a parameter. This enables easy identification and further
              manipulation of specific elements within your content.</span
            >
          </v-tooltip>
          <v-spacer></v-spacer>

          <v-divider vertical class="mr-7"></v-divider>

          <MenuConnector
            class="connector-selector"
            :helpText="'Pick the desired connector to use'"
            :connector="selectedVersion.defaultConnector"
            :version="selectedVersion.defaultConnectorVersion"
            @onConnectorChanged="
              (conn) => {
                selectedVersion.defaultConnector = conn;
                selectedVersion.defaultConnectorVersion = undefined;
                selectedVersion.default_connector_version_id = null;
                updatePrompt();
              }
            "
            @onConnectorVersionChanged="
              (ver) => {
                selectedVersion.defaultConnectorVersion = ver;
                updatePrompt();
              }
            "
          ></MenuConnector>
        </v-row>
        <v-slide-x-transition>
          <v-row
            v-if="
              selectedVersion.defaultConnectorVersion &&
              selectedVersion.defaultConnectorVersion.proxyParams
            "
            class="connector-proxy-params-row"
          >
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="(param, i) in selectedVersion.defaultConnectorVersion
                  .proxyParams"
                :key="i"
              >
                <!-- v-slot="{ active, toggle }" -->

                <div class="mx-2">
                  <ParamPicker
                    v-model="
                      selectedVersion.defaultConnectorVersion.proxyParams[i]
                    "
                    :help-text="'Pick Param Values'"
                  ></ParamPicker>
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-slide-x-transition>
        <!-- </v-btn-toggle> -->
      </v-toolbar>

      <v-fade-transition leave-absolute hide-on-leave>
        <PromptPrefill
          v-show="tab === 'tab-7'"
          class="param-prefill"
          ref="paramsPrefill"
          :value="selectedVersion"
        ></PromptPrefill>
      </v-fade-transition>
      <v-fade-transition leave-absolute hide-on-leave>
        <HelpFormInput
          v-if="tab !== 'tab-7'"
          v-model="selectedVersion.text"
          @contextmenu="show"
          :id="componentId"
          :html="true"
          :rows="4"
          @click="onParamClicked"
          @input="updatePrompt"
          class="main-editor"
        ></HelpFormInput>
      </v-fade-transition>

      <!-- DEBUG CONSOLE -->
      <v-card class="config-console-card" :style="configConsoleStyle">
        <div
          class="config-console primary-gradient"
          @mousedown="configConsoleMouseDown"
          @dragstart="configConsoleMouseDown"
          @touchstart="configConsoleMouseDown"
        ></div>
        <v-tabs v-model="tab" icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-1">
                <v-icon>mdi-text-recognition</v-icon>
              </v-tab>
            </template>
            <span
              >Prompt Parameters: Customize input field configurations for
              parameters. Define parameter names, API property names, default
              values, types (string or number), and descriptions. Tailor the
              parameters to align with your specific needs and integrate them
              seamlessly into your API workflows. Enhance user understanding and
              input consistency by providing clear and informative descriptions
              for each parameter.
            </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-2">
                <v-icon>mdi-comment-quote-outline</v-icon>
              </v-tab>
            </template>
            <span>
              Feedbacks
              <!-- Pre-processors: Define pre-processing actions for prompt
                  parameters. Apply cutting, transformations, and other
                  adjustments to input values before passing them to the prompt.
                  Shape and modify data to meet specific requirements, enhancing
                  prompt functionality and output quality. -->
            </span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-3">
                <v-icon>mdi-cog-play-outline</v-icon>
              </v-tab>
            </template>
            <span
              >Processing Configurations: Customize various execution options
              such as retries, timeout, and default model. Tailor the prompt's
              behavior to meet specific requirements, ensuring optimal
              performance and control over processing outcomes.
            </span>
          </v-tooltip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-4">
                <v-icon>mdi-brain</v-icon>
              </v-tab>
            </template>
            <span
              >Post-processors: Configure strategies for parsing AI model
              responses. Extract and manipulate relevant information using
              cutting, transformations, and other techniques. Refine and
              structure the output for seamless integration and customized
              presentation. Enhance the generated response's accuracy,
              consistency, and suitability for downstream tasks or user
              interactions.
            </span>
          </v-tooltip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-5">
                <v-icon>mdi-magnify-scan</v-icon>
              </v-tab>
            </template>
            <span
              >Post-validation: Perform operations on AI model-generated
              messages after receiving them. Apply string cutting,
              transformations, and other adjustments to validate and refine the
              output. Ensure compliance with specific criteria or desired
              formats, enhancing accuracy, consistency, and suitability for
              further processing or user interaction.
            </span>
          </v-tooltip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-6">
                <v-icon>mdi-export</v-icon>
              </v-tab>
            </template>
            <span
              >Listeners: Configure destinations for publishing the generated
              results. Choose from options like RabbitMQ topics, SNS, SQS, or
              API hooks. Establish communication channels to seamlessly
              integrate the output into existing systems or workflows. Utilize
              listeners to efficiently handle and distribute the generated
              output, enhancing communication and system interoperability.
            </span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" href="#tab-7">
                <v-icon>mdi-console</v-icon>
              </v-tab>
            </template>
            <span
              >Debug Console: Interactively test and fine-tune prompts in a
              convenient environment. Experiment with different inputs and
              observe corresponding AI model outputs. Verify prompt behavior,
              identify issues, and make necessary adjustments. Enhance prompt
              performance through iterative refinement and optimization using
              the debug console.</span
            >
          </v-tooltip>
        </v-tabs>

        <v-tabs-items v-model="tab" id="console-scroll-container">
          <v-tab-item :key="1" :value="'tab-1'">
            <PromptParams
              @mouseenter="paramMouseenter"
              @mouseleave="paramMouseleave"
              @input="updatePrompt"
              @onNameChanged="onParamNameChanged"
              @onRemove="removeParam"
              v-model="selectedVersion.parameters"
              :selected="selectedId"
            ></PromptParams>
          </v-tab-item>
          <v-tab-item :key="2" :value="'tab-2'">
            <Feedbacks
              ref="feedbacks"
              v-if="selectedVersion.id"
              :selectedVersionId="selectedVersion.id"
              nested
              :stats="false"
            ></Feedbacks>
          </v-tab-item>
          <v-tab-item :key="3" :value="'tab-3'">
            <PromptExecutionConfig
              v-model="selectedVersion.execution_config"
            ></PromptExecutionConfig>
          </v-tab-item>
          <!-- <v-tab-item :key="4" :value="'tab-4'"> Tab4 </v-tab-item> -->
          <!-- <v-tab-item :key="5" :value="'tab-5'"> Tab5 </v-tab-item> -->
          <!-- <v-tab-item :key="6" :value="'tab-6'"> Tab6 </v-tab-item> -->
          <v-tab-item :key="7" :value="'tab-7'">
            <PromptDebugConsole
              ref="console"
              :prompt="selectedVersion"
              :connector="debugConfig.connector"
              :promptId="selectedVersion.prompt.id"
              :version="debugConfig.version"
            ></PromptDebugConsole>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card-text>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="makeParam" :disabled="spanClicked">
          <v-list-item-icon
            ><v-icon color="primary"
              >mdi-text-recognition</v-icon
            ></v-list-item-icon
          >
          <v-list-item-title>Make Param</v-list-item-title>
        </v-list-item>
        <v-list-item @click="removeParam" :disabled="!spanClicked">
          <v-list-item-icon
            ><v-icon color="error">mdi-delete</v-icon></v-list-item-icon
          >
          <v-list-item-title>Remove Param</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-model="paramMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :close-on-content-click="false"
    >
      <v-card>
        <v-card-text>
          <ParamForm
            v-model="selected"
            @mouseenter="paramMouseenter"
            @mouseleave="paramMouseleave"
            @input="updatePrompt"
            @onNameChanged="onParamNameChanged"
            @onRemove="removeParam"
          ></ParamForm>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-card>
</template>
    
  <script>
import HelpFormInput from "../../components/wad/atoms/common/inputs/HelpFormInput.vue";
import PromptDebugConsole from "./components/PromptDebugConsole.vue";
import PromptParams from "./components/PromptParams.vue";
import { PromptAPIInstance } from "../../components/api";
import { v4 as uuidv4 } from "uuid";
// import PlayMenu from "./components/PlayMenu.vue";
import PromptPrefill from "./components/PromptPrefill.vue";
import _ from "lodash";
import SimpleInput from "../../components/wad/atoms/common/inputs/SimpleInput.vue";
import MenuPromptStatuses from "../../components/wad/atoms/common/menus/MenuPromptStatuses.vue";
// import PlayForm from "./components/forms/PlayForm.vue";
import MenuConnector from "../../components/wad/atoms/common/menus/MenuConnector.vue";
import ParamForm from "./components/forms/ParamForm.vue";
import ParamPicker from "../../components/wad/atoms/common/pickers/ParamPicker.vue";
import Feedbacks from "../Feedbacks/Feedbacks.vue";
import PromptExecutionConfig from "./components/PromptExecutionConfig.vue";
import MenuPromptVersion from "../../components/wad/atoms/common/menus/MenuPromptVersion.vue";

export default {
  data() {
    return {
      api: PromptAPIInstance,
      showMenu: false,
      componentId: uuidv4(),
      promptId: undefined,
      prompt: {
        latest: {},
        versions: [],
      },
      x: 0,
      y: 0,
      spanClicked: false,
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3],
      tab: null,
      parameters: [],
      selectedId: null,
      selected: null,
      debugConfig: {},
      debug: false,
      configConsoleStyle: {
        top: "300px",
        height: `calc(100% - 318px)`,
      },
      configConsoleMove: false,
      selectedVersion: {
        text: "",
        parameters: [],
        prompt: {},
      },
      paramMenu: false,
    };
  },
  components: {
    HelpFormInput,
    PromptDebugConsole,
    PromptParams,
    // PlayMenu,
    PromptPrefill,
    SimpleInput,
    MenuPromptStatuses,
    // PlayForm,
    MenuConnector,
    ParamForm,
    ParamPicker,
    Feedbacks,
    PromptExecutionConfig,
    MenuPromptVersion,
  },
  mounted() {},
  created() {
    const { id } = this.$route.params;

    if (!id) this.$router.push("/prompts");
    this.promptId = id;
    this.getFromApi();
  },
  methods: {
    configConsoleMouseDown() {
      console.log("SHIt");

      this.configConsoleMove = true;
    },
    configConsoleMouseUp() {
      this.configConsoleMove = false;
    },
    configConsoleMouseMove(e) {
      if (!this.configConsoleMove) return;

      const touch = e.touches && e.touches[0]; // Get the first touch
      const movementY = touch && touch.movementY; // Access the movementY value

      const newVal =
        parseInt(this.configConsoleStyle.top) +
        (movementY ? movementY : e.movementY);
      this.configConsoleStyle.top = newVal + "px";

      this.configConsoleStyle.height = `calc(100% - ${newVal + 18}px)`;
    },

    async getFromApi() {
      this.prompt = await this.api.get(this.promptId);
      this.selectedVersion = await this.api.Versions.get(
        this.promptId,
        this.prompt.latest.id
      );
    },
    async onVersionCreate() {
      await this.api.Versions.create(this.prompt.id, this.selectedVersion, {
        toast: {
          message: "New Version Released",
        },
      });
      this.prompt = await this.api.get(this.promptId);
      this.selectedVersion = await this.api.Versions.get(
        this.promptId,
        this.prompt.latest.id
      );
    },
    async onVersionSelected(selectedVersion){
      this.selectedVersion = await this.api.Versions.get(
        this.promptId,
        selectedVersion.id
      );
    },
    reloadText() {
      const regex = /<span\b[^>]*>(.*?)<\/span>/gi;
      this.selectedVersion.text = this.selectedVersion.text.replace(
        regex,
        "$1"
      );
    },
    removeText() {
      this.selectedVersion.text = "";
    },
    paramMouseenter(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      if (!paramSpan.classList.contains("hovered"))
        paramSpan.classList.add("hovered");
    },
    paramMouseleave(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      if (paramSpan.classList.contains("hovered"))
        paramSpan.classList.remove("hovered");
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.shiftKey && event.key === "P") {
        this.makeParam();
      }
    },
    onParamNameChanged(param) {
      const paramSpan = document.querySelector(`span#p-${param.id}`);
      paramSpan.setAttribute("data-before", param.name);

      const tempElement = document.createElement("span");
      tempElement.setAttribute("class", "param-hider");
      tempElement.style.visibility = "hidden";
      tempElement.style.position = "absolute";
      tempElement.style.top = 0;
      tempElement.style.left = 0;
      tempElement.style.fontSize = "18px";
      tempElement.style.zIndex = "9";

      tempElement.textContent = param.name;

      // Append the temporary element to the DOM
      document.body.appendChild(tempElement);

      // Get the width of the temporary element
      const textWidth = tempElement.offsetWidth;

      // Remove the temporary element from the DOM
      document.body.removeChild(tempElement);

      // Set the calculated width to the target element
      paramSpan.style.width = `${textWidth}px`;

      const container = document.getElementById(this.componentId);

      this.selectedVersion.text = container.innerHTML;

      this.updatePrompt();
    },
    show(e) {
      e.preventDefault();

      if (e.target.tagName.toLowerCase() === "span") {
        this.spanClicked = true;
        const [id] = e.target.getAttribute("id").split("p-").reverse();
        this.selectedId = id;
      } else this.spanClicked = false;

      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async makeParam() {
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const container = document.getElementById(this.componentId);

        if (range.toString()) {
          const name = _.snakeCase(range.toString());

          const newParam = await this.api.Params.create(
            this.selectedVersion.id,
            this.promptId,
            {
              name,
              default_value: range.toString(),
              type: "string",
            }
          );
          this.selectedVersion.parameters.push(newParam);

          const newSpan = document.createElement("span");
          newSpan.setAttribute("class", "param-hider");
          newSpan.setAttribute("aria-hidden", "true");
          newSpan.setAttribute("id", `p-${newParam.id}`);
          newSpan.setAttribute("key", newParam.id);
          newSpan.setAttribute("data-before", newParam.name);

          newSpan.style.setProperty("content", '"World"');

          const node = newSpan.getRootNode();

          range.surroundContents(node);

          const afterRendering = document.querySelector(`#p-${newParam.id}`);

          console.log("afterRendering: ", afterRendering.scrollWidth);

          afterRendering.style.width = afterRendering.scrollWidth + "px";

          const htmlAfterSelection = container.innerHTML;

          this.selectedVersion.text = htmlAfterSelection;
          this.updatePrompt();
        }
      }
    },
    removeParam(val) {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(
        this.selectedVersion.text,
        "text/html"
      );
      const spans = parsedDocument.querySelectorAll(
        `span#p-${val ? val.id : this.selectedId}`
      );

      spans.forEach((span) => {
        const textNode = document.createTextNode(span.textContent);
        span.parentNode.replaceChild(textNode, span);
      });

      this.selectedVersion.text = parsedDocument.documentElement.outerHTML;
      this.updatePrompt();
    },
    async onPlay() {
      this.tab = "tab-7";

      this.debug = true;

      const values = this.$refs.paramsPrefill.getValues();

      const resp = PromptAPIInstance.test({
        prompt: {
          id: this.selectedVersion.prompt.id,
          version: this.selectedVersion.v,
          params: values.map((val) => val.value),
        },
        connector: {
          id: this.selectedVersion.defaultConnector.id,
          version: this.selectedVersion.defaultConnectorVersion.v,
        },
        proxy:
          this.selectedVersion.defaultConnectorVersion.proxyParams &&
          this.selectedVersion.defaultConnectorVersion.proxyParams.length
            ? this.selectedVersion.defaultConnectorVersion.proxyParams.reduce(
                (acc, curr) => {
                  acc[curr.name] = curr.value;
                  return acc;
                },
                {}
              )
            : {},
        mode: this.debugConfig.mode,
      });

      // const terminal = await this.waitUntilReady("console");
      console.log("values", values);
      console.log("resp", resp);
      this.debug = false;
    },
    waitUntilReady(ref) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.$refs[ref]) {
            clearInterval(interval);
            resolve(this.$refs[ref]);
          }
        }, 100);
      });
    },

    onParamClicked(event) {
      if (event.target.tagName.toLowerCase() === "span") {
        // this.spanClicked = true;
        const [id] = event.target.getAttribute("id").split("p-").reverse();
        this.selectedId = id;

        this.x = event.clientX;
        this.y = event.clientY;

        this.paramMenu = true;
      }
      // else this.spanClicked = false;

      // const target = event.target;
      // if (target) {
      //   const [id] = target.getAttribute("id").split("p-").reverse();
      //   this.selectedId = id;
      // }

      // if (!target.classList.contains("hovered"))
      //   target.classList.add("hovered");
    },
    toParams(text) {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(text, "text/html");
      const spans = parsedDocument.querySelectorAll("span.param-hider");

      const prepared = [...spans].map((span) => {
        const [id] = span.getAttribute("id").split("p-").reverse();

        span.addEventListener("contextmenu", this.show);

        const param = this.selectedVersion.parameters.find(
          (p) => parseInt(p.id) === parseInt(id)
        );

        if (!param)
          return {
            name: `New Param`,
            id: parseInt(id),
          };
        else return param;
      });

      this.selectedVersion.parameters = prepared;
    },

    async updatePrompt() {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);

      this.updateTimeout = setTimeout(async () => {
        await this.api.Versions.update(this.prompt.id, this.selectedVersion, {
          // toast: {
          //   message: "Changes applied",
          // },
        });
      }, 500);
    },
  },
  watch: {
    "selectedVersion.text": {
      handler(newVal) {
        this.toParams(newVal);
      },
    },
    selectedId(newVal) {
      this.selected = this.selectedVersion.parameters.find((el) => {
        return String(el.id) === String(newVal);
      });
    },
    tab(newVal) {
      if (newVal === "tab-2" && this.$refs.feedbacks) {
        this.$refs.feedbacks.getList();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.prompt-content {
  height: calc(100% - 20px);
  position: relative;

  @media (max-width: 600px) {
    padding: 4px;
  }
  .prompt-debug-toolbar {
    // height: fit-content!important;
    .main-config-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      justify-content: flex-end;

      @media (max-width: 600px) {
        .connector-selector {
          margin-top: 14px;
        }

        hr {
          display: none;
        }
      }
    }

    .connector-proxy-params-row {
      border-top: 1px solid #e0e0e0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      // justify-content: flex-end;
    }
  }
}

.prompt-details {
  height: 100%;

  .page-card-title {
    justify-content: flex-end;
  }
}
.main-editor {
  line-height: 30px;
  height: 138px;
  overflow-y: auto;

  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.param-prefill {
  padding: 2px;
  line-height: 30px;
  height: 138px;
  overflow-y: auto;
  margin-top: -10px;
  @media (max-width: 600px) {
    height: 228px !important;
  }
}

.config-console {
  width: 100%;
  height: 8px;
  cursor: n-resize;
}
.config-console-card {
  position: absolute;
  top: 300px;
  width: calc(100% - 30px);
  z-index: 3;

  @media (max-width: 600px) {
    top: 444px !important;
    width: calc(100% - 8px);
  }
}

.prefill-col {
  display: flex;
  transition: all 0.3s;
}
.play-params-col {
  display: flex;
  min-width: 340px;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 fit-content;
  transition: all 0.3s;

  .play-params-card {
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    height: 260px;
    overflow: hidden;
    .play-form {
      overflow-y: auto;
      height: 210px;
    }
  }
}
</style>