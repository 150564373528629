import {
    API
} from '../Api.class';
import {
    PromptParamsAPI
} from './PromptParams.api';
import {
    PromptVersionsAPI
} from './PromptVersions.api';


export class PromptAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Params = new PromptParamsAPI(baseUrl);
        this.Versions = new PromptVersionsAPI(baseUrl);
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/prompts', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/prompts/statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async versions(promptId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${promptId}/versions`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/prompts/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/prompts', body, {
            meta
        });
    }

    async update(promptVersion, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/prompts/${promptVersion.prompt.id}`, promptVersion, {
            meta
        });
    }


    async delete(prompt, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/prompts/${prompt.id}`, {
            meta
        });
    }

    async test(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/prompts/test`, body, {
            meta
        });
    }
}