var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"prompt-item mx-auto",on:{"click":(e) => _vm.$emit('click', _vm.value, e)}},[_c('v-card-title',{staticClass:"prompt-title primary-gradient"},[_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v(_vm._s(_vm.value.name))]),_c('div',{staticClass:"item-status",style:({
        backgroundColor:
          _vm.value.status.code === 'DRAFT'
            ? 'red'
            : _vm.value.status.code === 'ACTIVE'
            ? 'purple'
            : 'grey',
      })},[_c('span',[_vm._v(_vm._s(_vm.value.status.name))])])]),_c('v-card-text',{staticClass:"font-weight-bold pa-4 prompt-text-preview"},[_c('v-list-item',{staticClass:"full-width"},[_c('v-list-item-icon',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-face-agent")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{},[_vm._v(" Support Category ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.value.category && _vm.value.category.name))])],1)],1)],1),_c('v-card-actions',{staticClass:"card-actions-bottom"},[_c('v-spacer'),_c('span',{staticClass:"mr-1"},[_vm._v("·")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }