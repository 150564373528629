<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12">
        <HelpFormInputVue
          v-model="form.old_password"
          type="password"
          label="Old Password"
          :helpText="'Please provide original password'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          type="password"
          v-model="form.new_password"
          :rules="newPasswordRules"
          label="New Password"
          :helpText="'Provide a desired password. Please ensure that it is strong enough.'"
          required
          autocomplete="new-password"
        ></HelpFormInputVue>

        <HelpFormInputVue
          type="password"
          v-model="form.repeat_password"
          :rules="repeatPasswordRules"
          label="Repeat New Password"
          :helpText="'Please repeat a new password to make sure they are identical.'"
          required
          autocomplete="new-password"
        ></HelpFormInputVue>
      </v-col>
    </v-row>
  </v-form>
</template>
          
          
    <script>
import HelpFormInputVue from "../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      newPasswordRules: [(v) => !!v || "New Password Is Required"],
      repeatPasswordRules: [
        (v) => !!v || "New Password Is Required",
        (v) => v === this.form.new_password || "Passwords should be identical.",
      ],
    };
  },
  computed: {},
  components: {
    HelpFormInputVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
          
          
<style lang="scss" scoped>
</style>