<template>
  <div class="response-preview-editor">
    <div v-html="displayJSON"></div>
  </div>
</template>
    
    <script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import { ParamsIterator } from "../../../components/helpers/params.parser";
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
      default: "{}",
    },
  },

  computed: {
    displayJSON() {
      const modelResponse = this.value.includes("MODEL_RESPONSE");

      const output = {
        result: modelResponse
          ? `<span 
            class="request-param-hider" 
            style="width:${this.onParamNameChanged({
              name: "MODEL_RESPONSE",
            })}px;"
            data-before="MODEL_RESPONSE"> 
            MODEL_RESPONSE 
            </span>`
          : undefined,
        output: {},
      };

      for (const param of new ParamsIterator(this.value)) {
        if (param.issuer !== "system")
          _.set(
            output,
            "output." + (param.path ? param.path : param.key),
            param.replacement
          );
      }

      return this.beautifyJSON(JSON.stringify(output))
        .replace(/\\"/gi, '"')
        .replace(/\n/gi, "<br>")
        .replace(/\t/gi, "&nbsp; ");
    },
  },
  data() {
    return {
      cutString: FormatterHelper.cutString,
    };
  },
  methods: {
    beautifyJSON(value) {
      if (typeof value === "string") {
        let parsingString = value;

        // TODO FIX when time is come

        const used = [];

        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(
          this.value.markup,
          "text/html"
        );
        const spans = parsedDocument.querySelectorAll(
          "span.request-param-hider"
        );

        spans.forEach((span) => {
          const randKey = Math.random();

          used.push({
            key: randKey,
            span,
          });

          parsingString = parsingString.replace(
            span.outerHTML,
            randKey
            // JSON.stringify(span.getAttribute("id")).replace(/"/gi, '\\"')
          );
        });

        // Add double quotes to property names
        const jsonString = parsingString.replace(
          /([{,]\s*)(\w+)(\s*:)/g,
          '$1"$2"$3'
        );

        // Replace single quotes with double quotes for string values
        const jsonLikeString = jsonString.replace(
          /'([^']+)'(:\s*[^',}\]]+)/g,
          '"$1"$2'
        );

        const parsed = JSON.parse(jsonLikeString);

        let prettified = JSON.stringify(parsed, null, "\t");

        used.forEach((item) => {
          prettified = prettified.replace(item.key, item.span.outerHTML);
        });

        return prettified;
      }
    },
    onParamNameChanged(param) {
      const tempElement = document.createElement("span");
      tempElement.setAttribute("class", "request-param-hider");
      tempElement.style.visibility = "hidden";
      tempElement.style.position = "absolute";
      tempElement.style.top = 0;
      tempElement.style.left = 0;
      tempElement.style.fontSize = "16px";

      tempElement.textContent = param.name;

      // Append the temporary element to the DOM
      document.body.appendChild(tempElement);

      // Get the width of the temporary element
      const textWidth = tempElement.offsetWidth;

      // Remove the temporary element from the DOM
      document.body.removeChild(tempElement);

      return textWidth;
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.response-preview-editor {
  font-family: monospace;
}
</style>