<template>
  <v-list-item class="debug-item" >
    <div
      class="indicator"
      :style="{
        '--color': generateColorFromUUID(value.task_id),
      }"
    ></div>

    <div class="status-small status">
      <div
        v-if="
          value.message === 'PROMPT_TASK_AUTH_COMPLETED' ||
          value.message === 'PROMPT_TASK_COMPLETED'
        "
        class="o-circle c-container__circle o-circle__sign--success"
      >
        <div class="o-circle__sign"></div>
      </div>
      <div
        v-else-if="value.message === 'PROMPT_TASK_FAILED'"
        class="o-circle c-container__circle o-circle__sign--failure"
      >
        <div class="o-circle__sign"></div>
      </div>
      <!-- </v-fade-transition> -->
    </div>

    <v-list-item-content>
      <v-list-item-title
      @click="$emit('click', value)"
        v-html="value.displayHtml"
      ></v-list-item-title>
      <v-list-item-subtitle>{{ fromNow(value.date) }} </v-list-item-subtitle>
      <FeedbackComponent
        :taskId="value.task_id"
        :source="'PRST Portal'"
        @onSubmit="(f) => $emit('onFeedbackSubmit', f)"
        v-if="value.message === 'PROMPT_TASK_COMPLETED' && feedback"
      ></FeedbackComponent>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { FormatterHelper } from "../../../components/helpers/formatter.helper";
import moment from "moment";
import FeedbackComponent from "../../Feedbacks/components/FeedbackComponent.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FeedbackComponent,
  },
  data() {
    return {
      formatTime: FormatterHelper.dateWithTime,
    };
  },
  computed: {},
  methods: {
    fromNow(date) {
      return moment(date).fromNow();
    },
    generateColorFromUUID(uuid) {
      // Remove any hyphens from the UUID
      const uuidWithoutHyphens = uuid.replace(/-/g, "");

      // Take the first 6 characters from the UUID
      const hexColor = uuidWithoutHyphens.substr(0, 6);

      // Prepend a '#' to the hex color
      const color = `#${hexColor}`;

      return color;
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.debug-item {
  display: flex;
  align-items: baseline;
}

.indicator {
  width: 4px;
  height: 100%;
  background-color: var(--color, blue);
  position: absolute;
  left: 0;
  top: 0;
}

.status {
  width: 40px;
  height: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.record {
  width: 100%;
}

.payload {
  word-break: break-all;
}

.json-container {
  max-width: 800px;
  text-overflow: ellipsis;
  overflow-x: auto;
  max-height: 60vh;
}
</style>
    
    
    