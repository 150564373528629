
<template>
  <v-card class="elevation-0 transparent page-card connector-details">
    <v-card-title class="page-card-title">
      <!-- <v-btn
            icon
            class="mr-2"
            color="primary"
            @click="() => (newPollDialog = !newPollDialog)"
            ><v-icon>mdi-plus</v-icon>
          </v-btn> -->
      <h2 class="page-title">
        <SimpleInput
          :icon="'mdi-pencil'"
          hide-details
          v-model="connectorVersion.connector.name"
          @input="updateConnector"
        ></SimpleInput>
      </h2>
      <v-spacer></v-spacer>

      <MenuConnectorStatuses
        v-if="connectorVersion.connector.status"
        v-model="connectorVersion.connector.status"
        @input="updateConnector"
        :helpText="'Pick the right status for your connector.'"
        autoload
      ></MenuConnectorStatuses>

      <v-chip
        v-if="connectorVersion.status"
        class="ma-2"
        :color="selectedVersion === connectorVersion.v ? 'primary' : 'grey'"
        label
        text-color="white"
      >
        <!-- <v-icon left> mdi-label </v-icon> -->
        {{ selectedVersion === connectorVersion.v ? "LTS: " : "" }}
        &nbsp; <strong>{{ connectorVersion.v }}</strong>
      </v-chip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="connector-setup-content">
      <!-- Pipeline -->
      <v-row class="connector-setup-content-row">
        <v-col cols="12" class="connector-setup-content-col">
          <ul class="pipeline-header-container">
            <li
              class="stepper__item"
              :class="{
                complete: tab > 0,
                active: tab === 1,
              }"
              @click="tab = 1"
            >
              <!-- Input -->
              <!-- Full Details  -->
              <v-card class="pipeline-header-card transparent elevation-0"
                ><v-card-title>
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 1,
                    }"
                    >Input</span
                  >
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text class="pipeline-config-content" v-if="tab === 1">
                    <v-card-title>Credentials</v-card-title>
                    <RequestParamsEditor
                      v-model="connectorVersion.decryptedCredentials"
                      addLabel="New Credential"
                      @input="updateConnector"
                    ></RequestParamsEditor>
                    <v-card-title>Proxy Parameters</v-card-title>
                    <RequestParamsEditor
                      v-model="connectorVersion.proxyParams"
                      addLabel="New Proxy Parameter"
                      @input="updateConnector"
                    ></RequestParamsEditor>
                  </v-card-text>
                </transition>
                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 1"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn @click.prevent.stop="tab = 2" color="primary"
                        >Next
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
            <li
              class="stepper__item"
              :class="{
                complete: tab > 1,
                active: tab === 2,
              }"
              @click="tab = 2"
            >
              <!-- Full Details  -->
              <v-card class="pipeline-header-card transparent elevation-0">
                <v-card-title class="pr-10">
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 2,
                    }"
                    >Auth</span
                  >

                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-if="tab === 2"
                    dark
                    v-model="connectorVersion.config.auth.skip"
                    label="Skip"
                    color="white"
                    class="pa-0 ma-0"
                    :value="true"
                    hide-details
                    @change="$emit('input', connectorVersion.config.auth.skip)"
                  ></v-checkbox>
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text v-if="tab === 2" class="pipeline-config-content">
                    <ConnectorHTTPUrlConfig
                      v-model="connectorVersion.config.auth"
                      @input="updateConnector"
                    ></ConnectorHTTPUrlConfig>
                    <ConnectorHTTPAPIConfigVue
                      class="connector-http-config"
                      v-model="connectorVersion.config.auth"
                      :availableParams="availableParams"
                      @input="updateConnector"
                    ></ConnectorHTTPAPIConfigVue>
                  </v-card-text>
                </transition>

                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 2"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn @click.prevent.stop="tab = 3" color="primary"
                        >Next
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
            <li
              class="stepper__item"
              :class="{
                complete: tab > 2,
                active: tab === 3,
              }"
              @click="tab = 3"
            >
              <!-- Full Details  -->
              <v-card class="pipeline-header-card transparent elevation-0"
                ><v-card-title class="pr-8">
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 3,
                    }"
                    >Execute
                  </span>

                  <v-spacer></v-spacer>

                  <transition name="slide">
                    <v-alert
                      v-if="!requiredParamsUsed && tab === 3"
                      dense
                      border="left"
                      type="warning"
                      class="ma-0 pa-0 config-alert"
                    >
                      Ensure <strong>PRST_PROMPT</strong> and other parameters
                      <br />
                      are assigned.
                    </v-alert>
                  </transition>
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text v-if="tab === 3" class="pipeline-config-content">
                    <ConnectorHTTPUrlConfig
                      v-model="connectorVersion.config.execute"
                      @input="updateConnector"
                    ></ConnectorHTTPUrlConfig>
                    <ConnectorHTTPAPIConfigVue
                      class="connector-http-config"
                      v-model="connectorVersion.config.execute"
                      :availableParams="availableParams"
                      @input="updateConnector"
                    ></ConnectorHTTPAPIConfigVue>
                  </v-card-text>
                </transition>
                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 3"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!requiredParamsUsed"
                        @click.prevent.stop="onAPITest"
                        color="primary"
                        >Test Connection
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
            <li
              class="stepper__item"
              :class="{
                complete: tab > 3,
                active: tab === 4,
              }"
              @click="tab = 4"
            >
              <!-- Full Details  -->
              <v-card class="pipeline-header-card transparent elevation-0"
                ><v-card-title class="pr-8">
                  <span
                    class="tab-title"
                    :class="{
                      vertical: tab !== 4,
                    }"
                    >Test
                  </span>
                </v-card-title>
                <transition name="slide-fade">
                  <v-card-text
                    id="console-scroll-container"
                    v-show="tab === 4"
                    class="pipeline-config-content"
                  >
                    <ConnectorTerminal
                      :connectorId="connectorId"
                      :userId="user.id"
                      v-if="user && user.id"
                    ></ConnectorTerminal>
                  </v-card-text>
                </transition>
                <v-fade-transition>
                  <v-card-actions
                    v-if="tab === 4"
                    class="card-actions-bottom pa-8"
                  >
                    <v-row class="mr-8">
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!requiredParamsUsed"
                        @click.prevent.stop="onAPITest"
                        color="primary"
                        >Test Again
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-fade-transition>
              </v-card>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
      
<script>
import { ConnectorAPIInstance } from "../../components/api";
import SimpleInput from "../../components/wad/atoms/common/inputs/SimpleInput.vue";
import MenuConnectorStatuses from "../../components/wad/atoms/common/menus/MenuConnectorStatuses.vue";
import ConnectorHTTPAPIConfigVue from "./components/ConnectorHTTPAPIConfig.vue";
import ConnectorHTTPUrlConfig from "./components/ConnectorHTTPUrlConfig.vue";
import RequestParamsEditor from "./components/RequestParamsEditor.vue";
import ConnectorTerminal from "./components/ConnectorTerminal.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      api: ConnectorAPIInstance,
      tab: 1,
      connectorId: undefined,
      connectorVersion: {
        connector: {},
        config: {
          auth: {
            prst_params: [],
          },
          execute: {
            prst_params: [],
          },
        },
      },
      selectedVersion: "v1",
    };
  },
  components: {
    RequestParamsEditor,
    ConnectorHTTPUrlConfig,
    ConnectorHTTPAPIConfigVue,
    MenuConnectorStatuses,
    SimpleInput,
    ConnectorTerminal,
  },
  mounted() {},
  computed: {
    ...mapState("MainStore", ["user"]),

    requiredParamsUsed() {
      let out = 1;
      // const regex = /<span.*?\breference-id="PRST_PROMPT".*?>.*?<\/span>/;

      out &=
        (this.connectorVersion.config.auth.body &&
          String(this.connectorVersion.config.auth.body).indexOf(
            "PRST_PROMPT"
          ) !== -1) ||
        (this.connectorVersion.config.auth.headers &&
          String(this.connectorVersion.config.auth.headers).indexOf(
            "PRST_PROMPT"
          ) !== -1) ||
        (this.connectorVersion.config.auth.params &&
          String(this.connectorVersion.config.auth.params).indexOf(
            "PRST_PROMPT"
          ) !== -1) ||
        (this.connectorVersion.config.execute.body &&
          String(this.connectorVersion.config.execute.body).indexOf(
            "PRST_PROMPT"
          ) !== -1) ||
        (this.connectorVersion.config.execute.headers &&
          String(this.connectorVersion.config.execute.headers).indexOf(
            "PRST_PROMPT"
          ) !== -1) ||
        (this.connectorVersion.config.execute.params &&
          String(this.connectorVersion.config.execute.params).indexOf(
            "PRST_PROMPT"
          ) !== -1);

      out &= !!(
        this.connectorVersion.config.execute.method &&
        this.connectorVersion.config.execute.url
      );

      out &= this.connectorVersion.config.auth.skip
        ? 1
        : !!(
            this.connectorVersion.config.auth.method &&
            this.connectorVersion.config.auth.url
          );

      return !!out;
    },
    availableParams() {
      return [
        { section: "System Parameters" },
        {
          id: "PRST_PROMPT",
          name: "PRST_PROMPT",
          value: "Will be automatically passed via API",
          issuer: "system",
        },
        {
          id: "MODEL_RESPONSE",
          name: "MODEL_RESPONSE",
          value: "Will be automatically passed via API",
          issuer: "system",
        },

        //  CREDENTIALS
        ...(this.connectorVersion.decryptedCredentials &&
        this.connectorVersion.decryptedCredentials.length
          ? [
              { section: "Credentials" },

              ...this.connectorVersion.decryptedCredentials.map((cr) => ({
                ...cr,
                issuer: "credentials",
              })),
            ]
          : []),

        // PROXY Parameters
        ...(this.connectorVersion.proxyParams &&
        this.connectorVersion.proxyParams.length
          ? [
              { section: "Proxy Parameters" },

              ...this.connectorVersion.proxyParams.map((cr) => ({
                ...cr,
                issuer: "proxy",
              })),
            ]
          : []),

        // AUTH RESPONSE
        ...(this.connectorVersion.config.auth &&
        this.connectorVersion.config.auth.response &&
        this.connectorVersion.config.auth.response.parameters &&
        this.connectorVersion.config.auth.response.parameters.length
          ? [
              { section: "Auth Response Parameters", area: "auth" },
              ...this.connectorVersion.config.auth.response.parameters.map(
                (pr) => ({
                  area: "auth",
                  ...pr,
                })
              ),
            ]
          : []),

        // EXECUTE RESPONSE
        ...(this.connectorVersion.config.execute &&
        this.connectorVersion.config.execute.response &&
        this.connectorVersion.config.execute.response.parameters &&
        this.connectorVersion.config.execute.response.parameters.length
          ? [
              { section: "Execute Response Parameters", area: "execute" },
              ...this.connectorVersion.config.execute.response.parameters.map(
                (pr) => ({
                  area: "execute",
                  ...pr,
                })
              ),
            ]
          : []),
      ];
    },
  },
  created() {
    const { id } = this.$route.params;

    if (!id) this.$router.push("/connectors");
    this.connectorId = id;
    this.getFromApi();
  },
  methods: {
    async getFromApi() {
      this.connectorVersion = await this.api.get(this.connectorId);
      this.connectorVersion.decryptedCredentials = this.connectorVersion
        .decryptedCredentials
        ? this.connectorVersion.decryptedCredentials
        : {};
      this.connectorVersion.config = this.connectorVersion.config
        ? this.connectorVersion.config
        : {
            auth: {},
            execute: {},
          };
    },
    async updateConnector() {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);

      this.updateTimeout = setTimeout(async () => {
        await this.api.update(this.connectorVersion, {
          // toast: {
          //   message: "Changes applied",
          // },
        });
      }, 500);
    },
    async onAPITest() {
      // open test tab
      this.tab = 4;
      try {
        await this.api.test(this.connectorId, "execute", {
          toast: {
            message: "Test Completed",
          },
        });
      } catch (error) {
        console.log("error: ", error);
      }

      this.updateConnector();
    },
  },
};
</script>
  
  
<style lang="scss"  scoped>
.connector-details {
  height: 100%;
  .connector-setup-content {
    height: calc(100% - 20px);

    .connector-setup-content-row {
      height: 100%;

      .connector-setup-content-col {
        height: 100%;
      }
    }
  }
}
.version-icon {
  font-weight: 600;
  font-size: 24px;
  min-width: 70px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pipeline-header-container {
  height: 100%;
  display: flex;
  padding: 0;
  width: 100%;
  list-style: none;
  position: relative;
  margin: 0px;
  border-radius: 4px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
    width: calc(100% - 20px);
    background: #e7e7e7;
  }
  min-height: 70vh;

  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   content: "";
  //   width: calc(100% - 20px);
  //   background: #e7e7e7;
  // }
}

.stepper__item {
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.6s;
  overflow: hidden;
  margin: 0 0 0 -19px;
  min-width: 60px;
  @media (max-width: 600px) {
    min-width: 10%;
  }
  width: 10%;
  flex-grow: 1;
  background: repeating-linear-gradient(
    -65deg,
    #fff,
    #fff 20px,
    #fcfcfc 20px,
    #fcfcfc 40px
  );

  clip-path: polygon(
    0 0,
    calc(100% - 21px) 0,
    100% 35px,
    calc(100% - 21px) 70px,
    calc(100% - 21px) 100%,
    0 100%,
    0 70px,
    21px 35px
  );
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 21px) 0,
    100% 35px,
    calc(100% - 21px) 70px,
    calc(100% - 21px) 100%,
    0 100%,
    0 70px,
    21px 35px
  );

  .pipeline-header-card {
    color: inherit;
    transition: all 0.1s;
    height: 100%;
    padding-right: 24px;
    padding-left: 14px;

    .tab-title {
      &.vertical {
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
    }

    .config-alert {
      position: absolute;
      right: 26px;
      top: 6px;
      width: 400px;
    }

    .pipeline-config-content {
      overflow-y: auto;
      padding-left: 0px;
      padding-right: 12px;
      height: calc(100% - 110px);

      .connector-http-config {
        // height: calc(100% - 160px);
      }
    }
  }

  &:before {
    border-radius: 4px;
    transition: all 0.6s linear;
    z-index: -1;
    content: "";
    background: linear-gradient(-65deg, #00897b, #109c1d);
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 70px;

    clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 70px,
      21px 35px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 70px,
      21px 35px
    );
  }

  &.active {
    width: 75%;
  }

  &.complete {
    // background: repeating-linear-gradient(-65deg, #00897b, #109c1d);
    color: white !important;

    &:before {
      left: 0;
    }
    // background: repeating-linear-gradient(
    //   -65deg,
    //   #fcfcfc,
    //   #fcfcfc 20px,
    //   #f4faf7 20px,
    //   #f4faf7 40px
    // );
  }

  &:first-child {
    margin: 0 0 0 0px;

    clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 0px,
      0px 0px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 21px) 0,
      100% 35px,
      calc(100% - 21px) 70px,
      calc(100% - 21px) 100%,
      0 100%,
      0 0px,
      0px 0px
    );

    &:before {
      clip-path: polygon(
        0 0,
        calc(100% - 21px) 0,
        100% 35px,
        calc(100% - 21px) 70px,
        calc(100% - 21px) 100%,
        0 100%,
        0 0px,
        0px 0px
      );
      -webkit-clip-path: polygon(
        0 0,
        calc(100% - 21px) 0,
        100% 35px,
        calc(100% - 21px) 70px,
        calc(100% - 21px) 100%,
        0 100%,
        0 0px,
        0px 0px
      );
    }
  }

  &:last-child {
    clip-path: polygon(
      0 0,
      100% 0,
      100% 35px,
      100% 70px,
      100% 100%,
      0 100%,
      0 70px,
      21px 35px
    );
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      100% 35px,
      100% 70px,
      100% 100%,
      0 100%,
      0 70px,
      21px 35px
    );

    &:before {
      clip-path: polygon(
        0 0,
        100% 0,
        100% 35px,
        100% 70px,
        100% 100%,
        0 100%,
        0 70px,
        21px 35px
      );
      -webkit-clip-path: polygon(
        0 0,
        100% 0,
        100% 35px,
        100% 70px,
        100% 100%,
        0 100%,
        0 70px,
        21px 35px
      );
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.6s ease;
  transition-delay: 0.5s;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  display: none;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(35px);
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(200%, 0);
}
</style>